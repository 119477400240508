import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"
import * as controllerUsuarioLogin from "~/controller/controllerUsuarioLogin"

import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const ModalVendedor = ({isOpen, toggleModalVendedor, shouldClean}) => {
    const { VENDEDOREs } = useSelector((state) => state.PedidoItem, shallowEqual);
    const [pesquisaVendedor, setpesquisaVendedor] = useState({linhaSelecionada: '', idUsuario: '', nomeUsuario: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ numeroUsuario: '', nomeUsuario: '' })
    const [usuariosSelected, setusuariosSelected] = useState({ idUsuario: 0 })
    const [usuarios, setUsuarios] = useState([])
    const dispatch = useDispatch();

     useEffect(() => {
      if (VENDEDOREs.length === 0){
        handlePesquisar();
      }
     }, [])
    
    async function handlePesquisar(){
      setLoading(true);
      if (!loading) {
          await controllerUsuarioLogin.RetornarUsuariosPedido(notificacao, dispatch, login.usuario.empresa, () => {
              document.getElementById("numeroUsuario").focus();  
          });
          setLoading(false);
      }
    }

    // useEffect(() => {
    //   if (paramCliente !== ''){
    //     setpesquisa({ ...pesquisa, nomeCliente: paramCliente })
    //     handlePesquisar();
    //   }
    // }, [paramCliente])

    useEffect(() => {
        setUsuarios(validaList(VENDEDOREs))
    }, [VENDEDOREs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'VENDEDOREs', value: [] })  
          //setpesquisa({ ...pesquisa, nomeCliente: '' })
        }
    }, [shouldClean])

    function handleOnChange(value, campo) {
      switch (campo) {
        case "numeroUsuario":
          setpesquisa({ ...pesquisa, numeroUsuario: value })
          break;
        default:
          break;
      }  
    }

    function handleVoltar(){
      toggleModalVendedor();
    }


    function handleOk(){
      let idStore = 'PEDIDO'
      let type = 'PEDIDO';
      let usuarioSelected = VENDEDOREs[pesquisaVendedor.linhaSelecionada]
      
      dispatch({ idStore, type, campo: 'idUsuarioVendedor', value: usuarioSelected.idUsuario })
      dispatch({ idStore, type, campo: 'nomeUsuarioVendedor', value: usuarioSelected.nome })

      toggleModalVendedor();
    }

    function onClick(rowIndex) {
      let usuarioSelected = VENDEDOREs[rowIndex]

      let idStore = 'PEDIDO'
      let type = 'PEDIDO';
      dispatch({ idStore, type, campo: 'idUsuarioVendedor', value: usuarioSelected.idUsuario })
      dispatch({ idStore, type, campo: 'nomeUsuarioVendedor', value: usuarioSelected.nome })
  
      setpesquisaVendedor({linhaSelecionada: rowIndex, idUsuario: usuarioSelected.idUsuario, nomeUsuario: usuarioSelected.nome })
      //setpesquisa({ ...pesquisa, nomeCliente: usuarioselected.nomeCliente })
  }

  function onDoubleClick(rowIndex) {
    let usuarioSelected = VENDEDOREs[rowIndex]
    setpesquisaVendedor({linhaSelecionada: rowIndex, idUsuario: usuarioSelected.idUsuario, nomeUsuario: usuarioSelected.nome })

      handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: VENDEDOREs[row.index].idUsuario === pesquisaVendedor.idUsuario ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar();
        }
    }

    function handleLimpar() {
        let idStore = 'PEDIDO';     
        dispatch({ idStore, type: 'VENDEDOREs', value: [] }) 

        //setpesquisa({ ...pesquisa, nomeCliente: '' })
        setusuariosSelected({ ...usuariosSelected, idUsuario: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idUsuario === item.idUsuario)) {
                arr.push(value)
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalVendedor"
            isOpen={isOpen}
            toggle={() => toggleModalVendedor()}
            title={'Vendedores'}
            size="sm" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                {/* <Row className="padding-padrao-row">
                  <Col md="3" className="padding-padrao-col">
                      <label>Número</label>
                      <Input
                        style={{textTransform:"uppercase" , fontSize: '16px' }}
                        id='numeroUsuario'
                        type='text'
                        autoComplete='off'
                        value={pesquisa.numeroUsuario}
                        onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        onFocus={e => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.target.value !== ''){
                            handleKeyPress(e, e.target.value);
                            if (e.key === 'Enter') e.target.select()  
                          }
                        }}
                        maxLength="10"
                      />
                  </Col>

                </Row> */}

                <Row className="justify-content-center">
                  <Col className="text-center">
                    <label style={{fontSize: '14px', textAlign:'center', marginTop: '-30px' }}
                    >Selecione um Vendedor</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      
                       <div
                        className='scrollBar'
                        style={VENDEDOREs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 200 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={VENDEDOREs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'Numero',
                                    accessor: 'idUsuario',
                                    minWidth: 40,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'Nome',
                                    accessor: 'login',
                                    resizable: false,
                                    sortable: false
                                }]}
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={VENDEDOREs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${VENDEDOREs.length} vendedores encontradas`}
                  </div>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center" style={{marginTop: '-30px' }}>
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>

                            {/* <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button> */}

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalVendedor