//State da tela ABERTURA DE CAIXA

var INITIAL_STATE = {

  cadastroCaixa: {
    idCaixa: '',
    idUsuario: '',
    idEmpresa: '',
    dataAbertura: '',
    dataFechamento: '',
    valorAbertura: '',
    idUsuarioAlteracao: '',
    dataAlteracao: '',
    status: '',
  },

  caixaFluxoSoma: {
    idCaixa: '',
    idUsuario: '',
    idEmpresa: '',
    dataAbertura: '',
    dataFechamento: '',
    valorTotalPix: '',
    valorTotalDinheiro: '',
    valorTotalDebito: '',
    valorTotalCredito: '',
    valorTotalAbertura: '',
    valorTotalRetirada: '',
    valorTotalAvista: '',
    valorTotalAprazo: '',
    valorTotalServico: '',
    valorTotalEntrega: '',
    valorSaldoComTaxa: '',
    valorSaldoSemTaxa: '',
    valorSaldoCaixa: '',
    valorSaldoBanco: '',
  },

  caixaVendasSoma: {
    valorSubTotal: '',
    valorTaxas: '',
    valorEntrega: '',
    valorTotal: '',
  },

  //Reabrir Caixa
  caixaUsuarioSenha: {
    usuario: '',
    senha: '',
  },

  caixaPesquisaVendas: {
    idUsuario: '',
    dataPesquisa: '',
    formaPagamento: '',
  },

  //Retiradas do Caixa (Utilizado no Pedido de Vendas)
  caixaUsuarioRetiradas: {
    idCaixa: '',
    dataAbertura: '',
    retiradasCaixa: [],
  },
  
  caixaRetirada: {
    idCaixaRetirada: '',
    idCaixa: '',
    idUsuario: '',
    valorRetirada: '',
    dataRetirada: '',
    motivo: '',
    imprimir: '',
    status: '',
    dataAbertura: '',
    nomeUsuarioCaixa: '',
    nomeUsuarioRetirada: '',
  },

  invalido: {
    senha: false,
    valorAbertura: false,
  },

  invalidoRetirada: {
    valorRetirada: false,
    motivo: false,
  },

  inicioCadastro: false,
  cadastroCaixaCopia: {},
  CAIXAs: [],
  RETIRADAs: [],
  CAIXAABERTOs: [],
}

export default function CAIXA(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CAIXA':
          switch (action.type) {
            case 'CAIXAs':
                 return { ...state, CAIXAs: action.value }
            case 'RETIRADAs':
                 return { ...state, RETIRADAs: action.value } 
            case 'CAIXAABERTOs':
                 return { ...state, CAIXAABERTOs: action.value }  
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'CAIXA':
                return { ...state, cadastroCaixa: { ...state.cadastroCaixa, [action.campo]: action.value } }
            case 'CAIXA_FLUXO_SOMA':
              return { ...state, caixaFluxoSoma: { ...state.caixaFluxoSoma, [action.campo]: action.value } }  
            case 'CAIXA_VENDAS_SOMA':
              return { ...state, caixaVendasSoma: { ...state.caixaVendasSoma, [action.campo]: action.value } }    
            case 'CAIXA_USUARIO_SENHA':
              return { ...state, caixaUsuarioSenha: { ...state.caixaUsuarioSenha, [action.campo]: action.value } }      
            case 'CAIXA_PESQUISA_VENDAS':
              return { ...state, caixaPesquisaVendas: { ...state.caixaPesquisaVendas, [action.campo]: action.value } }
            case 'CAIXA_USUARIO_RETIRADAS':
              return { ...state, caixaUsuarioRetiradas: { ...state.caixaUsuarioRetiradas, [action.campo]: action.value } }  
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'CAIXA_COPIA':
                return { ...state, cadastroCaixaCopia: state.cadastroCaixa }
            case 'CLEAN_CAIXA':
                return {
                    ...state,
                    cadastroCaixa: {
                      idCaixa: '',
                      idUsuario: '',
                      idEmpresa: '',
                      dataAbertura: '',
                      dataFechamento: '',
                      valorAbertura: '',
                      idUsuarioAlteracao: '',
                      dataAlteracao: '',
                      status: '',                                                                         
                    }
                }
            case 'CLEAN_CAIXA_FLUXO_SOMA':
              return {
                  ...state,
                  caixaFluxoSoma: {
                    idCaixa: '',
                    idUsuario: '',
                    idEmpresa: '',
                    dataAbertura: '',
                    dataFechamento: '',
                    valorTotalPix: '',
                    valorTotalDinheiro: '',
                    valorTotalDebito: '',
                    valorTotalCredito: '',
                    valorTotalAbertura: '',
                    valorTotalRetirada: '',
                    valorTotalAvista: '',
                    valorTotalAprazo: '',
                    valorTotalServico: '',
                    valorTotalEntrega: '',
                    valorSaldoComTaxa: '',
                    valorSaldoSemTaxa: '',
                    valorSaldoCaixa: '',
                    valorSaldoBanco: '',                                                                                                         
                  }
              }  
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                      senha: false,
                      valorAbertura: false,                                                      
                    }
                }
            case 'CLEAN_PESQUISA_VENDAS':
              return {
                  ...state,
                  caixaPesquisaVendas: {
                    idUsuario: '',
                    dataPesquisa: '',
                    formaPagamento: '',                                                                      
                  }
              }
            case 'CLEAN_VENDAS_SOMA':
              return {
                  ...state,
                  caixaVendasSoma: {
                    valorSubTotal: '',
                    valorTaxas: '',
                    valorEntrega: '',
                    valorTotal: '',                                                                                      
                  }
              }    
            case 'CLEAN_USUARIO_SENHA':
              return {
                  ...state,
                  caixaUsuarioSenha: {
                    usuario: '',
                    senha: '',                                                                                                      
                  }
              }      
            case 'CLEAN_USUARIO_RETIRADAS':
              return {
                  ...state,
                  caixaUsuarioRetiradas: {
                    idCaixa: '',
                    dataAbertura: '',
                    retiradasCaixa: [],
                  }    
            }        
            case 'RETIRADA':
              return { ...state, caixaRetirada: { ...state.caixaRetirada, [action.campo]: action.value } 
            }
            case 'CLEAN_RETIRADA':
              return {
                  ...state,
                  caixaRetirada: {
                    idCaixaRetirada: '',
                    idCaixa: '',
                    idUsuario: '',
                    valorRetirada: '',
                    dataRetirada: '',
                    motivo: '',
                    imprimir: '',
                    status: '',  
                    dataAbertura: '',
                    nomeUsuarioCaixa: '',
                    nomeUsuarioRetirada: '',                
                  }
            }
            case 'CLEAN_INVALIDO_RETIRADA':
              return {
                  ...state,
                  invalidoRetirada: {
                    valorRetirada: false,
                    motivo: false,                                                      
                  }
            }
            default:
              return { ...state }
          }
      default:
          return { ...state }
  }
}