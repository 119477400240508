//State da tela Pedido Vendas

var INITIAL_STATE = {

    pesquisaPedido: {
        idEmpresa: 0,
        nomeFantasia: '',
    },
   
  
    //Campos para PEDIDO
    pedidoVendas: {
      idEmpresa: 0,
      empresa: '',
      secret: '',
      identFier: '',
      nomeFantasia: '',
      urlAPi: '',
      ativo: 0,
      medida: '',
      tempo: 1,
    },

    //Campos para ITENS DO PEDIDO
    pedidoVendas: {
        idPedidoItem: '',
        idPedido: '',
        idEmpresa: '',
        idProduto_Item: '',
        dataItem: '',
        quantidade: '',
        valorItem: '',
        valorTotal: '',
        impresso: '',
        produzido: '',
        status: ''    
    },

  
    //Campos para validar na pesquisa
    invalido: {
      idEmpresa: false,
      nomeFantasia: false,
  },
  
    //Objetos para carregar lista de dados
    pesqempresa: [],
    EMPRESAs: [],
    //ICMSs: [],
    //inicioCadastro: false,
    //pesqregime: [],
    //cadregime: [],
    pedidoVendasCopia: {},
  
  }
  
  export default function PEDIDO(state = INITIAL_STATE, action) {
  
    switch (action.idStore) {
  
        case 'PEDIDO':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisaPedido: { ...state.pesquisaPedido, [action.campo]: action.value } }
                case 'EMPRESAS':
                    return { ...state, EMPRESAs: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisaPedido: {
                          idEmpresa: 0,
                          nomeFantasia: '',
                        },
                        EMPRESAs: []
                    }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'PEDIDO':
                    return { ...state, pedidoVendas: { ...state.pedidoVendas, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'PEDIDO_COPIA':
                    return { ...state, pedidoVendasCopia: state.pedidoVendas }
                case 'PEDIDO_COPIA_STATUS':
                    return { ...state, pedidoVendasCopia: { ...state.pedidoVendas, status: action.value } }
                case 'CLEAN_PEDIDO':
                    return {
                        ...state,
                        pedidoVendas: {
                          idEmpresa: 0,
                          empresa: '',
                          secret: '',
                          identFier: '',
                          nomeFantasia: '',
                          urlAPi: '',
                          ativo: 0,
                          medida: '',
                          tempo: 1,                    
                        }
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                          idEmpresa: false,
                          nomeFantasia: false,                    
                        }
                    }
  
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
  }
  
  
  
  
  