import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";
import { maskMoeda } from "~/Util/mascaras";
import { alertar } from "~/Util/alertar";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const idStore = "CAIXA";

const CaixaRetirada = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { caixaRetirada, invalidoRetirada } = useSelector((state) => state.Caixa);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "RETIRADA", campo, value });
  }

  function handleStatus(campo){
    var statusCaixa = '';
    if (isEnabled){
      statusCaixa = '9';
      setIsEnabled(false);
    }else{
      statusCaixa = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CAIXA', campo: campo, value: statusCaixa })
  }

  async function Salvar(e) {
    setLoading(true);
    if (!loading) {
      if (caixaRetirada.idCaixa === ''){
        alertar(notificacao, 'br', "O Caixa NÃO foi ABERTO.", 'warning', 'icon-alert-circle-exc', 3);
      }else if (caixaRetirada.motivo === ''){
        alertar(notificacao, 'br', "Informe o MOTIVO.", 'warning', 'icon-alert-circle-exc', 3);
      }else if (caixaRetirada.valorRetirada === '' || caixaRetirada.valorRetirada === '0,00'){
        alertar(notificacao, 'br', "Informe o VALOR.", 'warning', 'icon-alert-circle-exc', 3);  
      }else{

        await controllerCaixa.CaixaRetirada(notificacao, dispatch, caixaRetirada, caixaRetirada, () => {
        });     
      }
    }
    setLoading(false);

  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_RETIRADA' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO_RETIRADA' });
    document.getElementById('valorRetirada').focus()
  };

  useEffect(() => {
    //Preenche campos
    //controllerGrupoTributacao.GetTributos(notificacao, dispatch, login.usuario.empresa)
  }, []);
  
  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
              <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Caixa</label>
                      <Input
                        id="idCaixa"
                        type="text"
                        disabled
                        value={caixaRetirada.idCaixa}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col">
                    <label>Caixa Aberto Por</label>
                      <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '16px',  textAlign:'left' }}
                        id='caixa'
                        type='text'
                        autoComplete='off'
                        value={caixaRetirada.nomeUsuarioCaixa + " - " + caixaRetirada.dataAbertura}
                      />
                  </Col>

                  
                </Row>
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Retirada</label>
                      <Input
                        id="idCaixaRetirada"
                        type="text"
                        disabled
                        value={caixaRetirada.idCaixaRetirada}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>
                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Usuário</label>
                      <Input
                        id="usuarioLogin"
                        type="text"
                        disabled
                        value={caixaRetirada.nomeUsuarioRetirada}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>
                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Data Retirada</label>
                      <Input
                        id="dataRetirada"
                        type="text"
                        disabled
                        value={caixaRetirada.dataRetirada}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padr/ao-col" lg="2" md="3">
                    <label>Valor Retirada</label>
                    <Input
                      id="valorRetirada"
                      type="text"
                      value={caixaRetirada.valorRetirada}
                      onFocus={e => e.target.select()}
                      onChange={(e) =>
                        handleOnChange(maskMoeda(e.target.value), e.target.id)
                      }    
                      autoComplete="off"
                      maxLength="15"
                    />
                    <br></br>
                  </Col>

                  <Col className="padding-padrao-col">
                    <label>Motivo</label>
                      <Input 
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'left' }}
                          id='motivo'
                          type='text'
                          autoComplete='off'
                          value={caixaRetirada.motivo}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          //onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                          onFocus={e => e.target.select()}
                          maxLength="25"
                      />
                  </Col>                  

                </Row>
              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('codigoProduto').focus()
    }, 100)
  }

  render() {
    return (
      <CaixaRetirada />
    )
  }
}

export default Wizard;
