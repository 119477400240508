import { combineReducers } from 'redux'

import sistema from './sistemaStore'
import notificacao from './notificacaoStore'
import MensagemLoadBar from './mensagemLoadBarStore'
import login from './loginStore'
import ACESSO from './Acesso/acessoStore'
import EMPRESA from './Cadastros/empresaStore'
import PESQUISA_USUARIOS from './Pesquisas/pesqUsuarioStore'
import DASHBOARD from './Dashboard/dashboardStore'
import usuarios from './usuariosStore'
import UsuarioLogin from './Cadastros/usuarioLoginStore'
import Cliente from './Cadastros/clienteStore'
import Fornecedor from './Cadastros/fornecedorStore'
import Produto from './Cadastros/produtoStore'
import GrupoTributacao from './Cadastros/grupoTributacaoStore'
import Configuracao from './Cadastros/configuracaoStore'
import MeusDados from './Cadastros/meusDadosStore'
import Pedido from './Vendas/pedidoStore'
import PedidoItem from './Vendas/pedidoItemStore'
import PedidoPagamento from './Vendas/pedidoPagamentoStore'
import PedidoPesqProduto from './Vendas/pedidoPesqProdutoStore'
import PedidoPesqCliente from './Vendas/pedidoPesqClienteStore'
import Caixa from './Financeiro/caixaStore'
import Entrada from './Movimentacao/entradaStore'
import Operacao from './Cadastros/operacaoStore'


export default combineReducers({
    notificacao,
    MensagemLoadBar,
    login,
    ACESSO,
    sistema,
    EMPRESA,
    PESQUISA_USUARIOS,
    DASHBOARD,
    usuarios,
    UsuarioLogin,
    Cliente,
    Fornecedor,
    Produto,
    GrupoTributacao,
    Configuracao,
    MeusDados,
    Pedido,
    PedidoItem,
    PedidoPesqProduto,
    PedidoPesqCliente,
    PedidoPagamento,
    Caixa,
    Entrada,
    Operacao,
})
