//State da tela de Configuracao

var INITIAL_STATE = {

  mensagemLoadBar: {
    mensagem: '',
  },

}

export default function MENSAGEMLOADBAR(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'MENSAGEMLOADBAR':
          switch (action.type) {
            case 'MENSAGEMLOADBAR':
                return { ...state, mensagemLoadBar: { ...state.mensagemLoadBar, [action.campo]: action.value } }
            default:
                return { ...state }
          }
      default:
          return { ...state }
  }
}