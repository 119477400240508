
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas";
import * as controllerUsuarios from "~/controller/controllerUsuarios";
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa";
import { Select } from "~/components/ErnComponets/ErnComponets";
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {formatarData, maskMoeda} from "~/Util/mascaras";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";

import useModal from '~/Util/Hooks/useModal.ts';
import ModalReabrirCaixa from "~/views/Financeiro/FecharCaixa/Modals/modalReabrirCaixa.jsx";

// wizard steps
import Step1 from "~/views/Financeiro/FecharCaixa/CaixaVendas.jsx"

var steps = [
  {
    stepName: "", //Nome do Step,
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const idStore = "CAIXA"

const CaixaResumo = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);
  const {  usuarios } = useSelector((state) => state.usuarios);
  //trocar abaixo por novo store
  const { caixaFluxoSoma, caixaUsuarioSenha } = useSelector((state) => state.Caixa);
  
  // Estado para controlar a cor de fundo
  const corFormaSelecionada = '#6e6e6e'; 
  const corFormaNormal = '#cecece';

  const [bgColor, setBgColor] = useState(corFormaNormal); // Cor inicial
  const [bgColorDinheiro, setBgColorDinheiro] = useState(corFormaNormal); // Cor inicial
  const [bgColorDebito, setBgColorDebito] = useState(corFormaNormal); // Cor inicial
  const [bgColorCredito, setBgColorCredito] = useState(corFormaNormal); // Cor inicial
  

  const [pesquisa, setpesquisa] = useState({ dataPesquisa: '', idUsuario: '', formaPagamento: '' })
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [width, setWidht] = useState(window.innerWidth);
  
  //Modal
  const [shouldClean, setshouldClean] = useState(false)
  const [modalReabrirCaixa, toggleModalReabrirCaixa] = useModal(false)

  

  // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
  const toggleBackgroundColor = (e, cardName) => {
    setBgColor(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
    setBgColorDinheiro(corFormaNormal);
    setBgColorDebito(corFormaNormal);
    setBgColorCredito(corFormaNormal);


    setpesquisa({ ...pesquisa, formaPagamento: '2' })
    if (bgColor === corFormaSelecionada){
      setpesquisa({ ...pesquisa, formaPagamento: 'T' })
    }
  };

  // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
  const toggleBackgroundColorDinheiro = (e, cardName) => {
    setBgColor(corFormaNormal);
    setBgColorDinheiro(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
    setBgColorDebito(corFormaNormal);
    setBgColorCredito(corFormaNormal);

    setpesquisa({ ...pesquisa, formaPagamento: '1' })
    if (bgColorDinheiro === corFormaSelecionada){
      setpesquisa({ ...pesquisa, formaPagamento: 'T' })
    }
  };

  // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
  const toggleBackgroundColorDebito = (e, cardName) => {
    setBgColor(corFormaNormal);
    setBgColorDinheiro(corFormaNormal);
    setBgColorDebito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
    setBgColorCredito(corFormaNormal);

    setpesquisa({ ...pesquisa, formaPagamento: '3' })
    if (bgColorDebito === corFormaSelecionada){
      setpesquisa({ ...pesquisa, formaPagamento: 'T' })
    }
  };

  // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
  const toggleBackgroundColorCredito = (e, cardName) => {
    setBgColor(corFormaNormal);
    setBgColorDinheiro(corFormaNormal);
    setBgColorDebito(corFormaNormal);
    setBgColorCredito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));

    setpesquisa({ ...pesquisa, formaPagamento: '4' })
    if (bgColorCredito === corFormaSelecionada){
      setpesquisa({ ...pesquisa, formaPagamento: 'T' })
    }
  };

  useEffect(() => {
    let dataPesq = '';
    let dataAtual = new Date();
    let hora = new Date().getHours();
    if (hora >= 0 && hora <= 6){
      dataAtual.setDate(dataAtual.getDate() - 1);
      dataPesq = formatarData('EN', dataAtual.toLocaleDateString());
    }else{
      dataPesq = formatarData('EN', dataAtual.toLocaleDateString());
    }

    dispatch({ idStore, type: "CLEAN_CAIXA_FLUXO_SOMA" });

    setpesquisa({ ...pesquisa, dataPesquisa: dataPesq, idUsuario: login.usuario.idUsuarioLogin, formaPagamento: 'T' })

    controllerUsuarios.UsuariosEmpresaGet(notificacao, login.usuario.empresa, dispatch);

  }, []) 

  function handlePesquisar() {
    setLoading(true);
    if (!loading) {
      controllerCaixa.RetornarCaixaFluxoSoma(notificacao, dispatch, pesquisa, login.usuario.empresa);
    }
    setLoading(false);
  }

  function onChangeUsuario(value) {
    setpesquisa({ ...pesquisa, idUsuario: value })
  }


  function handleOnChange(value, campo) {
    if (campo === 'dataPesquisa'){
      setpesquisa({ ...pesquisa, dataPesquisa: value })
    }
  }


  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        //document.getElementById("valorAbertura").focus();
      }, 100);
    }
  }


  async function handleFecharReabrirCaixa(){
    setLoading(true);
    if (!loading) {
      
      dispatch({ idStore, type: "CLEAN_USUARIO_SENHA" });

      if (caixaFluxoSoma.dataFechamento === 'CAIXA ABERTO'){
        await controllerCaixa.FecharReabrirCaixa(notificacao, dispatch, caixaFluxoSoma, login.usuario.empresa, 'F', caixaUsuarioSenha);
      }else{
        //Reabrir o Caixa Solicitar Senha
        toggleModalReabrirCaixa(); 
        //controllerCaixa.FecharReabrirCaixa(notificacao, dispatch, caixaFluxoSoma, login.usuario.empresa, 'R');
      }
      
    }
    setLoading(false);


      //changeActiveTab("cadastrar");
     //novoCadatro();
  }

  async function handleVendas(){
    setLoading(true);
    if (!loading) {
      var idCaixa = caixaFluxoSoma.idCaixa;

      await controllerPedidoVendas.GetPedidosDataForma(notificacao, dispatch, login.usuario.empresa, idCaixa, pesquisa, () => {
    
    });

    }
    setLoading(false);
  }

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);


  function handleKeyPress(event) {
    if (event.key === "Enter") {

    }
  }


  function updateWidth() {
    setWidht(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
             <CardHeader className="text-center">
              <CardTitle tag="h4">
               Fechamento de Caixa
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >

                 {/* <TabPane tabId="cadastrar">
                  <h5>Vendas no Caixa</h5>
                </TabPane>  */}
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }} 
                    style={{ width: "50%" }}
                  >
                    Resumo
                  </Button>
                </ArrowTooltip>
                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      handleVendas();
                    }}
                    style={{ width: "50%" }}
                  >
                    Vendas
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px", marginTop: '-20px' }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa-GRADE */}
                <Row className="padding-padrao-row" style={{marginTop: '-40px' }}>
                  <Col md="2" className="padding-padrao-col">
                    <label>Data do Caixa</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataPesquisa'
                        type='date'
                        autoComplete='off'
                        value={pesquisa.dataPesquisa}
                        onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        maxLength="15"
                      />
                  </Col>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Usuário</label>
                     <Select
                      id="usuarioSelect"
                      select={{ value: "idUsuario", label: "login" }}
                      first={"Selecione"}
                      value={{ value: pesquisa.idUsuario }}
                      options={usuarios}
                      isSearchable={true}
                      onChange={(value) =>
                        onChangeUsuario(value.value)
                      }  
                      autoComplete="off"
                    />
                   </Col>

                   <Col md="2" className="padding-padrao-col">
                    <label>Data Abertura</label>
                    <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataAbertura'
                        type='text'
                        autoComplete='off'
                        value={caixaFluxoSoma.dataAbertura}
                      />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                    <label>Data Fechamento</label>
                    <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataFechamento'
                        type='text'
                        autoComplete='off'
                        value={caixaFluxoSoma.dataFechamento}
                      />
                  </Col>

                  <Col style={{ width: "100%", marginTop: '20px' }}>
                    <ArrowTooltip title={caixaFluxoSoma.dataFechamento === 'CAIXA ABERTO'? "Fechar Caixa": "Reabrir Caixa"} >
                      <Button className={caixaFluxoSoma.dataFechamento === 'CAIXA ABERTO'? 'btn btn-green': 'btn btn-danger'} 
                        //color="warning"
                        style={
                          width > 550
                            ? { float: "right", width: "150px" }
                            : width < 550
                              ? { marginLeft: "0px", width: "100%" }
                              : {}
                        }
                        onClick={() => handleFecharReabrirCaixa()}
                      >
                        {caixaFluxoSoma.dataFechamento === 'CAIXA ABERTO'? "Fechar Caixa": "Reabrir Caixa"} 
                      </Button>
                    </ArrowTooltip>                    
                    <ArrowTooltip title="Pesquisar">
                      <Button
                        color="info"
                        style={
                          width > 550
                            ? { float: "right", width: "150px" }
                            : width < 550
                              ? { marginLeft: "0px", width: "100%" }
                              : {}
                        }
                        onClick={() => handlePesquisar()}
                      >
                        Pesquisar
                      </Button>
                    </ArrowTooltip>

                  </Col>

                  
                </Row>

                <Row md="12" className="padding-padrao-row">
                  <Col md="5" className="padding-padrao-col" >
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Formas de Pagamento</legend>
                        <Row className="padding-padrao-row" style={{ marginTop: '0px'}}>
                          <Col>


                            <Card className="card-stats" 
                              style={{ backgroundColor: bgColor, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColor(e, "PIX")
                              }                            
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                  
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/pix.png")}
                                      />
                                      
                                    </div>
                                    <p className="card-category" style={{ width: '50px', textAlign: 'center' }}>PIX</p>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Total</p>
                                      <CardTitle tag="h3">R$ {caixaFluxoSoma.valorTotalPix}</CardTitle>
                                    </div>
                                  </Col>
                                  
                                </Row>
                              </CardBody>
                            </Card>                                  

                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }} >
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDinheiro, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDinheiro(e, "DINHEIRO")
                              }                            

                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/dinheiro.png")}
                                      />
                                    </div>
                                    <p className="card-category" style={{ width: '70px', textAlign: 'left' }}>DINHEIRO</p>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Total</p>
                                      <CardTitle tag="h3">R$ {caixaFluxoSoma.valorTotalDinheiro}</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDebito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDebito(e, "DEBITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_debito_cinza.png")}
                                      />
                                    </div>
                                    <p className="card-category" style={{ width: '130px', textAlign: 'left' }}>CARTÃO DÉBITO</p>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Total</p>
                                      <CardTitle tag="h3">R$ {caixaFluxoSoma.valorTotalDebito}</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorCredito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorCredito(e, "CREDITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_credito.png")}
                                      />
                                    </div>
                                    <p className="card-category" style={{ width: '130px', textAlign: 'left' }}>CARTÃO CRÉDITO</p>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Total</p>
                                      <CardTitle tag="h3">R$ {caixaFluxoSoma.valorTotalCredito}</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                    </fieldset>    
                  </Col>

                  <Col className="padding-padrao-col">
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Resumo do Caixa</legend>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelAberuraCaixa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Abertura de Caixa R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalAbertura"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalAbertura}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelRetiradas"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Retiradas R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#ff0000', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalRetirada"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalRetirada}
                              maxLength="15"
                            />
                          </Col>          
                        </Row>    

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTotalAvista"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Total à Vista R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalAvista"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalAvista}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTotalAprazo"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Total à Prazo R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalAprazo"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalAprazo}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTaxaServico"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Taxa de Serviço R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#d88e04', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalServico"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalServico}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelValorEntrega"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Valor Entrega R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#d88e04', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotalEntrega"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorTotalEntrega}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '0px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelSaldoComTaxa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Saldo COM Taxa R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSaldoComTaxa"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorSaldoComTaxa}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '0px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelSaldoSemTaxa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Saldo SEM Taxa R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSaldoSemTaxa"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorSaldoSemTaxa}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '5px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelSaldoCaixa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Saldo Caixa R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '26px', fontWeight: 'bold', color: '#0077ff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSaldoCaixa"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorSaldoCaixa}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '0px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelSaldoBanco"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Saldo Banco R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '26px', fontWeight: 'bold', color: '#0077ff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSaldoBanco"
                              type="text"
                              autoComplete="off"
                              value={caixaFluxoSoma.valorSaldoBanco}
                            />
                          </Col>          
                        </Row>




                    </fieldset>    
                  </Col>
                </Row>


                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      {/* <ArrowTooltip title="Novo Cadastro">
                        <Button className='btn btn-green'
                          //color="warning"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleNovoCadastro()}
                        >
                          Fechar Caixa
                        </Button>
                      </ArrowTooltip>                     */}
                      <ArrowTooltip title="Limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Imprimir
                        </Button>
                      </ArrowTooltip>

                      {/* <ArrowTooltip title="Pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip> */}

                    </Col>
                  </CardBody>
                </Row>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-40px" }}>
                   <ReactWizard
                    steps={steps}
                    //navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"                    
                    //goToFirstStep={inicioCadastro}
                    //finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                      "btn-wd btn-info invisible",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )} 
                     nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    wizardNavigationVisivel={false} //Para não mostrar a barra de navegação do wizard
                    progressbar
                    color="blue"
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {modalReabrirCaixa ?
          <ModalReabrirCaixa
            isOpen={true}
            toggleModalReabrirCaixa={toggleModalReabrirCaixa}
            caixaFluxoSoma={caixaFluxoSoma}
            shouldClean={shouldClean}
        />
        : null}
    </>
  );
};

export default CaixaResumo;
