//State da tela Meus Dados (Tabela Empresa)

var INITIAL_STATE = {

  cadastroMeusDados: {
    idEmpresa: '',
    numeroContrato: '',
    cnpjCpf: '',
    nomeEmpresa: '',
    celular: '',
    email: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    complemento: '',
  },

  invalido: {
    cnpjCpf: false,
    nomeEmpresa: false,
    celular: false,
    email: false,
    cep: false,
    endereco: false,
    bairro: false,
    cidade: false,
    uf: false,
    numero: false,
  },

  inicioCadastro: false,
  cadastroMeusDadosCopia: {},
}

export default function MEUSDADOS(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'MEUSDADOS':
          switch (action.type) {
            // case 'PESQUISA':
            //     return { ...state, pesquisaGrupoTributacao: { ...state.pesquisaGrupoTributacao, [action.campo]: action.value } }
            // case 'GRUPOS_TRIBUTACAO_TODOs':
            //     return { ...state, GRUPOTRIBUTACAOs: action.value }
            // case 'CLEAN_SEARCH':
            //     return {
            //         ...state,
            //         pesquisaGrupoTributacao: {
            //         idEmpresa: '',
            //         idGrupo: '',
            //         codigoProduto: '',
            //         descricaoProduto: '',
            //         statusProduto: '',                                    
            //         },
            //         PRODUTOs: []
            //     }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'MEUSDADOS':
                return { ...state, cadastroMeusDados: { ...state.cadastroMeusDados, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'MEUSDADOS_COPIA':
                return { ...state, cadastroMeusDadosCopia: state.cadastroMeusDados }
            case 'CLEAN_MEUSDADOS':
                return {
                    ...state,
                    cadastroMeusDados: {
                      idEmpresa: '',
                      numeroContrato: '',
                      cnpjCpf: '',
                      nomeEmpresa: '',
                      celular: '',
                      email: '',
                      cep: '',
                      endereco: '',
                      bairro: '',
                      cidade: '',
                      uf: '',
                      numero: '', 
                      complemento: '',                                                       
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                      cnpjCpf: false,
                      nomeEmpresa: false,
                      celular: false,
                      email: false,
                      cep: false,
                      endereco: false,
                      bairro: false,
                      cidade: false,
                      uf: false,
                      numero: false,                                    
                    }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}