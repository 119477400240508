import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskMoeda,removeMaskMoney } from "~/Util/mascaras";
import { alertar } from "~/Util/alertar";
import ReactTable from "react-table-6";

import {
    Input,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    UncontrolledTooltip,
    CardFooter, 
    NavItem,
    NavLink,
    Nav,  
} from "reactstrap";

const ModalPgtoInformacao = ({isOpen, toggleModalPgtoInformacao, pedidoPedidoFaturar, shouldClean}) => {
    var tema = localStorage.getItem('TEMA')
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Estado para controlar a cor de fundo
    const corFormaSelecionada = '#6e6e6e'; 
    const corFormaNormal = '#cecece';

    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [tableVisible, setTableVisible] = useState(false);
    const [width, setWidht] = useState(window.innerWidth);
    
  
    useEffect(() => {
      setTable(renderTable());
    }, [dataTable, loading]);
  
    function renderTable() {
      return dataTable.map((obj, key) => {
        return {
          ...dataTable[key],
          nomeForma: (
            //<hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.nomeForma}
            </div>
          ),
          nome: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.nome}
            </div>
          ),
          valorPago: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'right', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'right', fontSize: '10px', fontWeight: 'normal'}}>
              {maskMoeda(obj.valorPago.toFixed(2))}
            </div>
          ),
          dataHora: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'center', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'center', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.dataHora.substr(0, 5) + " " + obj.dataHora.substr(11, 5)}
            </div>
          ),
          actions: (
            <div className="actions-center">
              <Button
                className="btn-link btn-icon"
                color="danger"
                id="tooltipExcluir"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleExcluirParcial(obj);
                }}
              >
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipExcluir"
                >
                  Excluir
                </UncontrolledTooltip>                              
                <i className="tim-icons icon-simple-remove" />
              </Button>       
            </div>
          ),
        };
      });
    }
  
    //Monta Dados para Gride
    function tableData(data) {
      return data.map((prop, key) => {
        return {
          id: key,
          idPagamentoParcial: prop["idPagamentoParcial"],
          idPedido: pedidoPedidoFaturar.idPedido,
          idEmpresa: pedidoPedidoFaturar.idEmpresa,
          pedidoAberto: pedidoPedidoFaturar.pedidoAberto,
          nomeForma: prop["nomeForma"],
          valorPago: prop["valorPago"],
          nome: prop["nome"],
          dataHora: prop["dataHora"],
        };
      });
    }

  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(pedidoPedidoFaturar.pagamentosParcial));
    if (pedidoPedidoFaturar.pagamentosParcial.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [pedidoPedidoFaturar.pagamentosParcial]);

  
  //Exluir o Pagamento Parcial 
  async function handleExcluirParcial(obj) {
    setLoading(true);
    if (!loading) {
      if (obj.pedidoAberto === 'N'){
        alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
      }else{
        await controllerPedidoVendas.ExcluirPagamentoParcial(notificacao, dispatch, obj, () => {
  
        });    
      }
      await controllerPedidoVendas.ExcluirPagamentoParcial(notificacao, dispatch, obj, () => {
  
      });  

      toggleModalPgtoInformacao();

    setLoading(false);
    }
  }

    useEffect(() => {
      //inicializaValores();      
    }, [])
    

    function handleVoltar(){
      toggleModalPgtoInformacao();
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalPgtoInformacao()}
            title={'Informações de Pagamento'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
            style={{backgroundColor: "silver"}}
          > 
            <ModalBasicBody>
                <Row md="12" className="padding-padrao-row">
                  <Col md="6" className="padding-padrao-col" >
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Formas de Pagamento</legend>
                        <Row className="padding-padrao-row" style={{ marginTop: '20px'}}>
                          <Col>
                            <Card className="card-stats" 
                              style={{ backgroundColor: corFormaNormal, cursor: 'pointer' }} // Define a cor dinamicamente
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                  
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={pedidoPedidoFaturar.formaPagamento === 1 ? 
                                            require("~/assets/img/imagens/dinheiro.png") 
                                          : pedidoPedidoFaturar.formaPagamento === 2 ? 
                                            require("~/assets/img/imagens/pix.png")
                                          : pedidoPedidoFaturar.formaPagamento === 3 ?
                                            require("~/assets/img/imagens/cartao_debito_cinza.png")
                                          : pedidoPedidoFaturar.formaPagamento === 4 ? 
                                            require("~/assets/img/imagens/cartao_credito.png")
                                          : require("~/assets/img/imagens/sem_pagamento.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pago Com</p>
                                      <CardTitle tag="h4">{pedidoPedidoFaturar.formaPagamento === 1 ? 
                                            "DINHEIRO" 
                                          : pedidoPedidoFaturar.formaPagamento === 2 ? 
                                            "PIX"
                                          : pedidoPedidoFaturar.formaPagamento === 3 ?
                                            "CARTÃO DÉBITO"
                                          : pedidoPedidoFaturar.formaPagamento === 4 ?
                                            "CARTÃO CRÉDITO"
                                          : "SEM PAGAMENTO TOTAL"
                                          }
                                      </CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>                                  

                          </Col>
                        </Row>
                              {/* Pagamentos Parciais */}
                        <Row className="padding-padrao-row">
                          <div
                            className='scrollBar'
                            style={
                              tableVisible
                                ? {
                                    transition: "0.5s ease-in-out",
                                    visibility: "visible",
                                    opacity: "1",
                                    maxHeight: 225,
                                  }
                                : { visibility: "hidden", opacity: "0" }
                            }
                          >
                            <ReactTable
                              data={table}
                              filterable={false}
                              columns={[
                                {
                                  Header: "Forma",
                                  headerStyle: {fontSize: '10px', textAlign: 'left'},
                                  accessor: "nomeForma",
                                  width: 95,
                                  sortable: false,
                                },
                                {
                                  Header: "Pago Por",
                                  headerStyle: {fontSize: '10px'},
                                  accessor: "nome",
                                  width: 82,
                                  sortable: false,
                                },
                                {
                                  Header: "Valor",
                                  headerStyle: {fontSize: '10px',  textAlign: 'end'},
                                  accessor: "valorPago",
                                  width: 50,
                                  sortable: false,
                                  alignItems: 'revert',
                                },
                                {
                                  Header: "Hora",
                                  headerStyle: {fontSize: '10px', textAlign: 'center'},
                                  accessor: "dataHora",
                                  width: 70,
                                  sortable: false,
                                },
                                {
                                  Header: "",
                                  //headerStyle: {fontSize: '10px', textAlign: 'center'},
                                  accessor: "actions",
                                  width: 40,
                                  sortable: false,
                                },

                              ]}
                              minRows={1}
                              showPaginationTop={false}
                              showPaginationBottom={false}
                              className="-striped -highlight"
                               noDataText=""
                               previousText="Anterior"
                               nextText="Próximo"
                              loadingText="Carregando"
                               pageText="Página"
                               ofText="de"
                               rowsText="linhas"
                            />
                          </div>
                        </Row>                  

                    </fieldset>    
                  </Col>

                  <Col className="padding-padrao-col">
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Resumo do Pedido</legend>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelSubTotal"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="SubTotal R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSubTotal"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorSubTotal}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelAcrescimo"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Acrescimo R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorAcrescimo"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorAcrescimo}
                              maxLength="15"
                            />
                          </Col>          
                        </Row>    

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelDesconto"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Desconto R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorDesconto"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorDesconto}
                              maxLength="15"
                            />
                          </Col>          
                        </Row>    

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTaxa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Tx. Serviço R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTaxa"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorTaxa}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelEntrega"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Entrega R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorEntrega"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorEntrega}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>


                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelParcial"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Parcial R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorParcial"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorParcial}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '15px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelTotal"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Total R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '24px', fontWeight: 'bold', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotal"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorTotal}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '0px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelValorPago"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '16px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Valor Pago R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorPagoDigitado"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorPagoDigitado}
                            />
                          </Col>          
                        </Row>
                        <Row className="padding-padrao-row" style={{ marginTop: '10px' }}></Row>
                    </fieldset>    
                  </Col>
                </Row>


            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '130px' }}
                            >Voltar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalPgtoInformacao