import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa";
import '~/views/Vendas/Pedido/Modals/styles.css'
import ReactTable from "react-table-6";
import { alertar } from "~/Util/alertar";

import {
    Input,
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    UncontrolledTooltip,
} from "reactstrap";
import { formatarData, maskMoeda } from '~/Util/mascaras';

const idStore = "CAIXA"

const ModalRetirada = ({isOpen, toggleModalRetirada, caixaUsuarioRetiradas, shouldClean}) => {
    var tema = localStorage.getItem('TEMA')
    const inputRef = useRef(null);
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [retirada, setretirada] = useState({ caixa: '', dataRetirada: '', idUsuario: '', usuario: '', valorRetirada: '0,00', motivo: '' })
    
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [tableVisible, setTableVisible] = useState(false);

  /*------------------------------------------------------------------------------ 
   * FOCUS dos Campos
   *------------------------------------------------------------------------------*/
    const focoTela = useRef(false);
    const focoValor = useRef(false);


    useEffect(() => {
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate() - 1)
      dataAtual = dataAtual.toISOString().substr(0, 10); 
      dataAtual = formatarData('BR', dataAtual);

      var caixaUsuario = login.usuario.login

      setretirada({ ...retirada, 
                    caixa: caixaUsuario, 
                    dataRetirada: dataAtual, 
                    idUsuario: login.usuario.idUsuarioLogin, 
                    usuario: login.usuario.login 
                  });
      
      if (inputRef.current) {
        inputRef.current.focus();
      } 

      focoTela.current = true;

    }, []);

    useEffect(() => {
      setTable(renderTable());
    }, [dataTable, loading]);


    //Grid Retiradas
    function renderTable() {
      return dataTable.map((obj, key) => {
        return {
          ...dataTable[key],
          checkboxItem: (
            <div style={{ textAlign: 'left', marginTop: '-30px', fontSize: '14px', fontWeight: 'normal', color: '#444444'}}>
              <FormGroup check>
                <Label check>
                {obj.impresso === 'S' ?
                  <Input 
                    id="itemSelecionado" 
                    defaultChecked type="checkbox" 
                    onClick={() => {
                      handleCheckItem(obj);
                    }}
                  >
                  <UncontrolledTooltip
                    delay={0}
                    target="itemSelecionado"
                  >
                    Desmarque para NÃO Imprimir
                  </UncontrolledTooltip>                    
                  </Input>  
                :  
                  <Input 
                    id="itemNaoSelecionado" 
                    type="checkbox" 
                    onClick={() => {
                      handleCheckItem(obj);
                    }}
                  >
                  <UncontrolledTooltip
                    delay={0}
                    target="itemNaoSelecionado"
                  >
                    Marque para Imprimir
                  </UncontrolledTooltip>                    
                  </Input>              
                }  
                  <span className="form-check-sign" />
                </Label>
              </FormGroup>
            </div>
          ),
          usuarioNome: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.usuarioNome}
            </div>
          ),
          dataRetirada: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.dataRetirada}
            </div>
          ),
          valorRetirada: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'right', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'right', fontSize: '10px', fontWeight: 'normal'}}>
              {maskMoeda(obj.valorRetirada.toFixed(2))}
            </div>
          ),
          motivo: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.motivo}
            </div>
          ),
        };
      });
    }

    function handleCheckItem(obj) {

      var quantidade = caixaUsuarioRetiradas.retiradasCaixa.length;
      for (var i = 0; i < quantidade; i++) {
        if (obj.idCaixaRetirada === caixaUsuarioRetiradas.retiradasCaixa[i].idCaixaRetirada){
          if (obj.imprimir === 'S'){
            caixaUsuarioRetiradas.retiradasCaixa[i].imprimir = 'N';
          }else {
            caixaUsuarioRetiradas.retiradasCaixa[i].imprimir = 'S';
          }    
        }
      }
    }
      
    //Monta Dados para Gride Retiradas
    function tableData(data) {
      return data.map((prop, key) => {
        return {
          id: key,
          idCaixaRetirada: prop["idCaixaRetirada"],
          idCaixa: prop["idCaixa"],
          idUsuario: prop["idUsuario"],
          usuarioLogin: prop["usuarioLogin"],
          usuarioNome: prop["usuarioNome"],
          valorRetirada: prop["valorRetirada"],
          dataRetirada: prop["dataRetirada"],
          motivo: prop["motivo"],
          status: prop["status"],
        };
      });
    }

    if (focoValor.current){
      document.getElementById("valor").focus();
      focoValor.current = false;
    }

    if (focoTela.current){
      focoValor.current = true;
      focoTela.current = false;
    }

  //Retiradas  
  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(caixaUsuarioRetiradas.retiradasCaixa));
    if (caixaUsuarioRetiradas.retiradasCaixa.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [caixaUsuarioRetiradas.retiradasCaixa]);


    function handleVoltar(){
      toggleModalRetirada();
    }

    async function handleImprimir(){
      dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Enviando para Impressão...' })
      setLoading(true);
      if (!loading) {
        if (caixaUsuarioRetiradas.retiradasCaixa.length > 0){
          //Verifica se existem Retiradas Marcadas para Impressao
          var retiradasImprimir = false;
          var quantidade = caixaUsuarioRetiradas.retiradasCaixa.length;
          for (var i = 0; i < quantidade; i++) {
            if (caixaUsuarioRetiradas.retiradasCaixa[i].imprimir === 'S'){
              retiradasImprimir = true;
              break;
            }
          }
          if (retiradasImprimir){
            await controllerCaixa.EnviarRetiradaImpressao(notificacao, dispatch, caixaUsuarioRetiradas.retiradasCaixa, () => {
            });       
          }else{
            alertar(notificacao, 'br', "SELECIONE uma Retirada ou Mais para Imprimir.", 'warning', 'icon-alert-circle-exc', 3);
          }
        }else{
          alertar(notificacao, 'br', "SEM RETIRADAS para Imprimir.", 'warning', 'icon-alert-circle-exc', 3);
        }
      }
      setLoading(false);
      
    }


    async function handleConfirmar(){
      dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Salvando Retirada...' })
      setLoading(true);
      if (!loading) {
        if (caixaUsuarioRetiradas.idCaixa === ''){
          alertar(notificacao, 'br', "O Caixa NÃO foi ABERTO.", 'warning', 'icon-alert-circle-exc', 3);
        }else if (retirada.motivo === ''){
          alertar(notificacao, 'br', "Informe o MOTIVO.", 'warning', 'icon-alert-circle-exc', 3);
        }else if (retirada.valorRetirada === '' || retirada.valorRetirada === '0,00'){
          alertar(notificacao, 'br', "Informe o VALOR.", 'warning', 'icon-alert-circle-exc', 3);  
        }else{
          await controllerCaixa.CaixaRetirada(notificacao, dispatch, caixaUsuarioRetiradas, retirada, () => {
          });     
          toggleModalRetirada();
        }
      }
      setLoading(false);
      
    }

    function handleOnChange(value, campo) {
      if (campo === 'valor'){
        setretirada({ ...retirada, valorRetirada: value })
      }else if (campo === 'motivo'){
        setretirada({ ...retirada, motivo: value })
      }      
    }

    function handleKeyPress(event, type) {
      if (event.key === "Enter") {
        switch (type) {
          case "valor":
            document.getElementById('motivo').focus();
            break;
          case "motivo":
            document.getElementById('valor').focus();
            break;
          default:
            break;
        }
      }
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalRetirada()}
            title={'Retirada de Caixa (Sangria)'}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
            <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Caixa Aberto Por</label>
                      <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '16px',  textAlign:'left' }}
                        id='caixa'
                        type='text'
                        autoComplete='off'
                        value={retirada.caixa + " - " + caixaUsuarioRetiradas.dataAbertura}
                        maxLength="15"
                      />
                  </Col>
                </Row>      

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Data Retirada</label>
                      <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'left' }}
                        id='dataRetirada'
                        type='text'
                        autoComplete='off'
                        value={retirada.dataRetirada}
                        maxLength="15"
                      />
                  </Col>
                  <Col className="padding-padrao-col">
                    <label>Usuário</label>
                      <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'left' }}
                        id='usuario'
                        type='text'
                        autoComplete='off'
                        value={retirada.usuario}
                        maxLength="15"
                      />
                  </Col>
                </Row>      
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Valor</label>
                      <Input
                          ref={inputRef}
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'right' }}
                          id='valor'
                          type='text'
                          autoComplete='off'
                          value={retirada.valorRetirada}
                          onChange={(e) =>
                            handleOnChange(maskMoeda(e.target.value), e.target.id)
                          }
                          onKeyPress={(e) => handleKeyPress(e, e.target.id)}                          
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>
                  <Col className="padding-padrao-col">
                    <label>Motivo</label>
                      <Input 
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'left' }}
                          id='motivo'
                          type='text'
                          autoComplete='off'
                          value={retirada.motivo}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                          onFocus={e => e.target.select()}
                          maxLength="25"
                      />
                  </Col>
                </Row>      

                <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                  <legend>Retiradas</legend>
                  <Row className="padding-padrao-row">
                    <div
                      className='scrollBar'
                      style={
                        tableVisible
                          ? {
                              transition: "0.5s ease-in-out",
                              visibility: "visible",
                              opacity: "1",
                              maxHeight: 140,//225,
                            }
                          : { visibility: "hidden", opacity: "0" }
                      }
                    >
                      <ReactTable
                        data={table}
                        filterable={false}
                        columns={[
                          {
                            Header: () => (<div style={{ textAlign: 'left', fontSize: '14px', color: '#444444' }}>#</div>),
                            accessor: "checkboxItem",
                            width: 30,
                            sortable: false,
                            filterable: false,
                          },
                          {
                            Header: "Usuário",
                            headerStyle: {fontSize: '10px', textAlign: 'left'},
                            accessor: "usuarioNome",
                            width: 100,
                            sortable: false,
                          },
                          {
                            Header: "Horário",
                            headerStyle: {fontSize: '10px'},
                            accessor: "dataRetirada",
                            width: 90,
                            sortable: false,
                          },
                          {
                            Header: () => (<div style={{ textAlign: 'right', fontSize: '10px'}}>VALOR</div>),
                            accessor: "valorRetirada",
                            width: 60,
                            sortable: false,
                          },
                          {
                            Header: "Motivo",
                            headerStyle: {fontSize: '10px', textAlign: 'center'},
                            accessor: "motivo",
                            width: 140,
                            sortable: false,
                          },
                        ]}
                        minRows={1}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                        noDataText=""
                        previousText="Anterior"
                        nextText="Próximo"
                        loadingText="Carregando"
                        pageText="Página"
                        ofText="de"
                        rowsText="linhas"
                      />
                    </div>
                  </Row> 
                </fieldset>    

            </ModalBasicBody>
            <Row className="padding-padrao-row">
              <Col className="padding-padrao-col" style={{ fontSize: '10px', textAlign:'left', marginLeft: '20px', marginTop: '-15px' }}>
                <label>Para Imprimir Marque</label>
              </Col>            
            </Row>                 


            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleImprimir()}
                                style={{ width: '100px' }}
                            >Imprimir
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleConfirmar()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalRetirada