import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {maskMoeda, removeMaskMoney, formatarData} from "~/Util/mascaras";



const idStore = 'CAIXA';

export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

  try {

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlCaixaPesquisar + `${idEmpresa}` + `/${pesquisa.dataInicio}` + `/${pesquisa.dataFinal}` + `/${pesquisa.situacao}`;

    dispatch({ idStore, type: 'CAIXAs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
      var caixa = response.data.data;
      if (caixa.length === 0) {
          alertar(notificacao, 'br', "Nenhum Caixa Encontrado", 'info', 'icon-bulb-63', 2)
      }else{
        
        var total = caixa.length;
        for (var i = 0; i < total; i++) {  
          if (caixa[i].status === '1'){
            caixa[i].status = 'ATIVO'
          }else{
            caixa[i].status = 'INATIVO'    
          }     
        }    
  
      }

      dispatch({ idStore, type: 'CAIXAs', value: caixa })
      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateCaixa(notificacao, dispatch, cadastroCaixa, operacao, usuario, cadastroCaixaCopia) {
try {

    let ulr = '';
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlCaixaIncluirAlterar;


    if (JSON.stringify(cadastroCaixa) === JSON.stringify(cadastroCaixaCopia)) {
      alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
      return false
    }        


    if (operacao === 'I'){
      cadastroCaixa.idCaixa = "0";
    }else{
      cadastroCaixa.idUsuarioAlteracao = usuario.idUsuarioLogin;
      cadastroCaixa.idUsuario = usuario.idUsuarioLogin;
    }

    if (cadastroCaixa.valorAbertura === '') {
      cadastroCaixa.valorAbertura = '0.00'
    }

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
          idCaixa: cadastroCaixa.idCaixa,
          idEmpresa: cadastroCaixa.idEmpresa,
          idUsuario: cadastroCaixa.idUsuario,
          dataAbertura: '',
          dataFechamento: '',
          valorAbertura: removeMaskMoney(cadastroCaixa.valorAbertura),
          idUsuarioAlteracao: cadastroCaixa.idUsuarioAlteracao,
          dataAlteracao: '',
          status: cadastroCaixa.status,
      }
    }).then(response => {
        if (response.data.message === 'Sucesso'){
          alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
        }

        return false
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = 'A' = ATIVO ou 'I' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idCaixa, statusCaixa, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCaixaAlterarStatus + `${idCaixa}` + "/" + `${statusCaixa}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Caixa Alterado com Sucesso.", 'success', 'icon-check-2', 2)
          }

          thenFunction()
          return false

      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível alterar", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*-------------------------------------------------------------------------------------
* Retornar SOMA do Fluxo de Caixa da Empresa e do usuario selecionado
*-------------------------------------------------------------------------------------*/
export function RetornarCaixaFluxoSoma(notificacao, dispatch, pesquisa, idEmpresa) {

  try {

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlCaixaFluxoSoma + `${idEmpresa}` + `/${pesquisa.dataPesquisa}` + `/${pesquisa.idUsuario}`;

    
    dispatch({ idStore, type: 'CLEAN_CAIXA_FLUXO_SOMA' });

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
      var caixa = response.data.data;
      if (caixa.length === 0) {
          alertar(notificacao, 'br', "Nenhum Caixa Encontrado", 'info', 'icon-bulb-63', 2)
      }else{
        
        let type = 'CAIXA_FLUXO_SOMA';
        dispatch({ idStore, type, campo: 'idCaixa', value: caixa.idCaixa })
        dispatch({ idStore, type, campo: 'idUsuario', value: caixa.idUsuario })
        dispatch({ idStore, type, campo: 'idEmpresa', value: caixa.idEmpresa })
        dispatch({ idStore, type, campo: 'dataAbertura', value: caixa.dataAbertura })
        if (caixa.dataFechamento !== ''){
          dispatch({ idStore, type, campo: 'dataFechamento', value: caixa.dataFechamento })
        }else{
          dispatch({ idStore, type, campo: 'dataFechamento', value: 'CAIXA ABERTO' })
        }
        

        dispatch({ idStore, type, campo: 'valorTotalPix', value: maskMoeda(caixa.valorTotalPix.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalDinheiro', value: maskMoeda(caixa.valorTotalDinheiro.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalDebito', value: maskMoeda(caixa.valorTotalDebito.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalCredito', value: maskMoeda(caixa.valorTotalCredito.toFixed(2)) })

        dispatch({ idStore, type, campo: 'valorTotalAbertura', value: maskMoeda(caixa.valorTotalAbertura.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalRetirada', value: maskMoeda(caixa.valorTotalRetirada.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalAvista', value: maskMoeda(caixa.valorTotalAvista.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalAprazo', value: maskMoeda(caixa.valorTotalAprazo.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalServico', value: maskMoeda(caixa.valorTotalServico.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorTotalEntrega', value: maskMoeda(caixa.valorTotalEntrega.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorSaldoComTaxa', value: maskMoeda(caixa.valorSaldoComTaxa.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorSaldoSemTaxa', value: maskMoeda(caixa.valorSaldoSemTaxa.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorSaldoCaixa', value: maskMoeda(caixa.valorSaldoCaixa.toFixed(2)) })
        dispatch({ idStore, type, campo: 'valorSaldoBanco', value: maskMoeda(caixa.valorSaldoBanco.toFixed(2)) })
      
      }

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*-------------------------------------------------------------------------------------
* Utilizado para FECHAR ou REABRIR 
* OPERACAO = 'R' = REABRIR / 'F' = FECHAR
*-------------------------------------------------------------------------------------*/
export async function FecharReabrirCaixa(notificacao, dispatch, caixaFluxo, idEmpresa, operacao, caixaUsuarioSenha) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');  
      ulr = api.urlBaseChefe + api.urlCaixaFecharReabrir;
 
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            idCaixa: caixaFluxo.idCaixa,
            idEmpresa: caixaFluxo.idEmpresa,
            idUsuario: caixaFluxo.idUsuario,
            fecharReabrir: operacao,
            usuarioLogin: caixaUsuarioSenha.usuario,
            usuarioSenha: caixaUsuarioSenha.senha,
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)

            var dataAbertura = caixaFluxo.dataAbertura;
            dataAbertura = dataAbertura.substr(0,10);
            dataAbertura = formatarData('BR2', dataAbertura)

            const pesquisa = {
              dataPesquisa: dataAbertura,
              idUsuario: caixaFluxo.idUsuario,
            }
        
            RetornarCaixaFluxoSoma(notificacao, dispatch, pesquisa, idEmpresa);
          }else{
            if (response.data.data.status === 'Erro'){
              alertar(notificacao, 'br', response.data.data.mensagem , 'warning', 'icon-alert-circle-exc', 2)
              return true    
            }
          }
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }
  
/*-------------------------------------------------------------------------------------
* Utilizado para Registrar Retiradas
*-------------------------------------------------------------------------------------*/
export async function CaixaRetirada(notificacao, dispatch, caixaRetirada, retirada) {
  try {
  
      let ulr = '';
      var idCaixaRetirada = '0';
      if (retirada.idCaixaRetirada !== ''){
        idCaixaRetirada = retirada.idCaixaRetirada;
      }
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');  

      ulr = api.urlBaseChefe + api.urlCaixaRetirada;
 
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            idCaixaRetirada: idCaixaRetirada,
            idCaixa: caixaRetirada.idCaixa,
            idUsuario: retirada.idUsuario,
            valorRetirada: removeMaskMoney(retirada.valorRetirada),
            dataRetirada: '',
            motivo: retirada.motivo,
            imprimir: 'N',
            status: '1',
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2);        
          }else{
            if (response.data.data.status === 'Erro'){
              alertar(notificacao, 'br', response.data.data.mensagem , 'warning', 'icon-alert-circle-exc', 2);
              return true    
            }
          }
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*--------------------------------------------------------------------------------------- 
 * BUSCAR Caixa Aberto do Usuário e Retiradas desse caixa
 * idEmpresa e idUsuario
 *---------------------------------------------------------------------------------------*/
export async function GetRetiradasCaixaUsuario(notificacao, dispatch, idEmpresa, idUsuario){
  try {
    
        dispatch({ idStore, type: 'CLEAN_USUARIO_RETIRADAS' })  

        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlCaixaRetiradasUsuario + `${idEmpresa}` + `/${idUsuario}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {

            var caixaRetirada = response.data.data;
            if (caixaRetirada.idCaixa !== 0){
              let type = 'CAIXA_USUARIO_RETIRADAS';
              dispatch({ idStore, type, campo: 'idCaixa', value: caixaRetirada.idCaixa })
              dispatch({ idStore, type, campo: 'dataAbertura', value: caixaRetirada.dataAbertura })
              dispatch({ idStore, type, campo: 'retiradasCaixa', value: caixaRetirada.retiradas })  
            }

            //thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Pedido encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}

/*-------------------------------------------------------------------------------------
* Utilizado para Enviar Retiradas para Imprimir no chefeDesktop
*-------------------------------------------------------------------------------------*/
export async function EnviarRetiradaImpressao(notificacao, dispatch, caixaUsuarioRetiradas) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');  

      ulr = api.urlBaseChefe + api.urlCaixaEnviarRetiradasImpressao;
      
      const retiradasImprimir = []
      var quantidade = caixaUsuarioRetiradas.length;
  
      for (var i = 0; i < quantidade; i++) {
        if (caixaUsuarioRetiradas[i].imprimir === 'S'){
          retiradasImprimir.push({
            IdCaixaRetirada: caixaUsuarioRetiradas[i].idCaixaRetirada,
            IdCaixa: caixaUsuarioRetiradas[i].idCaixa,
            IdUsuario: caixaUsuarioRetiradas[i].idUsuario,
            Imprimir: 'S',
          });    
        }
      }
 
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: retiradasImprimir,
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Retiradas ENVIADA para IMPRESSÃO.", 'success', 'icon-check-2', 2);        
          }else{
            if (response.data.data.status === 'Erro'){
              alertar(notificacao, 'br', response.data.data.mensagem , 'warning', 'icon-alert-circle-exc', 2);
              return true    
            }
          }
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


export function PesquisarRetiradas(notificacao, dispatch, pesquisa, idEmpresa, thenFunction) {
  try {

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlCaixaRetornarRetiradasData + `${idEmpresa}` + `/${pesquisa.dataInicio}` + `/${pesquisa.dataFinal}` + `/${'0'}`;

    dispatch({ idStore, type: 'RETIRADAs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
      var retiradas = response.data.data;
      if (retiradas.length === 0) {
          alertar(notificacao, 'br', "Nenhum Retirada Encontrada", 'info', 'icon-bulb-63', 2);
      }

      dispatch({ idStore, type: 'RETIRADAs', value: retiradas });

      thenFunction();

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}

/*--------------------------------------------------------------------------
 * Retorna Caixas Aberto da Empresa
 *--------------------------------------------------------------------------*/
export function RetornarCaixas(notificacao, dispatch, login, thenFunction) {
  try {

    var ulrPesquisa = '';
    
    ulrPesquisa = api.urlBaseChefe + api.urlCaixaRetornarCaixas + `${login.usuario.empresa}` + `/${login.usuario.idUsuarioLogin}` + `/${" "}` + `/${"A"}`;

    dispatch({ idStore, type: 'CAIXAABERTOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
      var caixas = response.data.data;
      let dataRetirada = '';
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      dataRetirada = dataAtual.toLocaleDateString();    
  
      let type = 'RETIRADA';

      if (caixas.length === 0) {
          alertar(notificacao, 'br', "Nenhum Caixa Aberto Encontrado", 'info', 'icon-bulb-63', 2)
      }else if (caixas.length === 1){
        dispatch({ idStore, type, campo: 'idCaixa', value: caixas[0].idCaixa })
        dispatch({ idStore, type, campo: 'nomeUsuarioCaixa', value: caixas[0].usuarioLogin })
        dispatch({ idStore, type, campo: 'dataAbertura', value: caixas[0].dataAbertura })
        dispatch({ idStore, type, campo: 'idUsuario', value: login.usuario.idUsuarioLogin })
        dispatch({ idStore, type, campo: 'nomeUsuarioRetirada', value: login.usuario.login })
        
        dispatch({ idStore, type, campo: 'dataRetirada', value: dataRetirada })
      }else{
        dispatch({ idStore, type: 'CAIXAABERTOs', value: caixas })
      }

      thenFunction();

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}
