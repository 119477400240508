import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"
import * as controllerFornecedor from "~/controller/Cadastros/controllerFornecedor"
import * as controllerCep from "~/controller/controllerCep"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  FormGroup,
  CustomInput,
  UncontrolledTooltip
} from "reactstrap";

const idStore = "FORNECEDOR";

const FornecedorCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  //const { ENDERECOs, cadastroCliente, cadastroClienteEndereco, ClienteCopia, invalido, invalidoEndereco } = useSelector((state) => state.Cliente);
  const { cadastroFornecedor, FornecedorCopia, invalido } = useSelector((state) => state.Fornecedor);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  const [loading, setLoading] = useState(false);

  //Carregar uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 
  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "FORNECEDOR", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "FORNECEDOR", campo: campo, value });
  }

  function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "cep":
          controllerCep.GetCep(notificacao, dispatch, cadastroFornecedor.cep, 'FORNECEDOR', 'FORNECEDOR');
          document.getElementById('numero').focus();
          break;
        default:
      }
    }
  }

  function handleStatus(campo){
    var statusFornecedor = '';
    if (isEnabled){
      statusFornecedor = '9';
      setIsEnabled(false);
    }else{
      statusFornecedor = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'FORNECEDOR', campo: campo, value: statusFornecedor })
  }

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroFornecedor.idFornecedor === '') {
      controllerFornecedor.CreateFornecedor(notificacao, dispatch, cadastroFornecedor, 'I', FornecedorCopia);
    } else {
      controllerFornecedor.CreateFornecedor(notificacao, dispatch, cadastroFornecedor, 'A', FornecedorCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_FORNECEDOR' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cpfCnpj').focus()
  };

  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          

            <Row className="justify-content-center" >
              <Col md="12">
                <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
                  <legend>Informações do Fornecedor</legend>
                  <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

                    <Col className="padding-padrao-col" lg="2" md="2">
                      <label>Id do Fornecedor</label>
                        <Input
                          id="idFornecedor"
                          type="text"
                          disabled
                          value={cadastroFornecedor.idFornecedor}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                        />
                        <br></br>
                    </Col>

                    <Col className="padding-padrao-col" lg="3" md="3">
                      <label>CPF/CNPJ</label>
                      <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                        <Input
                          id="cpfCnpj"
                          type="text"
                          value={cadastroFornecedor.cpfCnpj}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="30"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col className="padding-padrao-col" lg="7" md="3">
                      <label>Nome/Razão Social</label>
                      <FormGroup className={invalido.nome ? "has-danger" : ''}>
                        <Input
                          id="nome"
                          type="text"
                          value={cadastroFornecedor.nome}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }    
                          autoComplete="off"
                          maxLength="80"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>


                  </Row>

                  <Row style={{ marginLeft: "25px", marginRight: "25px", marginTop: '-25px'}}>
                    <Col className="padding-padrao-col" lg="3" md="2" >
                      <label>Inscrição Estadual</label>
                        <Input
                          id="inscricaoEstadual"
                          type="text"
                          value={cadastroFornecedor.inscricaoEstadual}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="25"
                        />
                        <br></br>
                    </Col>

                    <Col className="padding-padrao-col" lg="3" md="2" >
                      <label>Email</label>
                        <Input
                          id="email"
                          type="text"
                          value={cadastroFornecedor.email}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="45"
                        />
                        <br></br>
                    </Col>

                    <Col className="padding-padrao-col" lg="3" md="2" >
                      <label>Celular</label>
                        <Input
                          id="celular"
                          type="text"
                          value={cadastroFornecedor.celular}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="11"
                        />
                        <br></br>
                    </Col>


                    <Col className="text-center" md="2" >
                      <label>Status</label>
                      <p></p>
                        <Col md="10" className="text-right">
                          <Switch
                            componentleft={<label className={cadastroFornecedor.status === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroFornecedor.status === "1" ? "Ativo" : "Inativo"}</label>}
                            checked={cadastroFornecedor.status === "1"}
                            onChange={(e) =>
                              handleStatus("status")
                            }
                            value={isEnabled}
                          />
                        </Col>                  
                    </Col>
                  </Row>
                </fieldset>  


              </Col>
            </Row>
          


        </Col>
      </Row>

      <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
        <legend>Endereço</legend>
          <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

            <Col className="padding-padrao-col" lg="3" md="3">
              <label>CEP</label>
              {"  "}<a
                target="_blank"
                style={{ fontSize:"10px", color: "#d6a956" }}
              >
                *Digite e tecle ENTER para pesquisar
              </a>
                <Input
                  id="cep"
                  type="text"
                  value={cadastroFornecedor.cep}
                  onKeyPress={(e) => handleKeyPress(e, "cep")}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }
                  autoComplete="off"
                  maxLength="10"
                />
                <br></br>
            </Col>

            <Col className="padding-padrao-col" lg="7" md="3">
              <label>Endereço</label>
                <Input
                  id="endereco"
                  type="text"
                  value={cadastroFornecedor.endereco}
                  //onChange={e => onChange(e.target.value, 'nome')}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }    
                  autoComplete="off"
                  maxLength="150"
                />
                <br></br>
            </Col>
          </Row>
          <Row style={{ marginTop: '-25px', marginLeft: "25px", marginRight: "25px" }}>
          <Col className="padding-padrao-col" lg="3" md="3">
              <label>Bairro</label>
              <Input
                id="bairro"
                type="text"
                value={cadastroFornecedor.bairro}
                onChange={(e) =>
                  handleOnChange(e.target.value, e.target.id)
                }
                autoComplete="off"
                maxLength="100"
              />
              <br></br>
          </Col>

          <Col className="padding-padrao-col" lg="6" md="3">
            <label>Cidade</label>
              <Input
                id="cidade"
                type="text"
                value={cadastroFornecedor.cidade}
                onChange={(e) =>
                  handleOnChange(e.target.value, e.target.id)
                }
                autoComplete="off"
                maxLength="100"
              />
              <br></br>
          </Col>

          <Col className="padding-padrao-col" lg="1" md="3">
            <label>Estado</label>
              <Input
                id="uf"
                type="text"
                value={cadastroFornecedor.uf}
                //onChange={e => onChange(e.target.value, 'nome')}
                onChange={(e) =>
                  handleOnChange(e.target.value, e.target.id)
                }    
                autoComplete="off"
                maxLength="2"
              />
              <br></br>
          </Col>

          <Col className="padding-padrao-col" lg="2" md="3">
            <label>Número</label>
              <Input
                id="numero"
                type="text"
                value={cadastroFornecedor.numero}
                //onChange={e => onChange(e.target.value, 'nome')}
                onChange={(e) =>
                  handleOnChange(e.target.value, e.target.id)
                }    
                autoComplete="off"
                maxLength="10"
              />
              <br></br>
          </Col>
          </Row>
          <Row style={{ marginTop: '-25px', marginLeft: "25px", marginRight: "25px" }}>
            <Col className="padding-padrao-col" lg="7" md="3">
                <label>Complemento</label>
              <Input
                id="complemento"
                type="text"
                value={cadastroFornecedor.complemento}
                //onChange={e => onChange(e.target.value, 'nome')}
                onChange={(e) =>
                  handleOnChange(e.target.value, e.target.id)
                }    
                autoComplete="off"
                maxLength="50"
              />
              <br></br>
            </Col>

          </Row>
        </fieldset>

      <Row className="justify-content-center" style={{ marginTop: "0px" }}>
        <Col>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='Limpar Campos'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='Salva as Informações do Fornecedor'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>






    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cpfCnpj').focus()
    }, 100)
  }

  render() {
    return (
      <FornecedorCadastro />
    )
  }
}

export default Wizard;
