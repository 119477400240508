//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaCliente: {
      idCliente: '',
      idEmpresa: 0,
      nomeCliente: '',
      celularCliente: '',
      statusCliente: '',
  },

  cadastroCliente: {
    idCliente: '',
    idEmpresa: 0,
    nomeCliente: '',
    celularCliente: '',
    cpfCliente: '',
    fotoCliente: '',
    statusCliente: '',
  },

  cadastroClienteEndereco: {
    idEndereco: '',
    idCliente: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    complemento: '',
    statusEndereco: '',
  },
  

  invalido: {
    nomeCliente: false,
    celularCliente: false,
  },

  invalidoEndereco: {
    cep: false,
    endereco: false,
    bairro: false,
    cidade: false,
    uf: false,
    numero: false,
  },


  CLIENTEs: [],
  inicioCadastro: false,
  ClienteCopia: {},
  EnderecoCopia: {},
  ENDERECOs: [],
}

export default function CLIENTE(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CLIENTE':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaCliente: { ...state.pesquisaCliente, [action.campo]: action.value } }
              case 'CLIENTEs':
                  return { ...state, CLIENTEs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaCliente: {
                        idCliente: '',
                        idEmpresa: 0,
                        nomeCliente: '',
                        celularCliente: '',
                        statusCliente: '', 
                      },
                      CLIENTEs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'CLIENTE':
                  return { ...state, cadastroCliente: { ...state.cadastroCliente, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'CLIENTE_COPIA':
                  return { ...state, ClienteCopia: state.cadastroCliente }
              case 'CLIENTE_COPIA_STATUS':
                  return { ...state, ClienteCopia: { ...state.cadastroCliente, status: action.value } }
              case 'CLEAN_CLIENTE':
                  return {
                      ...state,
                      cadastroCliente: {
                        idCliente: '',
                        idEmpresa: 0,
                        nomeCliente: '',
                        celularCliente: '',
                        cpfCliente: '',
                        statusCliente: '',                    
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        nomeCliente: false,
                        celularCliente: false,                                        
                      }
                  }
              case 'CLIENTE_ENDERECOs':
                return { ...state, ENDERECOs: action.value }      
              case 'CLIENTE_ENDERECO':
                return { ...state, cadastroClienteEndereco: { ...state.cadastroClienteEndereco, [action.campo]: action.value } }
              case 'INVALIDO_ENDERECO':
                return { ...state, invalidoEndereco: { ...state.invalidoEndereco, [action.campo]: action.value } }  
              case 'CLIENTE_ENDERECO_COPIA':
                return { ...state, EnderecoCopia: state.cadastroClienteEndereco }
              case 'CLEAN_CLIENTE_ENDERECO':
                return {
                    ...state,
                    cadastroClienteEndereco: {
                      idEndereco: '',
                      idCliente: '',
                      cep: '',
                      endereco: '',
                      bairro: '',
                      cidade: '',
                      uf: '',
                      numero: '',
                      complemento: '',
                      statusEndereco: '',                                        
                    }
                }  
              case 'CLEAN_INVALIDO_ENDERECO':
                return {
                    ...state,
                    invalidoEndereco: {
                      cep: false,
                      endereco: false,
                      bairro: false,
                      cidade: false,
                      uf: false,
                      numero: false,                                                            
                    }
                }
              case 'CLEAN_ENDERECOs':
                return {
                    ...state,
                    ENDERECOs: []
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




