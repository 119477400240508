
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import * as controllerConfiguracao from "~/controller/Cadastros/controllerConfiguracao"
import { Switch } from "~/components/ErnComponets/ErnComponets";
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {maskPercentual, maskMoeda, maskNumbers} from "~/Util/mascaras";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  CardFooter,
  Modal,
} from "reactstrap";

// core components
import ImageUpload from "~/components/CustomUpload/ImageUpload"

const idStore = "CONFIGURACAO"

const ConfiguracaoCadastro = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);

  const { cadastroConfiguracao, cadastroConfiguracaoCopia, invalido } = useSelector((state) => state.Configuracao);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [setWidht] = useState(window.innerWidth);

  const [isEnabledMesa, setIsEnabledMesa] = useState(false);
  const [isEnabledComanda, setIsEnabledComanda] = useState(false);
  const [isEnabledPedidoSeq, setIsEnabledPedidoSeq] = useState(false);
  const [isEnabledMesaComanda, setIsEnabledMesaComanda] = useState(false);
  const [isEnabledTaxaServico, setIsEnabledTaxaServico] = useState(false);
  const [isEnabledChefDesktop, setIsEnabledChefDesktop] = useState(false);
  const [isEnabledVendedorPedido, setIsEnabledVendedorPedido] = useState(false);

  function handleUtilizaMesa(campo){
    var status = '';
    if (isEnabledMesa){
      status = 'N';
      setIsEnabledMesa(false);
    }else{
      status = 'S';
      setIsEnabledMesa(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleUtilizaComanda(campo){
    var status = '';
    if (isEnabledComanda){
      status = 'N';
      setIsEnabledComanda(false);
    }else{
      status = 'S';
      setIsEnabledComanda(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleUtilizaPedidoSeq(campo){
    var status = '';
    if (isEnabledPedidoSeq){
      status = 'N';
      setIsEnabledPedidoSeq(false);
    }else{
      status = 'S';
      setIsEnabledPedidoSeq(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleMesaComanda(campo){
    var status = '';
    if (isEnabledMesaComanda){
      status = 'N';
      setIsEnabledMesaComanda(false);
    }else{
      status = 'S';
      setIsEnabledMesaComanda(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleCobrarTaxa(campo){
    var status = '';
    if (isEnabledTaxaServico){
      status = 'N';
      setIsEnabledTaxaServico(false);
    }else{
      status = 'S';
      setIsEnabledTaxaServico(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleChefDesktop(campo){
    var status = '';
    if (isEnabledChefDesktop){
      status = 'N';
      setIsEnabledChefDesktop(false);
    }else{
      status = 'S';
      setIsEnabledChefDesktop(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  function handleVendedorPedido(campo){
    var status = '';
    if (isEnabledVendedorPedido){
      status = 'N';
      setIsEnabledVendedorPedido(false);
    }else{
      status = 'S';
      setIsEnabledVendedorPedido(true);
    }
    
    dispatch({ idStore, type: 'CONFIGURACAO', campo: campo, value: status })
  }

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroConfiguracao.idConfiguracao === '') {
      controllerConfiguracao.CreateUpdateConfiguracao(notificacao, dispatch, cadastroConfiguracao, 'I', cadastroConfiguracaoCopia);
    } else {
      controllerConfiguracao.CreateUpdateConfiguracao(notificacao, dispatch, cadastroConfiguracao, 'A', cadastroConfiguracaoCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CONFIGURACAO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('quantidadeMesas').focus()
  };

  function SetImgLogo(value) {
    if (value === 'N'){
      alertar(notificacao, 'br', "Permitido apenas imagens PNG, JPG ou GIF.", 'info', 'icon-bulb-63', 4)
    }else{
      dispatch({ idStore, type: 'CONFIGURACAO', campo: "logo", value: value });
    }
    
  };

  async function handleBuscarCadastro() {
    setLoading(true);
    if (!loading) {
        await controllerConfiguracao.GetConfiguracao(notificacao, dispatch, login.usuario.empresa, () => {
            setInicioCadastro(true);
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });

        });
        setLoading(false);
    }
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CONFIGURACAO", campo, value });
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      //controllerPesqUsuario.Pesquisar(notificacao, dispatch, pesquisaLogin);
    }
  }


  function updateWidth() {
    setWidht(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    handleBuscarCadastro();
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="18">
          <Card>
             <CardHeader className="text-center">
              <CardTitle tag="h4">
               Configurações
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  {/* <h5>Configurações do Sistema</h5> */}
                </TabPane> 

              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
/*                     onClick={() => {
                      changeActiveTab("pesquisar");
                    }}  */
                    style={{ width: "100%" }}
                  >
                    Cadastro
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">

                <Row className="justify-content-center" style={{ marginTop: '-50px' }}>
                  <Col md="6">
                    <Row>

                      <Col className="padding-padrao-col" lg="2" md="1">
                        <label>Id Config.</label>
                          <Input
                            id="idConfiguracao"
                            type="text"
                            disabled
                            value={cadastroConfiguracao.idConfiguracao}
                            //onChange={e => onChange(e.target.value, 'idUsuario')}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            autoComplete="off"
                          />
                          <br></br>
                      </Col>
                      <Col className="padding-padrao-col" lg="2" md="1">
                        <label>Qtd. de Mesas</label>
                        <FormGroup className={invalido.quantidadeMesas ? "has-danger" : ''}>
                          <Input
                            id="quantidadeMesas"
                            type="text"
                            value={cadastroConfiguracao.quantidadeMesas}
                            onChange={(e) =>
                              handleOnChange(maskNumbers(e.target.value), e.target.id)
                            }
                            autoComplete="off"
                            maxLength="3"
                          />
                          <br></br>
                          </FormGroup>
                      </Col>
                      <Col className="padding-padrao-col" lg="2" md="1">
                        <label>% Taxa de Serviço</label>
                          <Input
                            id="percentualTaxa"
                            type="text"
                            value={cadastroConfiguracao.percentualTaxa}
                            onChange={(e) =>
                              handleOnChange(maskPercentual(e.target.value), e.target.id)
                            }
                            autoComplete="off"
                            maxlength='6'
                            max='100,00'
                          />
                          <br></br>
                      </Col>
                      <Col className="padding-padrao-col" lg="2" md="1">
                        <label>Valor de Entrega</label>
                          <Input
                            id="valorEntrega"
                            type="text"
                            value={cadastroConfiguracao.valorEntrega}
                            onChange={(e) =>
                              handleOnChange(maskMoeda(e.target.value), e.target.id)
                            }
                            autoComplete="off"
                            maxlength='7'
                            max='1.00,00'
                          />
                          <br></br>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '-25px' }}>

                      <Col className="padding-padrao-col" lg="3" md="1">
                        <label>Hora Trabalho Inicial</label>
                          <Input
                            id="horaInicioTrabalho"
                            type="text"
                            value={cadastroConfiguracao.horaInicioTrabalho}
                            onChange={(e) =>
                              handleOnChange(maskNumbers(e.target.value), e.target.id)
                            }
                            autoComplete="off"
                            maxLength="2"
                          />
                          <br></br>
                      </Col>                      
                      <Col className="padding-padrao-col" lg="3" md="1">
                        <label>Hora Trabalho Final</label>
                          <Input
                            id="horaFinalTrabalho"
                            type="text"
                            value={cadastroConfiguracao.horaFinalTrabalho}
                            onChange={(e) =>
                              handleOnChange(maskNumbers(e.target.value), e.target.id)
                            }
                            autoComplete="off"
                            maxLength="2"
                          />
                          <br></br>
                      </Col> 
                      <Col className="padding-padrao-col" lg="6" md="1">
                        <label>Mensagem Recibos</label>
                        <FormGroup className={invalido.mensagemRecibo ? "has-danger" : ''}>
                          <Input
                            id="mensagemRecibo"
                            type="text"
                            value={cadastroConfiguracao.mensagemRecibo}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            autoComplete="off"
                            maxLength="40"
                          />
                          <br></br>
                          </FormGroup>
                      </Col> 


                    </Row>        
                    <Row>
                      <Col className="text-left" lg="6" md="1" >
                        <Row style={{ marginTop: '-40px' }}>
                          <Col >
                            <p></p>
                            <Col  className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.utilizaMesa === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaMesa === "S" ? "Utilizar Mesas" : "Utilizar Mesas"}</label>}
                                checked={cadastroConfiguracao.utilizaMesa === "S"}
                                onChange={(e) =>
                                  handleUtilizaMesa("utilizaMesa")
                                }
                                value={isEnabledMesa}
                              />
                            </Col>            
                          </Col>      
                        </Row>
                        <Row style={{ marginTop: '-15px' }}>
                          <Col >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.utilizaComanda === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaComanda === "S" ? "Utilizar Comandas" : "Utilizar Comandas"}</label>}
                                checked={cadastroConfiguracao.utilizaComanda === "S"}
                                onChange={(e) =>
                                  handleUtilizaComanda("utilizaComanda")
                                }
                                value={isEnabledComanda}
                              />
                            </Col>  
                          </Col>      
                        </Row>

                        <Row style={{ marginTop: '-15px' }}>
                          <Col >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.utilizaPedidoSequencial === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaPedidoSequencial === "S" ? "Pedidos Sequencial" : "Pedidos Sequencial"}</label>}
                                checked={cadastroConfiguracao.utilizaPedidoSequencial === "S"}
                                onChange={(e) =>
                                  handleUtilizaPedidoSeq("utilizaPedidoSequencial")
                                }
                                value={isEnabledPedidoSeq}
                              />
                            </Col>            
                          </Col>      
                        </Row>

                        <Row style={{ marginTop: '-15px' }}>
                          <Col >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.vincularMesaComandas === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.vincularMesaComandas === "S" ? "Vincular Comandas à Mesa" : "Vincular Comandas à Mesa"}</label>}
                                checked={cadastroConfiguracao.vincularMesaComandas === "S"}
                                onChange={(e) =>
                                  handleMesaComanda("vincularMesaComandas")
                                }
                                value={isEnabledMesaComanda}
                              />
                            </Col>            
                          </Col>      
                        </Row>

                        <Row style={{ marginTop: '-15px' }}>
                          <Col  >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.cobrarTaxaServicoEntrega === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.cobrarTaxaServicoEntrega === "S" ? "Vlr. Entrega + Tx. de Serviço" : "Vlr. Entrega + Tx. de Serviço"}</label>}
                                checked={cadastroConfiguracao.cobrarTaxaServicoEntrega === "S"}
                                onChange={(e) =>
                                  handleCobrarTaxa("cobrarTaxaServicoEntrega")
                                }
                                value={isEnabledTaxaServico}
                              />
                            </Col>            
                          </Col>      
                        </Row>

                      
                      </Col>

                      <Col className="text-left" lg="6" md="5">

                        <Row style={{ marginTop: '-40px' }}>
                          <Col  >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.utilizaChefDesktop === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaChefDesktop === "S" ? "Utiliza ChefeDesktop" : "Utiliza ChefeDesktop"}</label>}
                                checked={cadastroConfiguracao.utilizaChefDesktop === "S"}
                                onChange={(e) =>
                                  handleChefDesktop("utilizaChefDesktop")
                                }
                                value={isEnabledChefDesktop}
                              />
                            </Col>            
                          </Col>      
                        </Row>

                        <Row style={{ marginTop: '-15px' }}>
                          <Col  >
                            <p></p>
                            <Col md="10" className="text-left">
                              <Switch
                                componentright={<label className={cadastroConfiguracao.vendedorPedido === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.vendedorPedido === "S" ? "Vendedor no Pedido" : "Vendedor no Pedido"}</label>}
                                checked={cadastroConfiguracao.vendedorPedido === "S"}
                                onChange={(e) =>
                                  handleVendedorPedido("vendedorPedido")
                                }
                                value={isEnabledVendedorPedido}
                              />
                            </Col>            
                          </Col>      
                        </Row>





                      </Col>
                    </Row>        

                    {/* <Row style={{ marginTop: '-40px' }}>
                      <Col className="text-left" lg="5" md="1" >
                        <p></p>
                        <Col  className="text-left">
                          <Switch
                            componentright={<label className={cadastroConfiguracao.utilizaMesa === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaMesa === "S" ? "Utilizar Mesas" : "Utilizar Mesas"}</label>}
                            checked={cadastroConfiguracao.utilizaMesa === "S"}
                            onChange={(e) =>
                              handleUtilizaMesa("utilizaMesa")
                            }
                            value={isEnabledMesa}
                          />
                        </Col>            
                      </Col>      
                    </Row>
                    <Row style={{ marginTop: '-15px' }}>
                      <Col className="text-center" lg="5" md="2" >
                        <p></p>
                        <Col md="10" className="text-left">
                          <Switch
                            componentright={<label className={cadastroConfiguracao.utilizaComanda === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaComanda === "S" ? "Utilizar Comandas" : "Utilizar Comandas"}</label>}
                            checked={cadastroConfiguracao.utilizaComanda === "S"}
                            onChange={(e) =>
                              handleUtilizaComanda("utilizaComanda")
                            }
                            value={isEnabledComanda}
                          />
                        </Col>  
                      </Col>      
                    </Row>
                    <Row style={{ marginTop: '-15px' }}>
                      <Col className="text-center" lg="5" md="1" >
                        <p></p>
                        <Col md="10" className="text-left">
                          <Switch
                            componentright={<label className={cadastroConfiguracao.utilizaPedidoSequencial === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.utilizaPedidoSequencial === "S" ? "Pedidos Sequencial" : "Pedidos Sequencial"}</label>}
                            checked={cadastroConfiguracao.utilizaPedidoSequencial === "S"}
                            onChange={(e) =>
                              handleUtilizaPedidoSeq("utilizaPedidoSequencial")
                            }
                            value={isEnabledPedidoSeq}
                          />
                        </Col>            
                      </Col>      
                    </Row>

                    <Row style={{ marginTop: '-15px' }}>
                      <Col className="text-center" lg="7" md="1" >
                        <p></p>
                        <Col md="10" className="text-left">
                          <Switch
                            componentright={<label className={cadastroConfiguracao.vincularMesaComandas === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.vincularMesaComandas === "S" ? "Vincular Comandas à Mesa" : "Vincular Comandas à Mesa"}</label>}
                            checked={cadastroConfiguracao.vincularMesaComandas === "S"}
                            onChange={(e) =>
                              handleMesaComanda("vincularMesaComandas")
                            }
                            value={isEnabledMesaComanda}
                          />
                        </Col>            
                      </Col>      
                    </Row>

                    <Row style={{ marginTop: '-15px' }}>
                      <Col className="text-center" lg="7" md="1" >
                        <p></p>
                        <Col md="10" className="text-left">
                          <Switch
                            componentright={<label className={cadastroConfiguracao.cobrarTaxaServicoEntrega === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConfiguracao.cobrarTaxaServicoEntrega === "S" ? "Valor de Entrega + Tx. de Serviço" : "Valor de Entrega + Tx. de Serviço"}</label>}
                            checked={cadastroConfiguracao.cobrarTaxaServicoEntrega === "S"}
                            onChange={(e) =>
                              handleCobrarTaxa("cobrarTaxaServicoEntrega")
                            }
                            value={isEnabledTaxaServico}
                          />
                        </Col>            
                      </Col>      
                    </Row> */}


                  </Col>

                  <Col md="5" >
                    <Row className="justify-content-center">
                      <Col md='6' style={{ marginTop: '10px', textAlign: 'center' }}>
                        <h5>Sua Logo</h5>
                        <ImageUpload
                            id="logo"
                            changeBtnClasses="btn-simple btn-padrao"
                            addBtnClasses="btn-simple"
                            removeBtnClasses="btn-simple btn-padrao"
                            changeBtnColor="info"
                            addBtnColor="info"
                            //avatar
                            imagem={cadastroConfiguracao.logo}
                            //imagePreviewBase64={UsuarioLogin.fotoUsuario}
                            // onChange={(e) =>
                            //   handleOnChange(e.target.value, e.target.id)
                            // }
                            onChange={(e) =>
                              SetImgLogo(e)}
                            //onChange={SetImgPerfil}
                            //onChange={(e: any) => actions.setUsuario({ ...usuario, foto: e })}
                            //disabled={verCadastro}
                          />
                      </Col>

                    </Row>
                  </Col>            

                </Row>      
              </TabPane>
              <CardFooter className="text-center">
                <Row className="justify-content-center">
                  <Col md="10" >
                    <Col className="text-center">
                    {/* <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip> */}
                    <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                    </Col>
                  </Col>
                </Row>
              </CardFooter>                  
            </TabContent> 
          </Card>
          
        </Col>
      </div>

      {/* Modal Excluir */}

{/*       <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
 */}
      {/* Modal Excluir*/}
    </>
  );
};

export default ConfiguracaoCadastro;
