import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerLogin from '~/controller/controllerLogin'
import '~/views/Vendas/Pedido/Modals/styles.css'

import { maskNumbers } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

/*-----------------------------------------------------------------------------------
 * paramTela = Parametros da Tela
 *-----------------------------------------------------------------------------------*/
const ModalEsqueciSenha = ({isOpen, toggleModalSenha, paramTela, shouldClean}) => {
    const inputRef = useRef(null);

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const [usuarioSenha, setusuarioSenha] = useState({login: '', email: '', senha: '', confirmarSenha: ''})
    const dispatch = useDispatch();

    useEffect(() => {
      //window.localStorage.removeItem("ALTERAR_SENHA");
    }, [])

    function handleVoltar(){
      toggleModalSenha();
    }

    async function handleSolicitarSenha(){
      setLoading(true);
      await controllerLogin.RecuperarSenha(notificacao, dispatch, usuarioSenha.email, () => {
        toggleModalSenha();
      });
      setLoading(false);
    }

    async function handleAlterarSenha(){
      setLoading(true);
      await controllerLogin.AlterarSenha(notificacao, dispatch, login, usuarioSenha.senha, usuarioSenha.confirmarSenha, () => {
        toggleModalSenha();
      });
      setLoading(false);
    }

    function handleConfirmar(){
      if (paramTela.acaoTela === 'E'){
        handleSolicitarSenha();
      }else{
        handleAlterarSenha();
      }
    }

    function handleOnChange(value, campo) {
      switch (campo) {
        case "email":
          setusuarioSenha({ ...usuarioSenha, email: value })
          break;
        case "senha":
          setusuarioSenha({ ...usuarioSenha, senha: value })                    
          break;
        case "confirmarSenha":
          setusuarioSenha({ ...usuarioSenha, confirmarSenha: value })                    
          break;
        default:
          break;
      }  
    }

  
    // function handleOnChangeSenha(value, campo) {
    //   setusuarioPedido({ ...usuarioPedido, senha: value }) 
    // }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalSenha()}
            fontSize='26px'
            title={paramTela.titulo}
            size="sm" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                {/* <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>ID Movimento</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '22px', height: '50px', textAlign:'center' }}
                          id='idMovimento'
                          type='text'
                          autoComplete='off'
                          value={paramTela.idMovimento}
                          disabled
                      />
                  </Col>
                </Row>   */}
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <label style={{fontSize: '14px', textAlign:'center' }}
                    >{paramTela.mensagem}</label>
                  </Col>
                </Row>

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <Input 
                        style={paramTela.acaoTela === 'E' ? {textTransform:"uppercase", textAlign:'center'} : {textTransform:"uppercase", textAlign:'center', display: 'none' }}
                        id='email'
                        placeholder="Digite seu E-mail"
                        type='text'
                        autoComplete='off'
                        value={usuarioSenha.email}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        onFocus={e => e.target.select()}
                        maxLength="40"
                    />
                  </Col>                  
                </Row>  

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <Input 
                        style={paramTela.acaoTela === 'E' ? {textTransform:"uppercase", textAlign:'center', display: 'none'} : {textAlign:'center' }}
                        id='senha'
                        placeholder="Nova Senha"
                        type='password'
                        autoComplete='off'
                        value={usuarioSenha.senha}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        onFocus={e => e.target.select()}
                        maxLength="15"
                    />
                  </Col>                  
                </Row>  

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <Input 
                        style={paramTela.acaoTela === 'E' ? {textTransform:"uppercase", textAlign:'center', display: 'none'} : {textAlign:'center' }}
                        id='confirmarSenha'
                        placeholder="Confirmar Senha"
                        type='password'
                        autoComplete='off'
                        value={usuarioSenha.confirmarSenha}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        onFocus={e => e.target.select()}
                        maxLength="15"
                    />
                  </Col>                  
                </Row>  


                  {/* <Col className="padding-padrao-col">
                    <label>Senha</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'center' }}
                          id='mesaPara'
                          type='password'
                          autoComplete='off'
                          value={usuarioPedido.senha}
                          onChange={(e) =>
                            handleOnChangeSenha(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                          
                      />
                  </Col>                   */}

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleConfirmar()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalEsqueciSenha