
import Dashboard from "~/views/Dashboard.jsx";
import Login from "~/views/Login.jsx";
//import NovaSenha from "~/views/EsqueciSenha.jsx";
//import Usuarios from "~/views/Usuarios/Usuarios.jsx";
import PesquisaUsuario from "./views/Consultas/PesquisaUsuario/PesquisaUsuario.jsx";
import CadastroUsuario from "./views/Cadastros/Pessoas/CadastroUsuario/CadastroUsuario.jsx"
import ClienteCadastro from "./views/Cadastros/Pessoas/Cliente/ClientePesquisa.jsx"
import FornecedorCadastro from "./views/Cadastros/Pessoas/Fornecedor/ForecedorPesquisa.jsx";
import ProdutoCadastro from "./views/Cadastros/Produtos/Produto/ProdutoPesquisa.jsx"
import GrupoTributacao from "./views/Cadastros/Produtos/GruposTributo/GrupoTributoPesquisa.jsx"
import Configuracoes from "./views/Cadastros/Configuracao/Configuracao/Cadastro/ConfiguracaoCadastro.jsx"
import MeusDados from "./views/Cadastros/Configuracao/MeusDados/Cadastro/MeusDadosCadastro.jsx"
import ConfiguracaoNFCe from "./views/Cadastros/Configuracao/NotaFiscal/NFCe/ConfiguracaoNFCe.jsx";
import PedidoVendas from "./views/Vendas/Pedido/Steps/PedidoItem.jsx"
import Caixa from "./views/Financeiro/AbrirCaixa/CaixaPesquisa.jsx"
import CaixaResumo from "./views/Financeiro/FecharCaixa/CaixaResumo.jsx";
import CaixaRetiradas from "./views/Financeiro/RetiradaCaixa/CaixaPesquisaRetiradas.jsx";
import Entrada from "./views/Movimentacao/Entradas/EntradaPesquisa.jsx";


/*-----------------------------------------------------------------------------------------------
 * Acesso aos Menus/SubMenus do Sistema
 * ID = ID da tela na tabela 
 * nivelMenu: 
 * Menu [Nivel 0]   
 *    |_SubMenu [Nivel 1]
 *             |_SubMenu2 [Nivel 2]
 *                       |_SubMenu3 [Nivel3]
 *-----------------------------------------------------------------------------------------------*/ 

const routes = [
  {
    nivelMenu: "SubMenu0",
    path: "/dashboard",
    name: "Dashboard",
    id: 12,
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    //layout: "/admin",
    layout: "",
  },
  {
    nivelMenu: "SubMenu2",
    collapse: true,
    name: "Cadastro",
    icon: "tim-icons icon-pencil",
    state: "cadastroCollapse",
    views: [
      {
        collapse: true,
        name: "Pessoas",
        icon: " tim-icons icon-badge",
        state: "pessoasCollapse",
        className: "sideBarSubMenu",
        views: [
          {  
            path: "/clientes",
            name: "Clientes",
            id: 20,
            mini: "CL",
            component: ClienteCadastro,
            layout: "",
            
          },
          {  
            path: "/fornecedor",
            name: "Fornecedores",
            id: 21,
            mini: "FR",
            component: FornecedorCadastro,
            layout: "",
            
          },
          {
            path: "/usuario",
            name: "Usuários Login",
            id: 13,
            mini: "US",
            component: CadastroUsuario,
            layout: "",
          },
        ],
      },
      {
        collapse: true,
        name: "Produtos",
        icon: " tim-icons icon-notes",
        state: "produtosCollapse",
        className: "sideBarSubMenu",
        views: [
          {
            path: "/produtos",
            name: "Produtos",
            id: 14,
            mini: "P",
            component: ProdutoCadastro,
            layout: "",
          },
          {
            path: "/grupostributos",
            name: "Grupos de Tributos",
            id: 14,
            mini: "GT",
            component: GrupoTributacao,
            layout: "",
          },
        ],
      },
      {
        collapse: true,
        name: "Configurações",
        icon: " tim-icons icon-settings",
        state: "configuracoesCollapse",
        className: "sideBarSubMenu",
        views: [
          {
            path: "/Configuracao",
            name: "Configuração",
            id: 16,
            mini: "CF",
            component: Configuracoes,
            layout: "",
          },
          {
            path: "/meusdados",
            name: "Meus Dados",
            id: 15,
            mini: "MD",
            component: MeusDados,
            layout: "",
          },
          {
            path: "/confignfce",
            name: "Cupom Fiscal NFC-e",
            id: 15,
            mini: "CF",
            component: ConfiguracaoNFCe,
            layout: "",
          },
        ],
      },
    ],
  },
  {
    nivelMenu: "SubMenu1",
    collapse: true,
    name: "Vendas",
    icon: "tim-icons icon-cart",
    state: "vendasCollapse",
    views: [
      {
        path: "/pedidoitem",
        name: "Pedido de Vendas",
        id: 1,
        mini: "PDV",
        component: PedidoVendas,
        //layout: "/admin",
        layout: "",
      },
      // {
      //   path: "/pedidoconta",
      //   name: "Contas",
      //   id: 7,
      //   mini: "PC",
      //   component: PesquisaUsuario,
      //   //layout: "/admin",
      //   layout: "",
      // },
      // {
      //   path: "/pedidomanutencao",
      //   name: "Manutenção de Pedido",
      //   id: 10,
      //   mini: "PM",
      //   component: PesquisaUsuario,
      //   //layout: "/admin",
      //   layout: "",
      // },
      // {
      //   path: "/dashboardcozinha",
      //   name: "Dashboard Cozinha",
      //   id: 3,
      //   mini: "DC",
      //   component: PesquisaUsuario,
      //   //layout: "/admin",
      //   layout: "",
      // },
    ],
  },
  {
    nivelMenu: "SubMenu1",
    collapse: true,
    name: "Financeiro",
    icon: "tim-icons icon-money-coins",
    state: "financeiroCollapse",
    views: [
      {
        collapse: true,
        name: "Caixa",
        icon: " tim-icons icon-laptop",
        state: "caixaCollapse",
        className: "sideBarSubMenu",
        views: [
          {  
            path: "/caixaabrir",
            name: "Abrir Caixa",
            id: 18,
            mini: "AC",
            component: Caixa,
            layout: "",
          },
        {
          path: "/caixafechar",
          name: "Fechar Caixa (Fluxo)",
          id: 17,
          mini: "FC",
          component: CaixaResumo,
          layout: "",
        },
        {
          path: "/caixaretirada",
          name: "Retiradas (Sangria)",
          id: 19,
          mini: "RC",
          component: CaixaRetiradas,
          layout: "",
        },
      ],
    },
    {
      collapse: true,
      name: "Financeiro",
      icon: " tim-icons icon-coins",
      state: "tituloCollapse",
      className: "sideBarSubMenu",
      views: [
        {  
          path: "/caixaabrir",
          name: "Títulos",
          id: 23,
          mini: "TI",
          component: Caixa,
          layout: "",
        },
      ],
    }
  ],


      


      
      // {
      //   path: "/caixaabrir",
      //   name: "Abrir Caixa",
      //   id: 18,
      //   mini: "AC",
      //   component: Caixa,
      //   //layout: "/admin",
      //   layout: "",
      // },
      // {
      //   path: "/caixafechar",
      //   name: "Fechar Caixa (Fluxo)",
      //   id: 17,
      //   mini: "FC",
      //   component: CaixaResumo,
      //   //layout: "/admin",
      //   layout: "",
      // },
      // {
      //   path: "/caixaretirada",
      //   name: "Retiradas (Sangria)",
      //   id: 19,
      //   mini: "RC",
      //   component: CaixaRetiradas,
      //   //layout: "/admin",
      //   layout: "",
      // },

    //],
  },
  {
    nivelMenu: "SubMenu1",
    collapse: true,
    name: "Movimentação",
    icon: "tim-icons icon-app",
    state: "movimentacaoCollapse",
    views: [
      {
        path: "/entrada",
        name: "Entrada de Mercadoria",
        id: 22,
        mini: "EM",
        component: Entrada,
        //layout: "/admin",
        layout: "",
      },
    ],
  },

  
  {
    nivelMenu: "SubMenu1",
    collapse: true,
    name: "Consultas",
    icon: "tim-icons icon-zoom-split",
    state: "pesquisasCollapse",
    views: [
      {
        path: "/vendasperiodo",
        name: "Vendas Periodo",
        id: 11,
        mini: "VP",
        component: PesquisaUsuario,
        //layout: "/admin",
        layout: "",
      },
    ],
  },
   {
    path: "/login",
    name: "Sair",
    component: Login,
    icon: "tim-icons icon-button-power",
    layout: "/auth",
  },
  //  {
  //   path: "/novaSenha",
  //   component: NovaSenha,
  //   layout: "/auth",
  //   redirect: true,
  // },
];

export default routes;
