import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa";
import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const idStore = "CAIXA"

const ModalReabrirCaixa = ({isOpen, toggleModalReabrirCaixa, caixaFluxoSoma, shouldClean}) => {
    const inputRef = useRef(null);
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const { caixaUsuarioSenha } = useSelector((state) => state.Caixa);
    
    const [loading, setLoading] = useState(false);

    const [mesaComandaNova, setmesaComandaNova] = useState({idPedido: '', idEmpresa: '', mesaComandaNova: '' })
    const dispatch = useDispatch();

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      } 
    }, [])
 
    function handleVoltar(){
      toggleModalReabrirCaixa();
    }

    async function handleConfirmar(){
      setLoading(true);
      if (!loading) {
       await controllerCaixa.FecharReabrirCaixa(notificacao, dispatch, caixaFluxoSoma, login.usuario.empresa, 'R', caixaUsuarioSenha, () => {

       });  
      }
      setLoading(false);
      toggleModalReabrirCaixa();
    }

    function handleOnChange(value, campo) {
      if (campo === 'usuario'){
        dispatch({ idStore, type: "CAIXA_USUARIO_SENHA", campo, value });
      }else if (campo === 'senha'){
        dispatch({ idStore, type: "CAIXA_USUARIO_SENHA", campo, value });
      }      
    }
  

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalReabrirCaixa()}
            title={'Reabrir Caixa'}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Usuário</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px', height: '30px', textAlign:'center' }}
                          id='usuario'
                          type='text'
                          autoComplete='off'
                          value={caixaUsuarioSenha.usuario}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>
                  <Col className="padding-padrao-col">
                    <label>Senha</label>
                      <Input
                          ref={inputRef} 
                          style={{textTransform:"uppercase" , fontSize: '16px', height: '30px', textAlign:'center' }}
                          id='senha'
                          type='password'
                          autoComplete='off'
                          value={caixaUsuarioSenha.senha}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>

                </Row>      
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleConfirmar()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalReabrirCaixa