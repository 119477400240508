//State da tela de Pesquisa e entrada de Mercadorias

var INITIAL_STATE = {

  pesquisaEntrada: {
    dataInicio: '',
    dataFinal: '',
    numeroDocumento: '',
    idProduto: '',
    descricao: '',
  },

  cadastroEntrada: {
    idMovimento: '',
    idEmpresa: '',
    idOperacao: '',
    idCliente: '',
    idFornecedor: '',
    idUsuario: '',
    tipoMovimento: '',
    numeroDocumento: '',
    valorTotal: '',
    dataInclusao: '',
    dataMovimento: '',
    dataFinalizado: '',
    status: '',
    statusProcesso: '', //Sem dataFinalizado = Em Digitação/Finalizado
    descricaoOperacao: '',
    nomeFornecedor: '',
    cpfCnpj: '',
  },

  cadastroEntradaItem: {
    idMovimentoItem: '',
    idMovimento: '',
    idProduto: '',
    descricao: '',
    quantidade: '',
    valorItem: '',
    valorTotal: '',
    status: '',
    alterando: 'N',
  },

  pesquisaFornecedor: {
    idFornecedor: '',
    idEmpresa: 0,
    nomeFornecedor: '',
    cpfCnpj: '',
  },

  invalidoPesquisaFornecedor: {
    cpfCnpj: false,
    nomeFornecedor: false,
  },

  invalidoPesquisa: {
    dataInicio: false,
    dataFinal: false,
  },

  invalido: {
    nome: false,
  },

  invalidoItem: {
    idProduto: false,
    quantidade: false,
    valorItem: false,
  },


  ENTRADAs: [],
  ENTRADAITENs: [],
  inicioCadastro: false,
  cadastroEntradaCopia: {},
}

export default function ENTRADA(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'ENTRADA':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaEntrada: { ...state.pesquisaEntrada, [action.campo]: action.value } }
              case 'ENTRADAs':
                  return { ...state, ENTRADAs: action.value }
              case 'ENTRADAITENs':
                return { ...state, ENTRADAITENs: action.value }  
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaEntrada: {
                        dataInicio: '',
                        dataFinal: '',
                        numeroDocumento: '',
                        idProduto: '',
                        descricao: '',                                         
                      },
                      ENTRADAs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'ENTRADA':
                  return { ...state, cadastroEntrada: { ...state.cadastroEntrada, [action.campo]: action.value } }
              case 'ENTRADA_ITEM':
                return { ...state, cadastroEntradaItem: { ...state.cadastroEntradaItem, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'ENTRADA_COPIA':
                  return { ...state, cadastroEntradaCopia: state.cadastroEntrada }
              case 'ENTRADA_COPIA_STATUS':
                  return { ...state, cadastroEntradaCopia: { ...state.cadastroEntrada, status: action.value } }
              case 'CLEAN_ENTRADA':
                  return {
                      ...state,
                      cadastroEntrada: {
                        idMovimento: '',
                        idEmpresa: '',
                        idOperacao: '',
                        idCliente: '',
                        idFornecedor: '',
                        idUsuario: '',
                        tipoMovimento: '',
                        numeroDocumento: '',
                        valorTotal: '',
                        dataInclusao: '',
                        dataMovimento: '',
                        dataFinalizado: '',
                        status: '',
                        statusProcesso: '', //Sem dataFinalizado = Em Digitação/Finalizado
                        descricaoOperacao: '',
                        nomeFornecedor: '',
                        cpfCnpj: '',                    
                      }
                  }
              case 'CLEAN_ENTRADA_ITEM':
                return {
                    ...state,
                    cadastroEntradaItem: {
                      idMovimentoItem: '',
                      idMovimento: '',
                      idProduto: '',
                      descricao: '',
                      quantidade: '',
                      valorItem: '',
                      valorTotal: '',
                      status: '',       
                      alterando: 'N',                               
                    }
                }                  
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        nome: false,
                      }
                  }
              case 'CLEAN_INVALIDO_PESQUISA':
                return {
                    ...state,
                    invalido: {
                      dataInicio: false,
                      dataFinal: false,                  
                    }
                }  
              case 'PESQUISA_FORNECEDOR':
                return { ...state, pesquisaFornecedor: { ...state.pesquisaFornecedor, [action.campo]: action.value } }
              case 'CLEAN_SEARCH_PESQUISA_FORNECEDOR':
                return {
                    ...state,
                    pesquisaFornecedor: {
                      idFornecedor: '',
                      idEmpresa: 0,
                      nomeFornecedor: '',
                      cpfCnpj: '',                                         
                    },
                }
              case 'CLEAN_INVALIDO_ITEM':
                return {
                    ...state,
                    invalidoItem: {
                      idProduto: false,
                      quantidade: false,
                      valorItem: false,                  
                    }
                }
              case 'INVALIDO_PESQ_FORNECEDOR':
                return { ...state, invalidoPesquisaFornecedor: { ...state.invalidoPesquisaFornecedor, [action.campo]: action.value } }

              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}