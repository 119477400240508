//State da tela de Pesquisa e cadastro de Fornecedores

var INITIAL_STATE = {

  pesquisaFornecedor: {
    cpfCnpj: '',
    nomeFornecedor: '',
    statusFornecedor: '',
  },

  cadastroFornecedor: {
    idFornecedor: '',
    idEmpresa: 0,
    cpfCnpj: '',
    nome: '',
    inscricaoEstadual: '',
    email: '',
    celular: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    codigoMunicipio: '',
    uf: '',
    numero: '',
    complemento: '',
    status: '',
  },

  invalido: {
    nome: false,
  },

  FORNECEDOREs: [],
  inicioCadastro: false,
  FornecedorCopia: {},
}

export default function FORNECEDOR(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'FORNECEDOR':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaFornecedor: { ...state.pesquisaFornecedor, [action.campo]: action.value } }
              case 'FORNECEDOREs':
                  return { ...state, FORNECEDOREs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaFornecedor: {
                        cpfCnpj: '',
                        nomeFornecedor: '',
                        statusFornecedor: '',                     
                      },
                      FORNECEDOREs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'FORNECEDOR':
                  return { ...state, cadastroFornecedor: { ...state.cadastroFornecedor, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'FORNECEDOR_COPIA':
                  return { ...state, FornecedorCopia: state.cadastroFornecedor }
              case 'FORNECEDOR_COPIA_STATUS':
                  return { ...state, FornecedorCopia: { ...state.cadastroFornecedor, status: action.value } }
              case 'CLEAN_FORNECEDOR':
                  return {
                      ...state,
                      cadastroFornecedor: {
                        idFornecedor: '',
                        idEmpresa: 0,
                        cpfCnpj: '',
                        nome: '',
                        inscricaoEstadual: '',
                        email: '',
                        celular: '',
                        cep: '',
                        endereco: '',
                        bairro: '',
                        cidade: '',
                        codigoMunicipio: '',
                        uf: '',
                        numero: '',
                        complemento: '',
                        status: '',                    
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        nome: false,
                      }
                  }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}