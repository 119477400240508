import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";
import { campoValido } from "~/Util/campoValido";
import { func } from "prop-types";

const idStore = 'CLIENTE';


export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

    try {

      /*---------------------------------------------------------------------------
       * Valida Campos
      ---------------------------------------------------------------------------*/
/*       var idEmpresa = 'T';
      if (pesquisa.idEmpresa === 0){
        if ((pesquisa.nomeUsuario === "" || pesquisa.nomeUsuario === undefined) && (pesquisa.loginUsuario === "" || pesquisa.loginUsuario === undefined) && (pesquisa.emailUsuario === "" || pesquisa.emailUsuario === undefined)){
          document.getElementById("nomeUsuario").focus();
          alertar(notificacao, "br", "Digite um Nome ou Login ou Email para pesquisar.", "info", "icon-bulb-63", 2);
          return;    
        }
      }else {
        idEmpresa = pesquisa.idEmpresa;
      } */

      var ulrPesquisa = '';
      var celular = '-'
      var nome = '-'

      if (pesquisa.celularCliente !== "" || pesquisa.nomeCliente !== "") {
        if (pesquisa.celularCliente !== ""){
          celular = pesquisa.celularCliente;
        }
        if (pesquisa.nomeCliente !== ""){
          nome = pesquisa.nomeCliente;      
        }
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroClientePesquisaCelularNome + `${idEmpresa}` + `/${celular}` + `/${nome}`;
      }else {
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroClientePesquisa + `${idEmpresa}` + `/N`;
      }

      dispatch({ idStore, type: 'CLIENTEs', value: [] })  

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      axios.request(config)
      .then((response) => {
        /*------------------------------------------------------------------------------ 
         * Pesquisa por Celular retorna UM REGISTRO, as outras retornam LISTA
         *------------------------------------------------------------------------------*/
        
        if (response.data.data.length === 0) {
            alertar(notificacao, 'br', "Nenhum Usuário encontrado", 'info', 'icon-bulb-63', 2)
        }
          var clientes = response.data.data;  
          var totalClientes = response.data.data.length;
          for (var i = 0; i < totalClientes; i++) {
            if (clientes[i].status === '1'){
              clientes[i].status = 'ATIVO'
            }else {
              clientes[i].status = 'INATIVO'
            }
          }
          dispatch({ idStore, type: 'CLIENTEs', value: clientes })
      })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateCliente(notificacao, dispatch, cliente, clienteEndereco, operacao, clienteCopia) {
  try {

      let ulr = '';

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');


      ulr = api.urlBaseChefe + api.urlCadastroClienteIncluirAlterar; 

      if (operacao === 'I'){
        cliente.idCliente = "0";
      }else{
        if (JSON.stringify(cliente) === JSON.stringify(clienteCopia)) {
          alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
          return false
        }        
      }

      var invalido = false;
      if (cliente.celularCliente === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'celular', value: true })
          invalido = true
      }
      if (cliente.nomeCliente === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
          invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      //Transforma em Lista
      var clienteEnderecoList = [];  
      clienteEnderecoList.push(clienteEndereco)    

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            idCliente: cliente.idCliente,
            idEmpresa: cliente.idEmpresa,
            celular: cliente.celularCliente,
            nome: cliente.nomeCliente,
            cpf: cliente.cpfCliente,            
            status: cliente.statusCliente,
            clienteEnderecos: clienteEnderecoList,
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
            var cliente = response.data.data;

            //Atualiza a Table de Endereços
            if (cliente.clienteEnderecos.length > 0){
              var totalEnderecos = cliente.clienteEnderecos.length;
              for (var i = 0; i < totalEnderecos; i++) {  
                if (cliente.clienteEnderecos[i].status === '1'){
                  cliente.clienteEnderecos[i].status = 'ATIVO'
                }else {
                  cliente.clienteEnderecos[i].status = 'INATIVO'
                }      
              }    
            }
  
            dispatch({ idStore, type: 'CLEAN_CLIENTE_ENDERECO' });
            dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: cliente.clienteEnderecos })

            dispatch({ idStore, type: 'CLIENTE', campo: 'idCliente', value: cliente.idCliente })
            dispatch({ idStore, type: 'CLIENTE_COPIA' })
            return false  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


export async function GetCliente(notificacao, dispatch, idCliente, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroClienteGetIdCliente + `${idCliente}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          var cliente = response.data.data;  
          let type = 'CLIENTE';
          dispatch({ idStore, type, campo: 'idCliente', value: cliente.idCliente })
          dispatch({ idStore, type, campo: 'idEmpresa', value: cliente.idEmpresa })
          dispatch({ idStore, type, campo: 'nomeCliente', value: cliente.nome })
          dispatch({ idStore, type, campo: 'celularCliente', value: cliente.celular })
          dispatch({ idStore, type, campo: 'cpfCliente', value: cliente.cpf })
          dispatch({ idStore, type, campo: 'statusCliente', value: cliente.status })

          /*-----------------------------------------------------------------------------
          * Endereços - Mostra o Primeiro Endereço Ativo e os outros na grid
          *-----------------------------------------------------------------------------*/  
          dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: [] })  
          
          if (cliente.clienteEnderecos.length > 0){
            var totalEnderecos = cliente.clienteEnderecos.length;
            //var primeiroEnderecoAtivo = false;
            for (var i = 0; i < totalEnderecos; i++) {
/*                 if (cliente.clienteEnderecos[i].status === '1' && primeiroEnderecoAtivo === false){
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idEndereco', value: cliente.clienteEnderecos[i].idEndereco })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idCliente', value: cliente.clienteEnderecos[i].idCliente })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cep', value: cliente.clienteEnderecos[i].cep })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'endereco', value: cliente.clienteEnderecos[i].endereco})
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'bairro', value: cliente.clienteEnderecos[i].bairro })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cidade', value: cliente.clienteEnderecos[i].cidade })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'uf', value: cliente.clienteEnderecos[i].uf })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'numero', value: cliente.clienteEnderecos[i].numero })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'complemento', value: cliente.clienteEnderecos[i].complemento })
                  dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'statusEndereco', value: cliente.clienteEnderecos[i].status })
                  primeiroEnderecoAtivo = true;
                }

 */                
                if (cliente.clienteEnderecos[i].status === '1'){
                  cliente.clienteEnderecos[i].status = 'ATIVO'
                }else {
                  cliente.clienteEnderecos[i].status = 'INATIVO'
                }    

            }    
          }

          dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: cliente.clienteEnderecos })
          dispatch({ idStore, type: 'CLIENTE_COPIA' })
          thenFunction()  
              
        }else{
          alertar(notificacao, 'br', "Nenhum Cliente encontrado", 'info', 'icon-bulb-63', 2)
        }
        //dispatch({ idStore, type: 'CLIENTEs', value: response.data.data })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = '1' = ATIVO ou '9' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idCliente, statusCliente, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroClienteAlterarStatus + `${idCliente}` + "/" + `${statusCliente}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Status Alterado com SUCESSO.", 'success', 'icon-check-2', 2)
          }
          //var user = response.data.data;
          //dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          //dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
          thenFunction()
          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


/*-------------------------------------------------------------------------------------
* ENDEREÇOS
* Utilizado para ATIVAR/INATIVAR 
* STATUS = '1' = ATIVO ou '9' = INATIVO
*-------------------------------------------------------------------------------------*/

export function PesquisarEndereco(notificacao, dispatch, idCliente) {

  try {

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlCadastroClienteGetIdCliente + `${idCliente}`;

    //dispatch({ idStore, type: 'ENDERECOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhum Endereço encontrado", 'info', 'icon-bulb-63', 2)
      }

      dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: [] })  

      var cliente = response.data.data; 
      if (cliente.clienteEnderecos.length > 0){
        var totalEnderecos = cliente.clienteEnderecos.length;
        for (var i = 0; i < totalEnderecos; i++) {
            if (cliente.clienteEnderecos[i].status === '1'){
              cliente.clienteEnderecos[i].status = 'ATIVO'
            }else {
              cliente.clienteEnderecos[i].status = 'INATIVO'
            }    
        }    
      }
        dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: cliente.clienteEnderecos })
    })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


export async function AtivarInativarEndereco(notificacao, dispatch, idEndereco, statusEndereco, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroClienteEnderecoAlterarStatus + `${idEndereco}` + "/" + `${statusEndereco}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Status Alterado com SUCESSO.", 'success', 'icon-check-2', 2)
          }
          thenFunction()
          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


export async function GetClienteEndereco(notificacao, dispatch, idCliente, idEndereco, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroClienteGetIdCliente + `${idCliente}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          var cliente = response.data.data;  
          // let type = 'CLIENTE';
          // dispatch({ idStore, type, campo: 'idCliente', value: cliente.idCliente })
          // dispatch({ idStore, type, campo: 'idEmpresa', value: cliente.idEmpresa })
          // dispatch({ idStore, type, campo: 'nomeCliente', value: cliente.nome })
          // dispatch({ idStore, type, campo: 'celularCliente', value: cliente.celular })
          // dispatch({ idStore, type, campo: 'cpfCliente', value: cliente.cpf })
          // dispatch({ idStore, type, campo: 'statusCliente', value: cliente.status })

          /*-----------------------------------------------------------------------------
          * Busca os endereco e localiza o endereco selecionado para editar
          *-----------------------------------------------------------------------------*/  
          dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: [] })  
          
          if (cliente.clienteEnderecos.length > 0){
            var totalEnderecos = cliente.clienteEnderecos.length;
            var enderecoEncontrado = false;
            for (var i = 0; i < totalEnderecos; i++) {
              if (cliente.clienteEnderecos[i].idEndereco === idEndereco && enderecoEncontrado === false){
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idEndereco', value: cliente.clienteEnderecos[i].idEndereco })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idCliente', value: cliente.clienteEnderecos[i].idCliente })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cep', value: cliente.clienteEnderecos[i].cep })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'endereco', value: cliente.clienteEnderecos[i].endereco })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'bairro', value: cliente.clienteEnderecos[i].bairro })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cidade', value: cliente.clienteEnderecos[i].cidade })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'uf', value: cliente.clienteEnderecos[i].uf })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'numero', value: cliente.clienteEnderecos[i].numero })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'complemento', value: cliente.clienteEnderecos[i].complemento })
                dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'statusEndereco', value: cliente.clienteEnderecos[i].status })
                enderecoEncontrado = true;
              }

              if (cliente.clienteEnderecos[i].status === '1'){
                cliente.clienteEnderecos[i].status = 'ATIVO'
              }else {
                cliente.clienteEnderecos[i].status = 'INATIVO'
              }    

            }    
          }

          dispatch({ idStore, type: 'CLIENTE_ENDERECOs', value: cliente.clienteEnderecos })

          dispatch({ idStore, type: 'CLIENTE_ENDERECO_COPIA' })
          thenFunction()  
              
        }else{
          alertar(notificacao, 'br', "Nenhum Cliente encontrado", 'info', 'icon-bulb-63', 2)
        }
        //dispatch({ idStore, type: 'CLIENTEs', value: response.data.data })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-----------------------------------------------------------------------------
* Busca os enderecos pelo celular (Delivery)
*-----------------------------------------------------------------------------*/  
export async function GetClienteEnderecoCelular(notificacao, dispatch, celular, thenFunction){
  try {
    
        var ulrPesquisa = '';
        if (celular === ''){
          return;
        }
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroClienteEnderecoCelular + `${celular}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          var cliente = response.data.data;  

          if (cliente.idCliente !== 0){
            
            
            //Pega o ultimo endereço cadastrado e mostra na tela
            var i = cliente.clienteEnderecos.length - 1;
            let idStore = 'PEDIDO';  
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idEndereco', value: cliente.clienteEnderecos[i].idEndereco });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idCliente', value: cliente.idCliente });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'nomeEntrega', value: cliente.nome });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cep', value: cliente.clienteEnderecos[i].cep });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'endereco', value: cliente.clienteEnderecos[i].endereco });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'bairro', value: cliente.clienteEnderecos[i].bairro });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cidade', value: cliente.clienteEnderecos[i].cidade });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'uf', value: cliente.clienteEnderecos[i].uf });   
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'numero', value: cliente.clienteEnderecos[i].numero });
            dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'complemento', value: cliente.clienteEnderecos[i].complemento });   
      
            //const enderecos = []
            //var qtdEnderecos = cliente.clienteEnderecos.length;

            // //PREENCHE COM TODOS OS ENDEREÇO
            // for (var i = 0; i < qtdEnderecos; i++) {
            //   enderecos.push({
            //     idEndereco: cliente.clienteEnderecos[i].idEndereco,
            //     idCliente: cliente.idCliente,
            //     nomeCliente: cliente.nome,
            //     celular: cliente.celular,
            //     cep: cliente.clienteEnderecos[i].cep,
            //     endereco: cliente.clienteEnderecos[i].endereco,
            //     bairro: cliente.clienteEnderecos[i].bairro,
            //     cidade: cliente.clienteEnderecos[i].cidade,
            //     uf: cliente.clienteEnderecos[i].uf,
            //     numero: cliente.clienteEnderecos[i].numero,
            //     complemento: cliente.clienteEnderecos[i].complemento,
            //     status: cliente.clienteEnderecos[i].status,
            //   });    
            // }        

            
            dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'idCliente', value: cliente.idCliente });
            dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: cliente.celular });
            dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: cliente.nome });
            //dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'enderecos', value: enderecos });

            dispatch({ idStore, type: 'PEDIDO_ENDERECOs', value: [] })            

            //dispatch({ idStore, type: 'PEDIDO_ENDERECOs', value: enderecos })  



            thenFunction()
        
/*             for (var i = 0; i < qtdEnderecos; i++) {
              enderecos.push({
                idEndereco: cliente.clienteEnderecos[i].idEndereco,
                idCliente: cliente.idCliente,
                nomeCliente: cliente.nome,
                celular: cliente.celular,
                cep: cliente.clienteEnderecos[i].cep,
                endereco: cliente.clienteEnderecos[i].endereco,
                bairro: cliente.clienteEnderecos[i].bairro,
                cidade: cliente.clienteEnderecos[i].cidade,
                uf: cliente.clienteEnderecos[i].uf,
                numero: cliente.clienteEnderecos[i].numero,
                complemento: cliente.clienteEnderecos[i].complemento,
                status: cliente.clienteEnderecos[i].status,
              });    
            }        

            let idStore = 'PEDIDO'
            dispatch({ idStore, type: 'PEDIDO_ENDERECOs', value: [] })            

            dispatch({ idStore, type: 'PEDIDO_ENDERECOs', value: enderecos })  

            thenFunction() */  

          }
              
        //}else{
        //  alertar(notificacao, 'br', "Nenhum Cliente encontrado", 'info', 'icon-bulb-63', 2)
        }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


export async function GetCep(notificacao, dispatch, idEndereco, idCliente, cepInformado){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlPesquisarCEP + `${cepInformado}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          /*-----------------------------------------------------------------------------
          * Carrega dados de CEP
          *-----------------------------------------------------------------------------*/  
          var cep = response.data.data;  
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idEndereco', value: idEndereco })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'idCliente', value: idCliente })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cep', value: cepInformado })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'endereco', value: cep.endereco })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'bairro', value: cep.bairro })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'cidade', value: cep.cidade })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'uf', value: cep.estado })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'numero', value: '' })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'complemento', value: '' })
          dispatch({ idStore, type: "CLIENTE_ENDERECO", campo: 'statusEndereco', value: 'ATIVO' })
          //thenFunction()  
              
        }else{
          alertar(notificacao, 'br', "CEP Não encontrado.", 'info', 'icon-bulb-63', 2)
        }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
