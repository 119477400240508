import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {maskMoeda, removerZerosEsquerda, adicionarZerosEsquerda, formatarData, removeMaskMoney} from "~/Util/mascaras";
import { func } from "prop-types";

const idStore = 'ENTRADA';

/*--------------------------------------------------------------------------------------- 
 * BUSCA INFORMAÇÕES DE ENTRADAS
 *---------------------------------------------------------------------------------------*/
export async function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa, thenFunction){
  try {
    
        var ulrPesquisa = '';

        var idProduto = '0' //ID_PRODUTO
        var numeroDocumento = '-';
        var tipoMovimento = 'E'; //ENTRADA

        dispatch({ idStore, type: 'ENTRADAs', value: [] });
  
        if (!isNaN(parseFloat(pesquisa.idProduto)) && isFinite(pesquisa.idProduto)) {
          idProduto = pesquisa.idProduto;
        }else if (pesquisa.numeroDocumento !== '' && pesquisa.numeroDocumento !== undefined){
          numeroDocumento = pesquisa.numeroDocumento;
        }

        ulrPesquisa = api.urlBaseChefe + api.urlEntradaPesquisar + `${idEmpresa}` + `/${pesquisa.dataInicio}` + `/${pesquisa.dataFinal}` + `/${idProduto}` + `/${numeroDocumento}` + `/${tipoMovimento}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {

            if (response.data.data.length === 0){
              alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)  
              return;
            }else{
              CarregarPesquisaEntradas(notificacao, dispatch, response.data.data)
            }

            thenFunction();
              
          }else{
            alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

export function CarregarPesquisaEntradas (notificacao, dispatch, entradas){
  try {
    //var entradas = response.data.data;
    let idStore = 'ENTRADA';             
    var totalEntradas = entradas.length;
    for (var i = 0; i < totalEntradas; i++) {
      if (entradas[i].status === '1'){
        entradas[i].status = 'ATIVO'
      }else {
        entradas[i].status = 'INATIVO'
      }

      if (entradas[i].dataFinalizado !== '' && entradas[i].dataFinalizado !== null && entradas[i].dataFinalizado !== undefined){
        entradas[i].statusProcesso = 'Finalizado';
      }else{
        entradas[i].statusProcesso = 'Em Digitação';
      }
    }

    dispatch({ idStore, type: 'ENTRADAs', value: entradas })  
    
  } catch (error) {
    alertar(notificacao, 'br', "Não foi possível carregar entradas, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}

/*--------------------------------------------------------------------------------------- 
 * BUSCA INFORMAÇÕES DE UMA ENTRADA
 *---------------------------------------------------------------------------------------*/
export async function GetEntrada(notificacao, dispatch, idEmpresa, idMovimento, tipoMovimento, thenFunction){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlEntradaGet + `${idEmpresa}` + `/${idMovimento}` + `/${tipoMovimento}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            if (response.data.data.length === 0){
              alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)  
              return;
            }else{
              //Limpa Objsetos
              dispatch({ idStore, type: 'CLEAN_ENTRADA' });
              dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
              dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
              //dispatch({ idStore, type: 'ENTRADA_COPIA' });
      
              CarregarEntradaItens(notificacao, dispatch, response.data.data);                          
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function AdicionarItem(notificacao, dispatch, entrada, entradaItem, tipo, tipoOperacao, entradaCopia) {
  try {

      let ulr = '';

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');


      ulr = api.urlBaseChefe + api.urlEntradaIncluirAlterar; 

      if (JSON.stringify(entrada) === JSON.stringify(entradaCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita.", 'info', 'icon-bulb-63', 2);
        return false
      }        

      var invalido = false;
      if (entrada.idFornecedor === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'idFornecedor', value: true })
          invalido = true
      }
      if (entrada.idOperacao === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'operacao', value: true })
          invalido = true
      }

      if (entradaItem.idProduto === ''){
        dispatch({ idStore, type: 'INVALIDO', campo: 'idProduto', value: true })
        invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      var idCliente = 0;
      var idFornecedor = 0;
      if (tipo === 'E'){
        idCliente = 0;
        idFornecedor = entrada.idFornecedor;
      }else{
        idCliente = entrada.idCliente;
        idFornecedor = 0;
      }

      if (tipoOperacao === 'I'){
        entrada.idMovimento = '0';
      }

      var dataInclusao = entrada.dataInclusao;
      var dataMovimento = entrada.dataMovimento;

      var valorTotalItem = 0;
      var valorItem = 0;
      valorItem = removeMaskMoney(entradaItem.valorItem)
      valorTotalItem = entradaItem.quantidade * valorItem;

      const itemDigitado = []
      itemDigitado.push({
        idMovimentoItem: '0',
        idMovimento: entrada.idMovimento,
        idProduto: entradaItem.idProduto,
        quantidade: entradaItem.quantidade,
        valorItem: valorItem,
        valorTotal: valorTotalItem,
        status: '1',    
      });

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
              idMovimento: entrada.idMovimento,
              idEmpresa: entrada.idEmpresa,
              idOperacao: entrada.idOperacao,
              idCliente: idCliente,
              idFornecedor: idFornecedor,
              idUsuario: entrada.idUsuario,
              tipoMovimento: tipo,
              numeroDocumento: entrada.numeroDocumento,
              valorTotal: '0',
              dataInclusao: dataInclusao,
              dataMovimento: dataMovimento,
              finalizar: 'N',
              status: '1',
              itens: itemDigitado,
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)

            //Limpa Objsetos
            dispatch({ idStore, type: 'CLEAN_ENTRADA' });
            dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
            dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
            //dispatch({ idStore, type: 'ENTRADA_COPIA' });
     
            CarregarEntradaItens(notificacao, dispatch, response.data.data);    
  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*-------------------------------------------------------------------------------------
* Utilizado para ALTERAR Informações de Entrada e FINALIZAR  
* tipo = E = Entrada ou S = Saída
* finalizar = S = SIM ou N = NÃO
*-------------------------------------------------------------------------------------*/
export async function Salvar(notificacao, dispatch, entrada, tipo, finalizar, entradaCopia, thenFunction) {
  try {

      let ulr = '';

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');


      ulr = api.urlBaseChefe + api.urlEntradaIncluirAlterar; 

      if (JSON.stringify(entrada) === JSON.stringify(entradaCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita.", 'info', 'icon-bulb-63', 2);
        return false
      }        

      var invalido = false;
      if (entrada.idFornecedor === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'idFornecedor', value: true })
          invalido = true
      }
      if (entrada.idOperacao === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'operacao', value: true })
          invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      var idCliente = 0;
      var idFornecedor = 0;
      if (tipo === 'E'){
        idCliente = 0;
        idFornecedor = entrada.idFornecedor;
      }else{
        idCliente = entrada.idCliente;
        idFornecedor = 0;
      }

      var dataInclusao = entrada.dataInclusao;
      var dataMovimento = entrada.dataMovimento;

      const itemDigitado = []

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
              idMovimento: entrada.idMovimento,
              idEmpresa: entrada.idEmpresa,
              idOperacao: entrada.idOperacao,
              idCliente: idCliente,
              idFornecedor: idFornecedor,
              idUsuario: entrada.idUsuario,
              tipoMovimento: tipo,
              numeroDocumento: entrada.numeroDocumento,
              valorTotal: '0', 
              dataInclusao: dataInclusao,
              dataMovimento: dataMovimento,
              finalizar: finalizar,
              status: '1',
              itens: itemDigitado, //PASSA ZERADO
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)

            //Limpa Objsetos
            dispatch({ idStore, type: 'CLEAN_ENTRADA' });
            dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
            dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
            //dispatch({ idStore, type: 'ENTRADA_COPIA' });
     
            CarregarEntradaItens(notificacao, dispatch, response.data.data);  
            
            thenFunction();
  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*-------------------------------------------------------------------------------------
* Utilizado para ALTERAR/REMOVER Item
* Passar status = 9 para Remover
*-------------------------------------------------------------------------------------*/
export async function AlterarRemoverItem(notificacao, dispatch, entradaItem, remover) {
  try {

      let ulr = '';

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      ulr = api.urlBaseChefe + api.urlEntradaAlterarRemoverItem; 

      var statusItem = '1';

      if (remover === 'S'){
        statusItem = '9';
      }

      var valorTotalItem = 0;
      var valorItem = 0;
      valorItem = removeMaskMoney(entradaItem.valorItem)
      valorTotalItem = entradaItem.quantidade * valorItem;

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
          idMovimentoItem: entradaItem.idMovimentoItem,
          idMovimento: entradaItem.idMovimento,
          idProduto: entradaItem.idProduto,
          quantidade: entradaItem.quantidade,
          valorItem: valorItem,
          valorTotal: valorTotalItem,
          status: statusItem,      
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)

            //Limpa Objsetos
            dispatch({ idStore, type: 'CLEAN_ENTRADA' });
            dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
            dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
            //dispatch({ idStore, type: 'ENTRADA_COPIA' });
     
            CarregarEntradaItens(notificacao, dispatch, response.data.data);    
  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


/*--------------------------------------------------------------------------------------- 
 * CARREGA INFORMAÇÕES DA ENTRADA E SEUS ITENS
 *---------------------------------------------------------------------------------------*/
export function CarregarEntradaItens(notificacao, dispatch, entradaItens){
  try{

    let type = 'ENTRADA';
    dispatch({ idStore, type, campo: 'idMovimento', value: entradaItens.idMovimento })
    dispatch({ idStore, type, campo: 'idEmpresa', value: entradaItens.idEmpresa })
    dispatch({ idStore, type, campo: 'idOperacao', value: entradaItens.idOperacao })
    dispatch({ idStore, type, campo: 'idCliente', value: entradaItens.idCliente })
    dispatch({ idStore, type, campo: 'idFornecedor', value: entradaItens.idFornecedor })
    dispatch({ idStore, type, campo: 'nomeFornecedor', value: entradaItens.nome }) //Nome Cliente/Fornecedor
    dispatch({ idStore, type, campo: 'cpfCnpj', value: entradaItens.cpfCnpj }) //Nome CPF/CNPJ
    dispatch({ idStore, type, campo: 'idUsuario', value: entradaItens.idUsuario })
    dispatch({ idStore, type, campo: 'tipoMovimento', value: entradaItens.tipoMovimento })
    dispatch({ idStore, type, campo: 'numeroDocumento', value: entradaItens.numeroDocumento })
    dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(entradaItens.valorTotal.toFixed(2)) })
    dispatch({ idStore, type, campo: 'dataInclusao', value: entradaItens.dataInclusao })
    dispatch({ idStore, type, campo: 'dataMovimento', value: entradaItens.dataMovimento })
    dispatch({ idStore, type, campo: 'dataFinalizado', value: entradaItens.dataFinalizado })
    if (entradaItens.dataFinalizado !== '' && entradaItens.dataFinalizado !== null && entradaItens.dataFinalizado !== undefined){
      dispatch({ idStore, type, campo: 'statusProcesso', value: 'Finalizado: ' + formatarData('BR', entradaItens.dataFinalizado)})
    }else{
      dispatch({ idStore, type, campo: 'statusProcesso', value: 'Em Digitação' })
    }
    dispatch({ idStore, type, campo: 'status', value: entradaItens.status })

    dispatch({ idStore, type: 'ENTRADAITENs', value: entradaItens.itens })

  } catch (error) {
    alertar(notificacao, 'br', "Não foi possível carregar entrada, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*--------------------------------------------------------------------------------------- 
 * BUSCA INFORMAÇÕES DO PRODUTO QUE FOI DIGITADO
 *---------------------------------------------------------------------------------------*/
export async function GetProduto(notificacao, dispatch, idEmpresa, idProduto, thenFunction){
  try {
    
        var ulrPesquisa = '';

        var codigo = '-' //ID_PRODUTO ou CODIGO_PRODUTO
        var descricao = '-'
  
        if (!isNaN(parseFloat(idProduto)) && isFinite(idProduto)) {
          codigo = idProduto;
        }else if (idProduto !== '' && idProduto !== undefined){
          descricao = idProduto;
        }

        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetProdutosPesquisa + `${idEmpresa}` + `/${codigo}` + `/${descricao}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            //let idStore = 'PEDIDO_PESQ_PRODUTO';             
            let idStore = 'ENTRADA';
            let type = 'ENTRADA_ITEM';

            var produto = response.data.data; 
      
            if (produto.length === 0){
              alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)  
              return;
            }else if (produto.length === 1){
              dispatch({ idStore, type, campo: 'idProduto', value: produto[0].idProduto })
              dispatch({ idStore, type, campo: 'descricao', value: produto[0].descricao })
              dispatch({ idStore, type, campo: 'quantidade', value: '1' })
              dispatch({ idStore, type, campo: 'valorItem', value: maskMoeda(produto[0].valorProduto.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(produto[0].valorProduto.toFixed(2)) }) 
        
              //CarregarProduto(notificacao, dispatch, response.data.data, 0)
            }else{
              dispatch({ idStore, type: 'PRODUTOs', value: produto })        
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

/*--------------------------------------------------------------------------------------- 
 * REABRIR MOVIMENTO (ENTRADA/SAIDA)
 *---------------------------------------------------------------------------------------*/
export async function ReabrirMovimento(notificacao, dispatch, idMovimento, thenFunction){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlMovimentoReabrir + `${idMovimento}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            if (response.data.data.length === 0){
              alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)  
              return;
            }else{
              //Limpa Objsetos
              dispatch({ idStore, type: 'CLEAN_ENTRADA' });
              dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
              dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
              //dispatch({ idStore, type: 'ENTRADA_COPIA' });
      
              alertar(notificacao, 'br', "Ação Realizada com Sucesso.", 'sucess', 'icon-bulb-63', 2)  

              CarregarEntradaItens(notificacao, dispatch, response.data.data);                          
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhuma Entrada encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

/*--------------------------------------------------------------------------------------- 
 * EXCLUIR MOVIMENTO (ENTRADA/SAIDA)
 *---------------------------------------------------------------------------------------*/
export async function ExcluirMovimento(notificacao, dispatch, idMovimento, pesquisaEntrada, idEmpresa, thenFunction){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlMovimentoExcluir + `${idMovimento}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
              alertar(notificacao, 'br', "Movimento EXCLUÍDO com Sucesso", 'sucess', 'icon-bulb-63', 2)

              Pesquisar(notificacao, dispatch, pesquisaEntrada, idEmpresa, () => {
              });
  
              thenFunction();              
          }else{
            alertar(notificacao, 'br', "Nenhuma Movimento encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}
