//State de Acessos

var INITIAL_STATE = {

    //Campos para validar o Acesso
    ACESSO: {
      mostrarDashboard: '',
      cadastroProduto: '',
      cadastroGrupoTributo: '',
    },
    //Objetos para carregar lista de dados
    PerfilAcessos: [],
  }
  
  export default function ACESSO(state = INITIAL_STATE, action) {
  
    switch (action.idStore) {
  
        case 'ACESSO':
            switch (action.type) {
                case 'ACESSO':
                    return { ...state, ACESSO: { ...state.ACESSO, [action.campo]: action.value } }
                    case 'PERFIL_ACESSOS':
                      return { ...state, PerfilAcessos: action.value }    
                case 'CLEAN_ACESSO':
                    return {
                        ...state,
                        ACESSO: {
                          mostrarDashboard: '',
                          cadastroProduto: '',
                          cadastroGrupoTributo: '',                                        
                        }
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
  }  