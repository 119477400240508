import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"

import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const ModalProdutos = ({isOpen, toggleModalProdutos, paramProduto, shouldClean}) => {
    //const tableRef = useScrollBar([isOpen]);
    const { PRODUTOs } = useSelector((state) => state.PedidoPesqProduto, shallowEqual);
    const [pesquisaProduto, setPesquisaProduto] = useState({linhaSelecionada: '', idProduto: 0, codigoProduto: '', descricao: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ descricao: '' })
    const [produtosSelected, setprodutosSelected] = useState({ codigoProduto: 0 })
    const [listProdutos, setProdutos] = useState([])
    const dispatch = useDispatch();
    
    async function handlePesquisar(parametroPesquisa){
      setLoading(true);
      if (!loading) {
        //   if (parametroPesquisa === '' || parametroPesquisa === undefined){
        //     if (pesquisa.descricao !== ''){
        //         parametroPesquisa = pesquisa.descricao;         
        //     }else if (pesquisa.codigoProduto !== ''){
        //         parametroPesquisa = pesquisa.codigoProduto;                 
        //     }
        //   }
        await controllerPedidoVendas.GetProduto(notificacao, dispatch, login.usuario.empresa, parametroPesquisa, () => {
            document.getElementById("descricao").focus();  
        });  

        //   if (parametroPesquisa !== ''){

        // }else{
        //     alertar(notificacao, 'br', "Informe um PRODUTO.", 'info', 'icon-alert-circle-exc', 2);
        //   }
          setLoading(false);
      }
    }

    useEffect(() => {
      if (paramProduto !== ''){
        setpesquisa({ ...pesquisa, descricao: paramProduto })
        handlePesquisar(paramProduto);
      }
    }, [paramProduto])


    useEffect(() => {
        setProdutos(validaList(PRODUTOs))
    }, [PRODUTOs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO_PESQ_PRODUTO'
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  
          setpesquisa({ ...pesquisa, descricao: '' })
        }
    }, [shouldClean])

    function handleOnChangeDescricao(value) {
        setpesquisa({ ...pesquisa, descricao: value })
    }

    function handleVoltar(){
        toggleModalProdutos();
    }  

    function handleOk(){
      controllerPedidoVendas.CarregarProduto(notificacao, dispatch, PRODUTOs, pesquisaProduto.linhaSelecionada);
      toggleModalProdutos();
    }

    function onClick(rowIndex) {
      let produtoSelected = PRODUTOs[rowIndex]

      controllerPedidoVendas.CarregarProdutoPesquisa(notificacao, dispatch, PRODUTOs, rowIndex)
      setPesquisaProduto({linhaSelecionada: rowIndex, idProduto: produtoSelected.idProduto, codigoProduto: produtoSelected.codigoProduto, descricao: produtoSelected.descricao })
      setpesquisa({ ...pesquisa, descricao: produtoSelected.descricao })
  }

  function onDoubleClick(rowIndex) {
    let produtoSelected = PRODUTOs[rowIndex]
    setPesquisaProduto({linhaSelecionada: rowIndex, idProduto: produtoSelected.idProduto, codigoProduto: produtoSelected.codigoProduto, descricao: produtoSelected.descricao })

      handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: PRODUTOs[row.index].idProduto === pesquisaProduto.idProduto ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar(value);
        }
    }

    function handleLimpar() {
        let idStore = 'PEDIDO_PESQ_PRODUTO';     
        dispatch({ idStore, type: 'PRODUTOs', value: [] }) 
        dispatch({ idStore, type: 'CLEAN_PRODUTO' });        

        setpesquisa({ ...pesquisa, descricao: '' })
        setprodutosSelected({ ...produtosSelected, codigoProduto: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idProduto === item.idProduto)) {
                arr.push(value)
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalProdutos"
            isOpen={isOpen}
            toggle={() => toggleModalProdutos()}
            title={'Pesquisa de Produtos'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 

            <ModalBasicBody>
              {/* <Col> */}
                <Row className="padding-padrao-row">
                  <Col md="3" className="padding-padrao-col">
                      <label>Código</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='codigoProduto'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.codigoProduto}
                          onChange={(e) => handleOnChangeDescricao(e.target.value)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'15'}
                      />
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Descrição</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='descricao'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.descricao}
                          onChange={(e) => handleOnChangeDescricao(e.target.value)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                  </Col>

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <Row >
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Resultados</legend>
                       <div
                        className='scrollBar'
                        //ref={tabelaProdutos}
                        //ref={tableRef}
                        style={PRODUTOs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={PRODUTOs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'Código',
                                    accessor: 'codigoProduto',
                                    minWidth: 40,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'Descrição',
                                    accessor: 'descricao',
                                    resizable: false,
                                    sortable: false
                                }]}
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={PRODUTOs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${PRODUTOs.length} produtos encontrados`}
                  </div>
                </Col>


{/*                     Tabela Somente com Descrição
                     <fieldset className="fieldList">
                        <legend>Resultados da pesquisa</legend>
                         <div id="tabelaProdutos" className="tabelaList" ref={tabelaProdutos}>

                            {listProdutos.map((produto, key) => (
                                <label
                                    key={key}
                                    className={classNames("labelList", {
                                        labelListSelected: produto.codigoProduto === produtosSelected.codigoProduto
                                    })}
                                    onClick={() => onClickProduto(produto)}
                                >
                                    {produto.descricao}
                                </label>
                            ))}
                        </div>
                    </fieldset> */}


                {/* </Col> */}

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>

                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                //onClick={() => toggleModalProdutos()}
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalProdutos