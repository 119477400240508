import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { alertar } from "~/Util/alertar";
import * as controllerFornecedor from "~/controller/Cadastros/controllerFornecedor"

import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
    FormGroup,
} from "reactstrap";

const ModalFornecedores = ({isOpen, toggleModalFornecedores, shouldClean}) => {
    const { FORNECEDOREs, invalidoPesquisaFornecedor } = useSelector((state) => state.Fornecedor, shallowEqual);
    const [pesquisaFornecedor, setpesquisaFornecedor] = useState({linhaSelecionada: '', idFornecedor: '', cpfCnpj: '', nomeFornecedor: '', statusFornecedor: ''});
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({idEmpresa: login.usuario.empresa, nomeFornecedor: '', cpfCnpj: '', statusFornecedor: '1' });
    const [fornecedorSelected, setfornecedorSelected] = useState({ idFornecedor: 0 });
    const [fornecedor, setfornecedor] = useState([]);
    const dispatch = useDispatch();

/*     useEffect(() => {
      let idStore = 'FORNECEDOR'
      dispatch({ idStore, type: 'FORNECEDOREs', value: [] })  
      CLEAN_SEARCH
    }, []) */
    
    async function handlePesquisar(){
      setLoading(true);
      if (!loading) {
          let idStore = 'ENTRADA';
          let type = 'INVALIDO_PESQ_FORNECEDOR'
          var invalido = false;

          if (pesquisa.cpfCnpj === '' && pesquisa.nomeFornecedor === '') {
            dispatch({ idStore, type: 'INVALIDO_PESQ_FORNECEDOR', campo: 'nomeFornecedor', value: true });
            invalido = true
          }
  
        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2); 
        }else{
          await controllerFornecedor.Pesquisar(notificacao, dispatch, pesquisa, login.usuario.empresa, () => {
            document.getElementById("nome").focus();  
          });

        }            
      }
      setLoading(false);
    }

    // useEffect(() => {
    //   if (paramFornecedor !== ''){
    //     setpesquisa({ ...pesquisa, nome: paramFornecedor })
    //     handlePesquisar();
    //   }
    // }, [paramFornecedor])

    useEffect(() => {
        setfornecedor(validaList(FORNECEDOREs))
    }, [FORNECEDOREs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'FORNECEDOR'
          dispatch({ idStore, type: 'FORNECEDOREs', value: [] })  
          setpesquisa({ ...pesquisa, nome: '' })
        }
    }, [shouldClean])

    function handleOnChangeCliente(value, campo) {
      switch (campo) {
        case "nome":
          setpesquisa({ ...pesquisa, nomeFornecedor: value })
          break;
        case "cpfCnpj":
          setpesquisa({ ...pesquisa, cpfCnpj: value })                    
          break;
        default:
          break;
      }  
    }

    function handleVoltar(){
      toggleModalFornecedores();
    }


    function handleOk(){
      let idStore = 'ENTRADA'
      //let type = 'PESQUISA_FORNECEDOR';
      let type = 'ENTRADA';
      let fornecedorelected = FORNECEDOREs[pesquisaFornecedor.linhaSelecionada]
      
      dispatch({ idStore, type, campo: 'idFornecedor', value: fornecedorelected.idFornecedor })
      dispatch({ idStore, type, campo: 'idEmpresa', value: fornecedorelected.idEmpresa })
      dispatch({ idStore, type, campo: 'nomeFornecedor', value: fornecedorelected.nome })
      dispatch({ idStore, type, campo: 'cpfCnpj', value: fornecedorelected.cpfCnpj })

      toggleModalFornecedores();
    }

    function onClick(rowIndex) {
      let fornecedorelected = FORNECEDOREs[rowIndex]

      let idStore = 'ENTRADA'
      //let type = 'PESQUISA_FORNECEDOR';
      let type = 'ENTRADA';
      dispatch({ idStore, type, campo: 'idFornecedor', value: fornecedorelected.idFornecedor })
      dispatch({ idStore, type, campo: 'idEmpresa', value: fornecedorelected.idEmpresa })
      dispatch({ idStore, type, campo: 'nomeFornecedor', value: fornecedorelected.nome })
      dispatch({ idStore, type, campo: 'cpfCnpj', value: fornecedorelected.cpfCnpj })
  
      setpesquisaFornecedor({linhaSelecionada: rowIndex, idFornecedor: fornecedorelected.idFornecedor, cpfCnpj: fornecedorelected.celular, nomeFornecedor: fornecedorelected.nome })
      setpesquisa({ ...pesquisa, nome: fornecedorelected.nome })
  }

  function onDoubleClick(rowIndex) {
    let fornecedorelected = FORNECEDOREs[rowIndex]
    setpesquisaFornecedor({linhaSelecionada: rowIndex, idFornecedor: fornecedorelected.idFornecedor, cpfCnpj: fornecedorelected.cpfCnpj, nomeFornecedor: fornecedorelected.nome })

      handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: FORNECEDOREs[row.index].idFornecedor === pesquisaFornecedor.idFornecedor ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar();
        }
    }

    function handleLimpar() {
        let idStore = 'PEDIDO_PESQ_CLIENTE';     
        dispatch({ idStore, type: 'FORNECEDOREs', value: [] }) 
        dispatch({ idStore, type: 'CLEAN_CLIENTE' });        

        setpesquisa({ ...pesquisa, idEmpresa: login.usuario.empresa, cpfCnpj: '', nome: '', statusFornecedor: '1' })
        setfornecedorSelected({ ...fornecedorSelected, idFornecedor: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idFornecedor === item.idFornecedor)) {
                arr.push(value)
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalFornecedores"
            isOpen={isOpen}
            toggle={() => toggleModalFornecedores()}
            title={'Pesquisa de Fornecedores'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col md="3" className="padding-padrao-col">
                      <label>CPF/CNPJ</label>
                      {/* <FormGroup className={invalidoPesquisaFornecedor.cpfCnpj ? "has-danger" : ''}> */}
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='cpfCnpj'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.cpfCnpj}
                          onChange={(e) => handleOnChangeCliente(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                      {/* </FormGroup> */}
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Nome</label>
                      {/* <FormGroup className={invalidoPesquisaFornecedor.nomeFornecedor ? "has-danger" : ''}> */}
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='nome'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.nomeFornecedor}
                          onChange={(e) => handleOnChangeCliente(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                      {/* </FormGroup> */}
                  </Col>

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Resultados</legend>
                       <div
                        className='scrollBar'
                        style={FORNECEDOREs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={FORNECEDOREs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'CPF/CNPJ',
                                    accessor: 'cpfCnpj',
                                    minWidth: 40,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'Nome',
                                    accessor: 'nome',
                                    resizable: false,
                                    sortable: false
                                }]}
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={FORNECEDOREs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${FORNECEDOREs.length} fornecedores encontrados`}
                  </div>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>

                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalFornecedores