import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";


const idStore = 'GRUPOTRIBUTACAO';

export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

  try {

    /*---------------------------------------------------------------------------
     * Valida Campos
    ---------------------------------------------------------------------------*/
/*     if (pesquisa.codigoProduto === '' && pesquisa.descricaoProduto === ''){
      if (pesquisa.idGrupo === '0'){
        alertar(notificacao, 'br', "Informe um GRUPO.", 'info', 'icon-bulb-63', 2);
        return;
      }  
    }  
 */

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlCadastroGrupoTributacaoGetTributacaoTodas + `${idEmpresa}`;

    dispatch({ idStore, type: 'GRUPOTRIBUTACAOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhum Grupo de Tributação encontrado", 'info', 'icon-bulb-63', 2)
      }

      dispatch({ idStore, type: 'GRUPOS_TRIBUTACAO_TODOs', value: response.data.data })
      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateGrupoTributacao(notificacao, dispatch, cadastroGrupoTributacao, operacao, cadastroGrupoTributacaoCopia) {
try {

    let ulr = '';
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlCadastroGrupoTributoIncluirAlterar;


    if (JSON.stringify(cadastroGrupoTributacao) === JSON.stringify(cadastroGrupoTributacaoCopia)) {
      alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
      return false
    }        


    if (operacao === 'I'){
      cadastroGrupoTributacao.idGrupoTributacao = "0";
    }

    var invalido = false;
    if (cadastroGrupoTributacao.descricaoGrupoTributacao === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'descricaoGrupoTributacao', value: true })
        invalido = true
    }
    if (cadastroGrupoTributacao.idCfop === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'cfopSelect', value: true })
        invalido = true
    }
    if (cadastroGrupoTributacao.idIcms === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'icmsSelect', value: true })
        invalido = true
    }
    if (cadastroGrupoTributacao.idIpi === '') {
      dispatch({ idStore, type: 'INVALIDO', campo: 'ipiSelect', value: true })
      invalido = true
    }
    if (cadastroGrupoTributacao.idPis === '') {
      dispatch({ idStore, type: 'INVALIDO', campo: 'pisSelect', value: true })
      invalido = true
    }
    if (cadastroGrupoTributacao.idCofins === '') {
      dispatch({ idStore, type: 'INVALIDO', campo: 'cofinsSelect', value: true })
      invalido = true
    }

    if (invalido) {
        alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
        return true
    }

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
          IdTributacaoGrupo: cadastroGrupoTributacao.idGrupoTributacao,
          IdEmpresa: cadastroGrupoTributacao.idEmpresa,
          Descricao: cadastroGrupoTributacao.descricaoGrupoTributacao,
          IdCfop: cadastroGrupoTributacao.idCfop,
          IdIcms: cadastroGrupoTributacao.idIcms,
          IdIpi: cadastroGrupoTributacao.idIpi,
          IdPis: cadastroGrupoTributacao.idPis,
          IdCofins: cadastroGrupoTributacao.idCofins,
          Status: cadastroGrupoTributacao.statusGrupoTributacao,
      }
    }).then(response => {
        if (response.data.message === 'Sucesso'){
          alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
        }

        //dispatch({ idStore, type: 'PRODUTO', campo: 'idProduto', value: user.idUsuarioLogin })
        //dispatch({ idStore, type: 'PRODUTO_COPIA' })

        return false
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}


/*------------------------------------------------------------------------------* 
 * Retorna todos os Grupos de Tributação
 *------------------------------------------------------------------------------*/
export async function GetGruposTributacao(notificacao, dispatch, idEmpresa){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlCadastroGrupoTributacaoGetTributacaoTodas + `${idEmpresa}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {

          dispatch({ idStore, type: 'GRUPOS_TRIBUTACAO_TODOs', value: [] })
          dispatch({ idStore, type: 'GRUPOS_TRIBUTACAO_TODOs', value: response.data.data })  

        //thenFunction()
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


export async function GetTributacao(notificacao, dispatch, idEmpresa, idTributacaoGrupo, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroGrupoTributocaoIdGrupoTributacao + `${idEmpresa}` + `/${idTributacaoGrupo}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            let type = 'GRUPO_TRIBUTACAO';
            dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
            dispatch({ idStore, type, campo: 'idGrupoTributacao', value: response.data.data.idTributacaoGrupo })
            dispatch({ idStore, type, campo: 'descricaoGrupoTributacao', value: response.data.data.descricao })
            dispatch({ idStore, type, campo: 'idCfop', value: response.data.data.idCfop })
            dispatch({ idStore, type, campo: 'idIcms', value: response.data.data.idIcms })
            dispatch({ idStore, type, campo: 'idIpi', value: response.data.data.idIpi })
            dispatch({ idStore, type, campo: 'idPis', value: response.data.data.idPis })
            dispatch({ idStore, type, campo: 'idCofins', value: response.data.data.idCofins })
            dispatch({ idStore, type, campo: 'statusGrupoTributacao', value: response.data.data.status })
    
            dispatch({ idStore, type: 'GRUPO_TRIBUTACAO_COPIA' })
            thenFunction()
    
              
          }else{
            alertar(notificacao, 'br', "Nenhum Grupo de Tributação encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = 'A' = ATIVO ou 'I' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idGrupoTributacao, statusGrupoTributacao, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroGrupoTributoAlterarStatus + `${idGrupoTributacao}` + "/" + `${statusGrupoTributacao}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Grupo Tributação Alterado com Sucesso.", 'success', 'icon-check-2', 2)
          }
          //var user = response.data.data;
          //dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          //dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
          thenFunction()
          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*------------------------------------------------------------------------------* 
 * Retorna TODOS os CFOPs, ICMSs, IPIs, PISs e COFINSs
 *------------------------------------------------------------------------------*/
export async function GetTributos(notificacao, dispatch, idEmpresa){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlCadastroGrupoTributocaoGetTributacaoCadastro;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {

          dispatch({ idStore, type: 'CFOP_TODOs', value: [] })
          dispatch({ idStore, type: 'ICMS_TODOs', value: [] })
          dispatch({ idStore, type: 'IPI_TODOs', value: [] })
          dispatch({ idStore, type: 'PIS_TODOs', value: [] })
          dispatch({ idStore, type: 'COFINS_TODOs', value: [] })

          var cfopProduto = response.data.data.cfop;
          if (cfopProduto.length > 0){
            var totalCfop = cfopProduto.length;
            for (var i = 0; i < totalCfop; i++) {
              cfopProduto[i].descricao = cfopProduto[i].cfop + " - " +  cfopProduto[i].descricao;
            }
          }

          var icmsProduto = response.data.data.icms;
          if (icmsProduto.length > 0){
            var totalIcms = icmsProduto.length;
            for (var i = 0; i < totalIcms; i++) {
              icmsProduto[i].descricao = icmsProduto[i].icms + " - " +  icmsProduto[i].descricao;
            }
          }

          var ipiProduto = response.data.data.ipi;
          if (ipiProduto.length > 0){
            var totalIpi = ipiProduto.length;
            for (var i = 0; i < totalIpi; i++) {
              ipiProduto[i].descricao = ipiProduto[i].ipi + " - " +  ipiProduto[i].descricao;
            }
          }

          var pisProduto = response.data.data.pis;
          if (pisProduto.length > 0){
            var totalPis = pisProduto.length;
            for (var i = 0; i < totalPis; i++) {
              pisProduto[i].descricao = pisProduto[i].pis + " - " +  pisProduto[i].descricao;
            }
          }

          var cofinsProduto = response.data.data.cofins;
          if (cofinsProduto.length > 0){
            var totalCofins = cofinsProduto.length;
            for (var i = 0; i < totalCofins; i++) {
              cofinsProduto[i].descricao = cofinsProduto[i].cofins + " - " +  cofinsProduto[i].descricao;
            }
          }

          dispatch({ idStore, type: 'CFOP_TODOs', value: cfopProduto })
          dispatch({ idStore, type: 'ICMS_TODOs', value: icmsProduto })
          dispatch({ idStore, type: 'IPI_TODOs', value: ipiProduto })
          dispatch({ idStore, type: 'PIS_TODOs', value: pisProduto })
          dispatch({ idStore, type: 'COFINS_TODOs', value: cofinsProduto })

        //thenFunction()
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

