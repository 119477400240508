//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaProduto: {
      idEmpresa: '',
      idProduto: '',
      codigoProduto: '',
      descricaoProduto: '',
  },

  cadastroProduto: {
      idEmpresa: '',
      idProduto: '',
      idGrupo: '',
      descricaoProduto: '',
      descricaoProdutoDetalhe: '',
      codigoProduto: '',
      imagemProduto: '',
      valorProduto: '',
      impressaoProduto: '',
      tempoPreparo: 0,
      tipoProduto: '',
      unidadeProduto: '',
      ncmProduto: '',
      cestProduto: '',
      idTributacaoGrupo: '',
      statusProduto: '',
      idProdutoSelecionado: '',
      quantidade: '',
      observacaoProduto: '',
  },

  invalido: {
    descricaoProduto: false,
    idProduto: false,
  },

  PRODUTOs: [],
}

export default function PEDIDO_PESQ_PRODUTO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'PEDIDO_PESQ_PRODUTO':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaProduto: { ...state.pesquisaProduto, [action.campo]: action.value } }
              case 'PRODUTOs':
                  return { ...state, PRODUTOs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaProduto: {
                        idEmpresa: '',
                        idProduto: '',
                        codigoProduto: '',
                        descricaoProduto: '',                                                      
                      },
                      PRODUTOs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'PRODUTO':
                  return { ...state, cadastroProduto: { ...state.cadastroProduto, [action.campo]: action.value } }
              case 'CLEAN_PRODUTO':
                  return {
                      ...state,
                      cadastroProduto: {
                        idProduto: '',
                        idGrupo: '',
                        descricaoProduto: '',
                        descricaoProdutoDetalhe: '',
                        codigoProduto: '',
                        imagemProduto: '',
                        valorProduto: '',
                        impressaoProduto: '',
                        tempoPreparo: 0,
                        tipoProduto: '',
                        unidadeProduto: '',
                        ncmProduto: '',
                        cestProduto: '',
                        idTributacaoGrupo: '',
                        statusProduto: '',
                        idProdutoSelecionado: '',
                        quantidade: '',
                        observacaoProduto: '',                  
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        descricaoProduto: false,
                        idProduto: false,                                        
                      }
                  }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




