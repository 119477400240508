import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import { Select } from "~/components/ErnComponets/ErnComponets";
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskMoeda,removeMaskMoney } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";


const ModalItem = ({isOpen, toggleModalItem, pedidoPedidoItem, shouldClean}) => {

    //const { PEDIDOs } = useSelector((state) => state.PedidoItem, shallowEqual);
    //const [pesquisaPedido, setpesquisaPedido] = useState({linhaSelecionada: '', idPedido: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [itemEditado, setitemEditado] = useState({idPedido: '', idEmpresa: '', idPedidoItem: '', idProduto: '', quantidade: '', valorItem: '', desconto: '', valorTotal: '', observacaoItem: '' })
    //const [pedidosSelected, setpedidosSelected] = useState({ idPedido: 0 })
    //const [pedidos, setPedidos] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {

      //setpesquisa({ ...pesquisa, dataInicio: dataPesqInicio, dataFinal: dataPesqFinal, pedidoAberto: 'S' })

    }, [])
    
      useEffect(() => {
      if (pedidoPedidoItem !== null){
        setitemEditado({ ...itemEditado, idPedido: pedidoPedidoItem.idPedido,
                                         idEmpresa: pedidoPedidoItem.idEmpresa,
                                         idPedidoItem: pedidoPedidoItem.idPedidoItem,
                                         idProduto: pedidoPedidoItem.idProduto,
                                         quantidade: pedidoPedidoItem.quantidade,
                                         valorItem: pedidoPedidoItem.valorItem,
                                         desconto: pedidoPedidoItem.desconto,
                                         valorTotal: pedidoPedidoItem.valorTotal,
                                         observacaoItem: pedidoPedidoItem.observacaoItem                                           
         })
      }
      document.getElementById("quantidade").focus();  
    }, [])
 
/*     useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'PEDIDOs', value: [] })  
          setpesquisa({ ...pesquisa, nomeCliente: '' })
        }
    }, [shouldClean]) */

    function handleOnChange(value, campo) {
      var valorTotalItem = 0;
      var valorItem = 0;
      switch (campo) {
        case "quantidade":
          valorItem = itemEditado.valorItem;
          valorItem = removeMaskMoney(valorItem)
          valorTotalItem = value * valorItem;  
          setitemEditado({ ...itemEditado, quantidade: value, valorTotal: maskMoeda(valorTotalItem.toFixed(2))})
          break;
        case "valorItem":
          valorItem = value;
          valorItem = removeMaskMoney(valorItem)
          valorTotalItem = itemEditado.quantidade * valorItem;  
          setitemEditado({ ...itemEditado, quantidade: value, valorTotal: maskMoeda(valorTotalItem.toFixed(2))})

          setitemEditado({ ...itemEditado, valorItem: value, valorTotal: maskMoeda(valorTotalItem.toFixed(2)) })                    
          break;
        case "desconto":
          setitemEditado({ ...itemEditado, desconto: value })                    
          break;
        case "observacaoItem":
          setitemEditado({ ...itemEditado, observacaoItem: value })                    
          break;
        default:
          break;
      }   
    }

    function handleVoltar(){
      let idStore = 'PEDIDO_ITEM'
      dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });

      toggleModalItem();
    }

    async function handleGravar(){
      setLoading(true);
      if (!loading) {
        await controllerPedidoVendas.EditItem(notificacao, dispatch, itemEditado, () => {

        });  
      }
      setLoading(false);

      let idStore = 'PEDIDO_ITEM'
      dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });
      toggleModalItem();
    }

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            //handlePesquisar();
        }
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalItem()}
            title={'Alteração de Item'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                  <label>Produto</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='produtoDescricao'
                          type='text'
                          autoComplete='off'
                          value={pedidoPedidoItem.produtoDescricao}
                          disabled
                      />
                  </Col>
                </Row>      
                <Row className="padding-padrao-row">
                  <Col md="2" className="padding-padrao-col">
                      <label>Quantidade</label>
                        <Input
                            style={{textTransform:"uppercase" , fontSize: '16px' }}
                            id='quantidade'
                            type='text'
                            autoComplete='off'
                            value={itemEditado.quantidade}
                            onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                            onFocus={e => e.target.select()}
                            onKeyPress={(e) => {
                              if (e.target.value !== ''){
                                handleKeyPress(e, e.target.value);
                                if (e.key === 'Enter') e.target.select()  
                              }
                            }}
                            maxLength="15"
                        />
                  </Col>
                  <Col md="2" className="padding-padrao-col">
                      <label>Valor</label>
                        <Input
                            style={{textTransform:"uppercase" , fontSize: '16px' }}
                            id='valorItem'
                            type='text'
                            autoComplete='off'
                            value={itemEditado.valorItem}
                            onChange={(e) => handleOnChange(maskMoeda(e.target.value), e.target.id)}
                            onFocus={e => e.target.select()}
                            onKeyPress={(e) => {
                              if (e.target.value !== ''){
                                handleKeyPress(e, e.target.value);
                                if (e.key === 'Enter') e.target.select()  
                              }
                            }}
                            maxLength="15"
                        />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                      <label>Desconto (R$)</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='desconto'
                          type='text'
                          autoComplete='off'
                          value={itemEditado.desconto}
                          onChange={(e) => handleOnChange(maskMoeda(e.target.value), e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                          disabled
                      />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                      <label>Valor Total</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='valorTotal'
                          type='text'
                          autoComplete='off'
                          value={itemEditado.valorTotal}
                          onChange={(e) => handleOnChange(maskMoeda(e.target.value), e.target.id)}
                          onFocus={e => e.target.select()}
                          maxLength="15"
                          disabled
                      />
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Observação</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='observacaoItem'
                          type='text'
                          autoComplete='off'
                          value={itemEditado.observacaoItem}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                  </Col>

                </Row>


            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleGravar()}
                                style={{ width: '100px' }}
                            >Gravar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalItem