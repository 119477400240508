import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";
import { campoValido } from "~/Util/campoValido";
import { func } from "prop-types";

const idStore = 'FORNECEDOR';


export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

    try {

      /*---------------------------------------------------------------------------
       * Valida Campos
      ---------------------------------------------------------------------------*/
      var ulrPesquisa = '';
      var cpfCnpj = '-'
      var nomeFornecedor = '-'

      if (pesquisa.cpfCnpj !== "" || pesquisa.nome !== "") {
        if (pesquisa.cpfCnpj !== ""){
          cpfCnpj = pesquisa.cpfCnpj;
        }
        if (pesquisa.nomeFornecedor !== ""){
          nomeFornecedor = pesquisa.nomeFornecedor;      
        }
      }

      ulrPesquisa = api.urlBaseChefe + api.urlCadastroFornecedorPesquisa + `${idEmpresa}` + `/${cpfCnpj}` + `/${nomeFornecedor}` + `/${pesquisa.statusFornecedor}`;

      dispatch({ idStore, type: 'FORNECEDOREs', value: [] })  

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      try {

        axios.request(config)
        .then((response) => {
          /*------------------------------------------------------------------------------ 
           * Pesquisa por Celular retorna UM REGISTRO, as outras retornam LISTA
           *------------------------------------------------------------------------------*/
          
          if (response.data.data.length === 0) {
              alertar(notificacao, 'br', "Nenhum Fornecedor encontrado", 'info', 'icon-bulb-63', 2)
          }
            var fornecedor = response.data.data;  
            var qtdFornecedor = fornecedor.length;
            for (var i = 0; i < qtdFornecedor; i++) {
              if (fornecedor[i].status === '1'){
                fornecedor[i].status = 'ATIVO'
              }else {
                fornecedor[i].status = 'INATIVO'
              }
            }
            dispatch({ idStore, type: 'FORNECEDOREs', value: fornecedor })
        })
  
        
      } catch (error) {
        alertar(notificacao, 'br', "A pagina espirou.", 'warning', 'icon-alert-circle-exc', 2)
      }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateFornecedor(notificacao, dispatch, fornecedor, operacao, fornecedorCopia) {
  try {

      let ulr = '';

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');


      ulr = api.urlBaseChefe + api.urlCadastroFornecedorIncluirAlterar; 

      if (operacao === 'I'){
        fornecedor.idFornecedor = "0";
      }else{
        if (JSON.stringify(fornecedor) === JSON.stringify(fornecedorCopia)) {
          alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
          return false
        }        
      }

      var invalido = false;
      if (fornecedor.nome === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
          invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
          idFornecedor: fornecedor.idFornecedor,
          idEmpresa: fornecedor.idEmpresa,
          cpfCnpj: fornecedor.cpfCnpj,
          nome: fornecedor.nome,
          inscricaoEstadual: fornecedor.inscricaoEstadual,
          email: fornecedor.email,
          celular: fornecedor.celular,
          cep: fornecedor.cep,
          endereco: fornecedor.endereco,
          bairro: fornecedor.bairro,
          cidade: fornecedor.cidade,
          codigoMunicipio: fornecedor.codigoMunicipio,
          uf: fornecedor.uf,
          numero: fornecedor.numero,
          complemento: fornecedor.complemento,
          status: '1',      
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){
            var fornecedor = response.data.data;
  
            dispatch({ idStore, type: 'FORNECEDOR', campo: 'idFornecedor', value: fornecedor.idFornecedor })
            dispatch({ idStore, type: 'FORNECEDOR_COPIA' })

            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2);

            return false  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


export async function GetFornecedor(notificacao, dispatch, idFornecedor, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroFornecedorGet + `${idFornecedor}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          var fornecedor = response.data.data;  
          let type = 'FORNECEDOR';
          dispatch({ idStore, type, campo: 'idFornecedor', value: fornecedor.idFornecedor })
          dispatch({ idStore, type, campo: 'idEmpresa', value: fornecedor.idEmpresa })
          dispatch({ idStore, type, campo: 'cpfCnpj', value: fornecedor.cpfCnpj })
          dispatch({ idStore, type, campo: 'nome', value: fornecedor.nome })
          dispatch({ idStore, type, campo: 'inscricaoEstadual', value: fornecedor.inscricaoEstadual })
          dispatch({ idStore, type, campo: 'email', value: fornecedor.email })
          dispatch({ idStore, type, campo: 'celular', value: fornecedor.celular })
          dispatch({ idStore, type, campo: 'cep', value: fornecedor.cep })
          dispatch({ idStore, type, campo: 'endereco', value: fornecedor.endereco })
          dispatch({ idStore, type, campo: 'bairro', value: fornecedor.bairro })
          dispatch({ idStore, type, campo: 'cidade', value: fornecedor.cidade })
          dispatch({ idStore, type, campo: 'codigoMunicipio', value: fornecedor.codigoMunicipio })
          dispatch({ idStore, type, campo: 'uf', value: fornecedor.uf })
          dispatch({ idStore, type, campo: 'numero', value: fornecedor.numero })
          dispatch({ idStore, type, campo: 'complemento', value: fornecedor.complemento })
          dispatch({ idStore, type, campo: 'status', value: fornecedor.status })

          dispatch({ idStore, type: 'FORNECEDOR_COPIA' })

          thenFunction()  
              
        }else{
          alertar(notificacao, 'br', "Nenhum Fornecedor encontrado", 'info', 'icon-bulb-63', 2)
        }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


