import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerProduto from "~/controller/Cadastros/controllerProduto"
import * as controllerGrupoTributacao from "~/controller/Cadastros/controllerGrupoTributacao"
import { Select } from "~/components/ErnComponets/ErnComponets";
//import Select from "react-select";
import { maskMoeda } from "~/Util/mascaras";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";
import { alertar } from "~/Util/alertar";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

// core components
import ImageUpload from "~/components/CustomUpload/ImageUpload"

const idStore = "PRODUTO";

const tipoProduto = [
  { value: "B", label: "Bar" },
  { value: "C", label: "Cozinha" },
];

const ProdutoCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  //const { UsuarioLogin, UsuarioLoginCopia, invalido } = useSelector((state) => state.UsuarioLogin);
  const { cadastroProduto, CadastroProdutoCopia, GRUPOSTODOs, NCMs, invalido } = useSelector((state) => state.Produto);
  const { GRUPOTRIBUTACAOs } = useSelector((state) => state.GrupoTributacao);

  
  const dispatch = useDispatch();


  const [isEnabled, setIsEnabled] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PRODUTO", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "PRODUTO", campo, value });
    //dispatch({ idStore, type: "INVALIDO", campo, value });
  }

  function handleStatus(campo){
    var statusProd = '';
    if (isEnabled){
      statusProd = '9';
      setIsEnabled(false);
    }else{
      statusProd = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'PRODUTO', campo: campo, value: statusProd })
  }

  function SetImgProduto(value) {
    if (value === 'N'){
      alertar(notificacao, 'br', "Permitido apenas imagens PNG, JPG ou GIF.", 'info', 'icon-bulb-63', 4)
    }else{
      dispatch({ idStore, type: 'PRODUTO', campo: "imagemProduto", value: value });
    }
    
  };

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroProduto.idProduto === '') {
      controllerProduto.CreateUpdateProduto(notificacao, dispatch, cadastroProduto, 'I', CadastroProdutoCopia);
    } else {
      controllerProduto.CreateUpdateProduto(notificacao, dispatch, cadastroProduto, 'A', CadastroProdutoCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_PRODUTO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('codigoProduto').focus()
  };

/*   function onChangeGrupo(value) {
    dispatch({ idStore, type: "PRODUTO", campo: "idGrupo", value: value });
  } */

  useEffect(() => {
    //Preenche combo de Grupos Aba Cadastro
    controllerProduto.GetGruposProduto(notificacao, dispatch, login.usuario.empresa, 'S')
    controllerGrupoTributacao.GetGruposTributacao(notificacao, dispatch, login.usuario.empresa)
    controllerProduto.GetNCMs(notificacao, dispatch, login.usuario.empresa)
  }, []);

/*   function onChangeTipoProduto(value) {
    dispatch({ idStore, type: "PRODUTO", campo: "tipoProduto", value: value });
  }

  function onChangeGrupoTributacao(value) {
    dispatch({ idStore, type: "PRODUTO", campo: "idTributacaoGrupo", value: value });
  } */

  function onChangeCombo(campo, value) {
    dispatch({ idStore, type: "PRODUTO", campo: campo, value: value });
  }

  
  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id do Produto</label>
                      <Input
                        id="idProduto"
                        type="text"
                        disabled
                        value={cadastroProduto.idProduto}
                        //onChange={e => onChange(e.target.value, 'idUsuario')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>
                  <Col className="padding-padrao-col" lg="1" md="1">
                    <label>Seu Código</label>
                      <Input
                        id="codigoProduto"
                        type="text"
                        value={cadastroProduto.codigoProduto}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Grupo</label>
                    <Select
                      id="grupoTodosSelect"
                      select={{ value: "idGrupo", label: "descricao" }}
                      //first={"Selecione"}
                      value={{ value: cadastroProduto.idGrupo }}
                      options={GRUPOSTODOs}
                      //isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo("idGrupo", value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>


{/*                   <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Grupo</label>
                    <FormGroup className={invalido.idGrupo ? "has-danger" : ''}>
                      <Input
                        id="idGrupo"
                        type="text"
                        value={cadastroProduto.idGrupo}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                    </FormGroup>
                  </Col> */}

                  <Col className="padding-padrao-col" lg="6" md="3">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricaoProduto ? "has-danger" : ''}>
                      <Input
                        id="descricaoProduto"
                        type="text"
                        value={cadastroProduto.descricaoProduto}
                        //onChange={e => onChange(e.target.value, 'nome')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                </Row>
                <Row style={{ marginTop: '-25px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>Composição do Produto (Opcional)</label>
                    <FormGroup className={invalido.descricaoProdutoDetalhe ? "has-danger" : ''}>
                      <Input
                        id="descricaoProdutoDetalhe"
                        type="text"
                        value={cadastroProduto.descricaoProdutoDetalhe}
                        //onChange={e => onChange(e.target.value, 'nome')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row style={{ marginTop: '-25px' }}>
                <Col className="padding-padrao-col" lg="2" md="2" >
                    <label>Valor de Venda</label>
                    <FormGroup className={invalido.valorProduto ? "has-danger" : ''}>
                      <Input
                        id="valorProduto"
                        type="text"
                        value={cadastroProduto.valorProduto}
                        onChange={(e) =>
                          handleOnChange(maskMoeda(e.target.value), e.target.id)
                        }
                        autoComplete="off"
                        maxLength="15"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Tipo de Produto</label>
                    <Select
                      id="tipoProdutoSelect"
                      //select={{ value: "idGrupo", label: "descricao" }}
                      //first={"Selecione"}
                      value={{ value: cadastroProduto.tipoProduto }}
                      options={tipoProduto}
                      //isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo("tipoProduto", value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>


{/*                   <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Tipo de Produto</label>
                    <FormGroup className={invalido.tipoProduto ? "has-danger" : ''}>
                      <Input
                        id="tipoProduto"
                        type="text"
                        value={cadastroProduto.tipoProduto}
                        //onChange={e => onChange(e.target.value, 'email')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>

                  </Col> */}

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Tempo Preparo</label>
                    <FormGroup className={invalido.tempoPreparo ? "has-danger" : ''}>
                      <Input
                        id="tempoPreparo"
                        type="text"
                        value={cadastroProduto.tempoPreparo}
                        //onChange={e => onChange(e.target.value, 'email')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>




                   <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroProduto.statusProduto === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroProduto.statusProduto === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroProduto.statusProduto === "1"}
                          onChange={(e) =>
                            handleStatus("statusProduto")
                          }
                          value={isEnabled}
                        />
                      </Col>            

      



{/*                     <p className="adjustments-line">
                      <div >
                        <span className="label-switch">Inativo</span>
                        <Switch
                          //id="statusUsuario"
                          //onChange={this.handleActiveMode}
                          onChange={(e) =>
                             handleStatus("statusUsuario")
                          }
                          //value={this.state.darkMode}
                          checked={UsuarioLogin.statusUsuario === 'A'}
                          onText=""
                          offText=""
                          trackColor={{false: '#767577', true: '#81b0ff'}}
                          thumbColor={isEnabled ? '#f5dd4b' : '#06661e'}
                          value={isEnabled}

                        />
                        <span className="label-switch">Ativo</span>
                      </div>
                    </p> */}
                  </Col>
 

{/*                       <Col className="padding-padrao-col" lg="2" md="3" >
                        <p className="category">Status</p>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">Inativo</span>
                          <CustomInput
                            type="switch"
                            id="switch-5"
                            className="mt-n4"
                          />
                          <span className="ml-n2">Ativo</span>
                        </div>
                      </Col> */}

                </Row>

                <Row style={{ marginTop: '-25px' }}>

                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>NCM</label>
                    <Select
                      id="ncmSelect"
                      select={{ value: "ncm", label: "descricao" }}
                      //first={"Selecione"}
                      value={{ value: cadastroProduto.ncmProduto }}
                      options={NCMs}
                      //isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo("ncmProduto", value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>

{/*                   <Col lg="auto">
                    <Button
                        style={{marginLeft:'-15px', marginTop: '27px', width: '40px', marginBottom: '0px' }}
                        color="info"
                        //onClick={() => toggleModalCEST()}
                    >
                        <Icon icon="search" className="fa-lg" />
                    </Button>
                  </Col> */}


                </Row>

                <Row style={{ marginTop: '-10px' }}>
                  <Col className="padding-padrao-col" lg="5" md="3">
                    <label>Grupo de Tributação</label>
                    <Select
                      id="grupoTributacaoSelect"
                      select={{ value: "idTributacaoGrupo", label: "descricaoTributacao" }}
                      //first={"Selecione"}
                      value={{ value: cadastroProduto.idTributacaoGrupo }}
                      options={GRUPOTRIBUTACAOs}
                      //isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo("idTributacaoGrupo", value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>

                  <Col md='5' style={{ marginTop: '10px', textAlign: 'center' }}>
                      <h5>Imagem do Produto</h5>
                      <ImageUpload
                          id="imagemProduto"
                          changeBtnClasses="btn-simple btn-padrao"
                          addBtnClasses="btn-simple"
                          removeBtnClasses="btn-simple btn-padrao"
                          changeBtnColor="info"
                          addBtnColor="info"
                          //avatar
                          imagem={cadastroProduto.imagemProduto}
                          //imagePreviewBase64={UsuarioLogin.fotoUsuario}
                          // onChange={(e) =>
                          //   handleOnChange(e.target.value, e.target.id)
                          // }
                          onChange={(e) =>
                            SetImgProduto(e)}
                          //onChange={SetImgPerfil}
                          //onChange={(e: any) => actions.setUsuario({ ...usuario, foto: e })}
                          //disabled={verCadastro}
                      />
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('codigoProduto').focus()
    }, 100)
  }

  render() {
    return (
      <ProdutoCadastro />
    )
  }
}

export default Wizard;
