import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerLogin from '~/controller/controllerLogin'
import '~/views/Vendas/Pedido/Modals/styles.css'

import { maskNumbers } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

/*-----------------------------------------------------------------------------------
 * Parametros da Tela
 *-----------------------------------------------------------------------------------*/
const ModalLicenca = ({isOpen, toggleModalLicenca, paramTela, shouldClean}) => {
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const [usuario, setusuario] = useState({nomeEmpresa: ''})
    const dispatch = useDispatch();

    function handleVoltar(){
      toggleModalLicenca();
    }

    async function handleConfirmar(){
      setLoading(true);
      await controllerLogin.LiberarLicençaUmDia(notificacao, dispatch, login.usuario.empresa, login.usuario.token, () => {
        toggleModalLicenca();
      });
      setLoading(false);
    }


    function handleOnChange(value, campo) {
      switch (campo) {
        case "nomeEmpresa":
          setusuario({ ...usuario, nomeEmpresa: value })
          break;
        default:
          break;
      }  
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalLicenca()}
            fontSize='26px'
            title={paramTela.titulo}
            size="sm" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <label style={{fontSize: '14px', textAlign:'center' }}
                    >{paramTela.mensagem}</label>
                  </Col>
                </Row>

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <Input 
                        style={{
                          textAlign: 'center',
                        }}        
                        disabled
                        id='nomeEmpresa'
                        type='text'
                        autoComplete='off'
                        value={login.usuario.nomeEmpresa}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        onFocus={e => e.target.select()}
                    />
                  </Col>                  
                </Row>  
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <label style={{fontSize: '14px', textAlign:'center' }}
                    >{paramTela.mensagem2}</label>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <div 
                      style={{
                        textAlign: 'center',
                        position: "relative",
                        minHeight: "15px",
                        marginTop: "30px",
                      }}                  
                  >
                    <a 
                      style={{fontSize: '16px', color: '#db7d01', textDecoration: 'underline'}}
                      href="https://www.chefefaster.com.br/investimento" target="_blank" rel="noopener noreferrer"
                      className="pull-right"
                      //onClick={(e) => handleModalEsqueceuSenha()}
                    >
                      <i className="tim-icons icon-spaceship" /> Renovar Licença
                    </a>
                  </div>


                </Row>


            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                //className='btn btn-green'
                                color="default"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            {/* <Button
                                color="info"
                                type="button"
                                onClick={() => handleConfirmar()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button> */}
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalLicenca