//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaCliente: {
      idCliente: '',
      idEmpresa: 0,
      nomeCliente: '',
      celularCliente: '',
      statusCliente: '',
  },

  cadastroCliente: {
    idCliente: '',
    idEmpresa: 0,
    nomeCliente: '',
    celularCliente: '',
    cpfCliente: '',
    fotoCliente: '',
    statusCliente: '',
  },

  cadastroClienteEndereco: {
    idEndereco: '',
    idCliente: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    complemento: '',
    statusEndereco: '',
  },

  CLIENTEs: [],
  ENDERECOs: [],
}

export default function PEDIDO_PESQ_CLIENTE(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'PEDIDO_PESQ_CLIENTE':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaCliente: { ...state.pesquisaCliente, [action.campo]: action.value } }
              case 'CLIENTEs':
                  return { ...state, CLIENTEs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaCliente: {
                        idCliente: '',
                        idEmpresa: 0,
                        nomeCliente: '',
                        celularCliente: '',
                        statusCliente: '', 
                      },
                      CLIENTEs: []
                  }
              case 'CLIENTE':
                  return { ...state, cadastroCliente: { ...state.cadastroCliente, [action.campo]: action.value } }
              case 'CLEAN_CLIENTE':
                  return {
                      ...state,
                      cadastroCliente: {
                        idCliente: '',
                        idEmpresa: 0,
                        nomeCliente: '',
                        celularCliente: '',
                        cpfCliente: '',
                        statusCliente: '',                    
                      }
                  }
              case 'CLIENTE_ENDERECOs':
                return { ...state, ENDERECOs: action.value }      
              case 'CLIENTE_ENDERECO':
                return { ...state, cadastroClienteEndereco: { ...state.cadastroClienteEndereco, [action.campo]: action.value } }
              case 'CLEAN_CLIENTE_ENDERECO':
                return {
                    ...state,
                    cadastroClienteEndereco: {
                      idEndereco: '',
                      idCliente: '',
                      cep: '',
                      endereco: '',
                      bairro: '',
                      cidade: '',
                      uf: '',
                      numero: '',
                      complemento: '',
                      statusEndereco: '',                                        
                    }
                }  
              case 'CLEAN_ENDERECOs':
                return {
                    ...state,
                    ENDERECOs: []
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




