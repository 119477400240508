import moment from "moment";

export function maskCPF(value) {
    if (value != null){
        value = value.toString()
        value = ("00000000000" + value).slice(-11);
        let newValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3\-\$4");
        return newValue    
    }
}

export function maskCEST(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 2 && length <= 5) {
        newValue = newValue.replace(/(\d{2})/, "$1.");
        return newValue
    }
    if (length > 5) {
        newValue = newValue.replace(/(\d{2})(\d{3})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskNCM(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 4 && length <= 6) {
        newValue = newValue.replace(/(\d{4})/, "$1.");
        return newValue
    }
    if (length > 6) {
        newValue = newValue.replace(/(\d{4})(\d{2})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskICMS(value) {
    value = value.toString()
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskCFOP(value) {
    let newValue = value.replace(/[\D]/g, '')
        let length = newValue.length
        if (length > 1) {
            newValue = newValue.replace(/(\d{1})/, "$1.");
            return newValue
        }
        return newValue
}

export function maskNumbers(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskEnquadramentoIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskValor(value) {
    var newValue = value.toString().replace(/\D+/g, '')
    newValue = newValue.toString().replace(/[^\d]+/g, '')
    newValue = newValue.replace(/(\d{1})(\d{2})$/, "$1,$2") // coloca ponto antes dos ultimos digitos 
    return newValue
}

export function maskMoeda(value) {
    var newValue = value.toString().replace(/\D/g, '');
    newValue = newValue.replace(/\D/g, "") // permite digitar apenas numero
    newValue = newValue.replace(/(\d{1})(\d{14})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
    newValue = newValue.replace(/(\d{1})(\d{11})$/, "$1.$2") // coloca ponto antes dos ultimos 11 digitos
    newValue = newValue.replace(/(\d{1})(\d{8})$/, "$1.$2") // coloca ponto antes dos ultimos 8 digitos
    newValue = newValue.replace(/(\d{1})(\d{5})$/, "$1.$2") // coloca ponto antes dos ultimos 5 digitos
    newValue = newValue.replace(/(\d{1})(\d{1,2})$/, "$1,$2") // coloca virgula antes dos ultimos 2 digitos
    return newValue
}
export function removeMaskMoney(value){

    var newValue = value.toString();
    newValue = newValue.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return newValue
}

export function formatarData(type  , value) {
    if(type === 'BR'){
        var newValue = moment(value.toString().replace(/\//g, '/'), 'YYYY-MM-DD').format('DD/MM/YYYY')
        return newValue
    } else if (type === 'BR2'){
        var newValue = moment(value.toString().replace(/\//g, '/'), 'DD-MM-YYYY').format('YYYY-MM-DD')
        return newValue
    } else if (type === 'EN'){
        var newValue = moment(value.toString().replace(/\//g, '/'), 'DD/MM/YYYY').format('YYYY-MM-DD')
        return newValue
    }
}

export function maskPercentual(value) {
    var newValue = value.toString().replace(/\D/g, '');
    newValue = (newValue / 100).toFixed(2) + '';
    newValue = newValue.replace(".", ",");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    newValue = newValue.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return newValue
}

export function removerZerosEsquerda(value){
    var newValeu = value.toString().replace(/(^0+(?=\d))/g,'')
    return newValeu
}

export function adicionarZerosEsquerda(value, qtdZeros){
    var newValeu = value.toString().padStart(qtdZeros, '0');
    return newValeu
}
