//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  DASHBOARD: {
    departamentos: 0,
    perfis: 0,
    usuariosAtivo: 0,
    erros: 0,
  },

  pesqempresa: [],
  EMPRESAs: [],
  USUARIOs: [],
  
}

export default function DASHBOARD(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'DASHBOARD':
          switch (action.type) {
               case 'CHART1':
                    window.localStorage.setItem('CHART1', action.value);
                    return { ...state }                  
               case 'CHART_SEMANAL':
                    window.localStorage.setItem('CHART_SEMANAL', action.value);
                    return { ...state }                  
               case 'CHART_DIARIO':
                    window.localStorage.setItem('CHART_DIARIO', action.value);
                    return { ...state }          
                case 'CHART_VENDIDOS_ANO':
                    window.localStorage.setItem('CHART_VENDIDOS_ANO', action.value);
                    return { ...state }          
                case 'QTD_VENDIDOS_ANO':
                    window.localStorage.setItem('QTD_VENDIDOS_ANO', action.value);
                    return { ...state }          
                case 'CHART_VENDIDOS_MES':
                    window.localStorage.setItem('CHART_VENDIDOS_MES', action.value);
                    return { ...state }          
                case 'QTD_VENDIDOS_MES':
                    window.localStorage.setItem('QTD_VENDIDOS_MES', action.value);
                    return { ...state }  
                case 'CHART_VENDIDOS_BALCAO':
                    window.localStorage.setItem('CHART_VENDIDOS_BALCAO', action.value);
                    return { ...state }          
                case 'CHART_VENDIDOS_ENTREGA':
                    window.localStorage.setItem('CHART_VENDIDOS_ENTREGA', action.value);
                    return { ...state }          
                case 'VALOR_TOTAL_BALCAO':
                    window.localStorage.setItem('VALOR_TOTAL_BALCAO', action.value);
                    return { ...state }          
                case 'VALOR_TOTAL_ENTREGA':
                    window.localStorage.setItem('VALOR_TOTAL_ENTREGA', action.value);
                    return { ...state }          
                case 'EMPRESA_NOME':
                    window.localStorage.setItem('EMPRESA_NOME', action.value);
                    return { ...state }          
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




