import axios from "axios";
import api from "~/Util/api.js";
import history from "~/Util/history";
import { alertar } from "~/Util/alertar";
import { campoValido } from "~/Util/campoValido";
import * as controllerDashboard from "~/controller/controllerDashboard.js";
import global from "~/Util/global";



//import * as https from "https"
//import { HttpsProxyAgent } from "https-proxy-agent";

//import { ProxyAgent } from 'proxy-agent';


//var basicAuth = "Basic " + btoa(api.apiUser + ":" + api.apiPass);
var idStore = "LOGIN";
//const avatar = require("~/assets/img/face-0.jpg");
const avatar = "https://integrador.ernetwork.com.br/img/face-0.jpg";

export function login(login, notificacao, dispatch, thenFunction) {
  try {
    if (!campoValido(login.usuario.login)) {
      document.getElementById("login").focus();
      alertar(notificacao, "br", "Digite um Login ou e-mail", "info", "icon-bulb-63", 2);
      return;
    }

    if (!campoValido(login.usuario.senha)) {
      document.getElementById("senha").focus();
      alertar(notificacao, "br", "Digite uma senha", "info", "icon-bulb-63", 2);
      return;
    }

    //autenticarAdmin(login, notificacao, dispatch);

    autenticarUsuario(login, notificacao, dispatch, thenFunction);

  } catch (e) {
    alertar(notificacao, "br", "Não foi possível concluir", "warning", "icon-alert-circle-exc", 2);
  }
}

//Desloga o usuario e volta para a tela inicial
export function logout(dispatch) {
  dispatch({ idStore, type: "LOGOUT" });
  dispatch({ idStore: "USUARIOS", type: "CLEAN_CADASTRO" });
  dispatch({ idStore: "USUARIOS", type: "CLEAN_SEARCH" });
  window.localStorage.removeItem("USUARIO");
  window.localStorage.removeItem("TOKEN_KEY");
  window.localStorage.removeItem("CHART1");
  window.localStorage.removeItem("CHART_SEMANAL");
  window.localStorage.removeItem("CHART_DIARIO");
  window.localStorage.removeItem("DEPARTAMENTOS");
  
  window.localStorage.removeItem("CHART_VENDIDOS_ANO");
  window.localStorage.removeItem("QTD_VENDIDOS_ANO");
  window.localStorage.removeItem("CHART_VENDIDOS_MES");
  window.localStorage.removeItem("QTD_VENDIDOS_MES");
  window.localStorage.removeItem("PERFIS");
  window.localStorage.removeItem("USUARIOS_ATIVOS");
  window.localStorage.removeItem("ERROS");
  window.localStorage.removeItem("ULTIMAS_INTEGRACOES");
  window.localStorage.removeItem("ULTIMOS_ERROS");
  history.push("/auth/login");
}

//Deixa na tela de Login com o usuário e esperando a senha
export function lock(dispatch) {
  dispatch({ idStore, type: "LOCK" });
  //dispatch({ idStore: 'USUARIOS', type: 'CLEAN_CADASTRO' });
  //dispatch({ idStore: 'USUARIOS', type: 'CLEAN_SEARCH' });
  //window.localStorage.removeItem('USUARIO');
  window.localStorage.removeItem('TOKEN_KEY');
 // window.localStorage.removeItem('TOPFIVE')
  history.push('/auth/login');
}

/*-------------------------------------------------------------------------------
* Autentica o usuário de login
*--------------------------------------------------------------------------------*/ 
function autenticarUsuario(login, notificacao, dispatch, thenFunction){
  try {
    dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 25 });
    var url = '';
    url = api.urlBaseChefe + 'login/authenticateweb';

    axios({
        method: 'POST',
        maxBodyLength: Infinity,
        url: url,
        headers: {"Content-Type": "application/json"},
        data: {
            email: login.usuario.login,
            senha: login.usuario.senha,
            anoGrafico: '2024',
        }
    }).then(response => {
        dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 100 });
        var mensagemRetorno = response.data.data.mensagemErro;
        if (response.data.message === "Sucesso"){
          var user = response.data.data;
          dispatch({ idStore, type: 'USUARIO', campo: 'idUsuarioLogin', value: user.idUsuario })
          dispatch({ idStore, type: 'USUARIO', campo: 'login', value: user.loginUsuario })
          dispatch({ idStore, type: 'USUARIO', campo: 'email', value: user.email })
          dispatch({ idStore, type: 'USUARIO', campo: 'nome', value: user.loginUsuario }) //MUDAR
          dispatch({ idStore, type: 'USUARIO', campo: 'empresa', value: user.idEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'nomeEmpresa', value: user.nomeEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'token', value: user.token })
          //dispatch({ idStore, type: 'USUARIO', campo: 'perfilAcesso', value: user.perfilAcessoUsuario })

          dispatch({ idStore, type: 'USUARIO', campo: 'quantidadeMesas', value: user.configuracaoEmpresa.quantidadeMesas })
          dispatch({ idStore, type: 'USUARIO', campo: 'percentualTaxa', value: user.configuracaoEmpresa.percentualTaxa })
          dispatch({ idStore, type: 'USUARIO', campo: 'mesasComandas', value: user.configuracaoEmpresa.mesasComandas })
          dispatch({ idStore, type: 'USUARIO', campo: 'logo', value: user.configuracaoEmpresa.logo })
          dispatch({ idStore, type: 'USUARIO', campo: 'alterarSenha', value: user.alterarSenha })
          dispatch({ idStore, type: 'USUARIO', campo: 'qtdDiasLicenca', value: user.qtdDiasLicenca })
          dispatch({ idStore, type: 'USUARIO', campo: 'usoLicencaDia', value: user.usoLicencaDia })
          dispatch({ idStore, type: 'USUARIO', campo: 'tipoPlano', value: user.tipoPlano })
          

          dispatch({ idStore, type: 'USUARIO', campo: 'utilizaCfastDesktop', value: user.configuracaoEmpresa.utilizaCfastDesktop })
          dispatch({ idStore, type: 'USUARIO', campo: 'vendedorPedido', value: user.configuracaoEmpresa.vendedorPedido })


          window.localStorage.setItem('ALTERAR_SENHA', user.alterarSenha); //Armazena para usario alterar a senha
          window.localStorage.setItem('QTD_DIAS_LICENCA', user.qtdDiasLicenca); //Armazena quantidade de dias da licença para validar no login

          //alterarSenha = user.alterarSenha;

          VerificarAcessoMenu(user.perfilAcessoUsuario, dispatch);

          
/*           dispatch({ idStore, type: 'USUARIO', campo: 'nomeEmpresa', value: user.nomeEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'enderecoEmpresa', value: user.enderecoEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'bairroEmpresa', value: user.bairroEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'cidadeEmpresa', value: user.cidadeEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'ufEmpresa', value: user.ufEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'numeroEmpresa', value: user.numeroEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'telefoneEmpresa', value: user.telefoneEmpresa })
          //dispatch({ idStore, type: 'USUARIO', campo: 'loginUsuario', value: user.loginUsuario })
          dispatch({ idStore, type: 'USUARIO', campo: 'alterarSenha', value: user.alterarSenha })
          dispatch({ idStore, type: 'USUARIO', campo: 'utilizaIntegracao', value: user.utilizaIntegracao })
          dispatch({ idStore, type: 'USUARIO', campo: 'qtdDiasLicenca', value: user.qtdDiasLicenca })
          dispatch({ idStore, type: 'USUARIO', campo: 'tipoPlano', value: user.tipoPlano })
          dispatch({ idStore, type: 'USUARIO', campo: 'idConfiguracao', value: user.configuracaoEmpresa.idConfiguracao })
          //dispatch({ idStore, type: 'USUARIO', campo: 'idEmpresa', value: user.configuracaoEmpresa.idEmpresa })
          dispatch({ idStore, type: 'USUARIO', campo: 'quantidadeMesas', value: user.configuracaoEmpresa.quantidadeMesas })
          dispatch({ idStore, type: 'USUARIO', campo: 'percentualTaxa', value: user.configuracaoEmpresa.percentualTaxa })
          dispatch({ idStore, type: 'USUARIO', campo: 'cobrarTaxaServicoEntrega', value: user.configuracaoEmpresa.cobrarTaxaServicoEntrega })
          dispatch({ idStore, type: 'USUARIO', campo: 'mensagemRecibo', value: user.configuracaoEmpresa.mensagemRecibo })
          dispatch({ idStore, type: 'USUARIO', campo: 'mesasComandas', value: user.configuracaoEmpresa.mesasComandas })
          dispatch({ idStore, type: 'USUARIO', campo: 'vincularMesaComandas', value: user.configuracaoEmpresa.vincularMesaComandas })
 */

          if (user.foto != null){
            dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: 'data:image/jpg;base64,' + user.foto })
          }else{
            dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: avatar })
          }

/*           if (user.configuracaoEmpresa.logo != null){
            dispatch({ idStore, type: 'USUARIO', campo: 'logo', value: 'data:image/jpg;base64,' + user.logo })
          }else{
            dispatch({ idStore, type: 'USUARIO', campo: 'logo', value: avatar })
          }
 */
          /*---------------------------------------------------------------------------
          * Alteração de senha não carrega a tela principal (Dashboard)
          * Dias de licença igual a zero não carrega tela principal
          *---------------------------------------------------------------------------*/  
          if (user.alterarSenha !== 'S' && user.qtdDiasLicenca >= 0){
            dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: false });

            //Armazena TOKEN localmente
            dispatch({ idStore, type: 'USUARIO', campo: 'usuarioExiste', value: true })
            dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: true })
            dispatch({ idStore, type: 'SAVE' })
            window.localStorage.setItem("TOKEN_KEY", user.token)
  
            //Busca dados para o Dashboard
            controllerDashboard.carregarDadosDashboardPrincipal(dispatch, user.graficos, user.graficosProdutos, user.nomeEmpresa);
  
            alertar(notificacao, 'br', "Bem vindo!", 'success', 'icon-check-2', 2)
            setTimeout(() => { history.push('/Dashboard') }, 1000);  
          }

          thenFunction();

        }else{
          alertar(notificacao, 'br', mensagemRetorno , 'warning', 'icon-alert-circle-exc', 3);
          dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
          document.getElementById("senha").focus()
          setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 1000)  
        }


    }).catch((erro) => {
        if (erro.code === 'ERR_NETWORK'){
          alertar(notificacao, 'br', "Erro ao conectar no servidor.", 'warning', 'icon-alert-circle-exc', 2, erro)  
        }else{
          alertar(notificacao, 'br', "Erro : " + erro.message, 'warning', 'icon-alert-circle-exc', 2, erro)  
        }
        dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
        document.getElementById("senha").focus()
        setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

    });

} catch (e) {

    alertar(notificacao, 'br', "Falha no login", 'warning', 'icon-alert-circle-exc', 2)
    dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
    document.getElementById("senha").focus()
    setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

}  
}

/******************************************************************
 * Verifica Acesso do Usuário / Mostrar Menus e Dashboard
 ******************************************************************/
function VerificarAcessoMenu(perfilAcessos, dispatch){
  try {

    //Remove Todos os Acessos
    let idStore = 'ACESSO'
    dispatch({ idStore, type: 'ACESSO', campo: 'mostrarDashboard', value: 'N' })
    dispatch({ idStore, type: 'ACESSO', campo: 'cadastroProduto', value: 'N' })
    dispatch({ idStore, type: 'ACESSO', campo: 'cadastroGrupoTributo', value: 'N' })

    dispatch({ idStore, type: 'PERFIL_ACESSOS', value: perfilAcessos })
    

    var totalAcessos = perfilAcessos.length;
    for (var i = 0; i < totalAcessos; i++) {
      if (perfilAcessos[i].idActivity === 12) {
        dispatch({ idStore, type: 'ACESSO', campo: 'mostrarDashboard', value: 'S' })
      }
      
  }

    
  } catch (error) {
    //alertar(notificacao, 'br', "Falha na validação do acesso.", 'warning', 'icon-alert-circle-exc', 2)
  }
}

/******************************************************************
 * Valida Token 
 ******************************************************************/
export async function ValidarToken(token) {
  try {

     //var urlValidar = api.urlIntegradorApi + api.urlValidarToken + `${token}`;
     var urlValidar = api.urlBaseChefe + api.urlValidarToken + `${token}`;
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: urlValidar,
        headers: {}
      };
      
      await axios.request(config)
      .then((response) => {
        var token = response.data.data;
        if (token.Valido === "S"){
          return true;
        }
  
        return false;  
      })
  
  } catch (e) {
      return false;
      //alertar(notificacao, 'br', "Não foi possível validar o acesso", 'warning', 'icon-alert-circle-exc', 2)
  }
}

//Envia solicitação para receber um código de acesso
export function RecuperarSenha(notificacao, dispatch, email, thenFunction) {
  try {

      //Verifica email
      if (email === '' || email === undefined) {
        alertar(notificacao, 'br', "Informe um E-MAIL.", 'warning', 'icon-alert-circle-exc', 2);
        return true;
      }
  
      var ulrPesquisa = '';
      ulrPesquisa = api.urlBaseChefe + api.urlUsuarioLogin;

      var login = global.cfastUser;
      var senha = global.cfastToken;
    
      let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: {'Content-Type': 'application/json'},    
      data: {
        email: login,
        senha: senha,}
      };

      axios.request(config)
      .then((response) => {
          var tokenUsuario = response.data.data;
          if (tokenUsuario.token !== '') {
              EnviarSolicitacaoRecuperarSenha(notificacao, dispatch, email, tokenUsuario.token)      
          }    

          thenFunction();
      })
  
  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}
  
//Envia solicitação para receber um código de acesso
export function EnviarSolicitacaoRecuperarSenha(notificacao, dispatch, email, token) {
  try {
  
      var ulrPesquisa = '';
      ulrPesquisa = api.urlBaseChefe + api.urlUsuarioRecuperarSenha + `${email}`;
  
      const bearerAuth = 'Bearer ' + token;
  
      let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      axios.request(config)
      .then((response) => {
          if (response.data.data.status === 'OK') {        
              alertar(notificacao, 'br', "Um código foi enviado para seu e-mail " + email, 'info', 'icon-bulb-63', 5)      
          }    
      })
  
  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}

//Envia solicitação para receber um código de acesso
export function AlterarSenha(notificacao, dispatch, usuario, senha, confirmarSenha, thenFunction ) {
  try {
  
      //Verifica senha
      var invalido = false;
      if (senha === '') {
        alertar(notificacao, 'br', "Informe uma senha de 4 caracteres", 'warning', 'icon-alert-circle-exc', 2);
        return true;
      }
      if (confirmarSenha === '') {
        alertar(notificacao, 'br', "Informe a Confirmação da senha", 'warning', 'icon-alert-circle-exc', 2);
        return true;
      }

      if (senha !== confirmarSenha) {
        alertar(notificacao, 'br', "As Senhas devem ser iguais", 'warning', 'icon-alert-circle-exc', 2);
        return true;
      }

      var ulrPesquisa = '';
      ulrPesquisa = api.urlBaseChefe + api.urlUsuarioAlterarSenha;
  
      const bearerAuth = 'Bearer ' + usuario.usuario.token;

      const dadosSenha = {
        email: usuario.usuario.email,
        nomeUsuario: usuario.usuario.nome,
        idEmpresa: usuario.usuario.empresa,
        idUsuario: usuario.usuario.idUsuarioLogin,
        novaSenha: senha,
        confirmarSenha: confirmarSenha,
      }
  
      let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },
      data: {
        usuario: dadosSenha,}
      };
      
      axios.request(config)
      .then((response) => {
          if (response.data.data.status === 'OK') {   
              window.localStorage.removeItem("ALTERAR_SENHA");     
              alertar(notificacao, 'br', "Senha Alterada com SUCESSO.", 'info', 'icon-bulb-63', 5)      
          }    

          thenFunction();

      })
  
  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


//Liberar licença de usuario por um dia
export function LiberarLicençaUmDia(notificacao, dispatch, idEmpresa, token) {
  try {
  
      var ulrPesquisa = '';
      ulrPesquisa = api.urlBaseChefe + api.urlUsuarioRecuperarSenha + `${idEmpresa}`;
  
      const bearerAuth = 'Bearer ' + token;
  
      let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      axios.request(config)
      .then((response) => {
          if (response.data.data.status === 'OK') {        
              alertar(notificacao, 'br', "Acesso liberado por UM DIA.", 'info', 'icon-bulb-63', 5)      
          }    
      })
  
  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}




/* //Autentica o usuário
function autenticarAdmin(login, notificacao, dispatch) {
  try {
      dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 25 });
      var url = '';
      url = api.urlIntegradorApi + 'login/authenticate';

      axios({
          method: 'POST',
          maxBodyLength: Infinity,
          url: url,
          //headers: {"Content-Type": "application/json", httpAgent:agent, httpsAgent:agent, proxy: false },
          //headers: {"Content-Type": "application/json", httpsAgent: agent },
          //headers: {"Content-Type": "application/json", scheme: 'https' },
          headers: {"Content-Type": "application/json"},
          data: {
              email: login.usuario.login,
              senha: login.usuario.senha,
          }
      }).then(response => {
          dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 100 });
          var mensagemRetorno = response.data.message;

          autenticarUsuario(login, notificacao, dispatch);


          if (mensagemRetorno === "Sucesso"){
            var user = response.data.data;
            dispatch({ idStore, type: 'USUARIO', campo: 'idUsuarioLogin', value: user.idUsuarioLogin })
            dispatch({ idStore, type: 'USUARIO', campo: 'login', value: user.login })
            dispatch({ idStore, type: 'USUARIO', campo: 'email', value: user.email })
            dispatch({ idStore, type: 'USUARIO', campo: 'nome', value: user.nome })
            dispatch({ idStore, type: 'USUARIO', campo: 'empresa', value: user.empresa })
            dispatch({ idStore, type: 'USUARIO', campo: 'token', value: user.token })
            if (user.foto != null){
              dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: 'data:image/jpg;base64,' + user.foto })
            }else{
              dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: avatar })
            }
            
            dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: false });
  
            //Armazena TOKEN localmente
            dispatch({ idStore, type: 'USUARIO', campo: 'usuarioExiste', value: true })
            dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: true })
            dispatch({ idStore, type: 'SAVE' })
            window.localStorage.setItem("TOKEN_KEY", user.token)
  
            //Busca dados para o Dashboard
            controllerDashboard.Pesquisar(dispatch, user);
  
            alertar(notificacao, 'br', "Bem vindo!", 'success', 'icon-check-2', 2)
            setTimeout(() => { history.push('/Dashboard') }, 1000)

          }else{
            alertar(notificacao, 'br', mensagemRetorno , 'warning', 'icon-alert-circle-exc', 2);
            dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
            document.getElementById("senha").focus()
            setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 1000)  
          }


      }).catch((erro) => {
          if (erro.code === 'ERR_NETWORK'){
            alertar(notificacao, 'br', "Erro ao conectar no servidor.", 'warning', 'icon-alert-circle-exc', 2, erro)  
          }else{
            alertar(notificacao, 'br', "Erro : " + erro.message, 'warning', 'icon-alert-circle-exc', 2, erro)  
          }
          dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
          document.getElementById("senha").focus()
          setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

      });

  } catch (e) {

      alertar(notificacao, 'br', "Falha no login", 'warning', 'icon-alert-circle-exc', 2)
      dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
      document.getElementById("senha").focus()
      setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

  }
}
 */

export function salvarSenha(login, notificacao, dispatch) {
  function camposInvalidos() {
      dispatch({ idStore, type: 'OBJETOS', campo: 'novaSenhaInvalida', value: true });
      dispatch({ idStore, type: 'OBJETOS', campo: 'confirmarSenhaInvalida', value: true });
  }
  try {
      if (login.objetos.novaSenha === "" && login.objetos.confirmarSenha === "") {
          camposInvalidos();
          return alertar(notificacao, 'br', "Preencha os dois campos", 'info', 'icon-bulb-63', 2);
      }
      if (login.objetos.novaSenha !== login.objetos.confirmarSenha) {
          camposInvalidos();
          return alertar(notificacao, 'br', "As senhas digitadas devem ser iguais", 'warning', 'icon-alert-circle-exc', 2);
      }
      axios({
          method: 'put',
          url: api.urlCadastro + `api/v1/usuario/${login.usuario.primeiroAcesso ? 'primeiroAcesso' : 'alterarSenha'}`,
          //headers: { Authorization: basicAuth },
          data: {
              CodUser: login.usuario.codUser,
              Senha: login.objetos.novaSenha
          }
      }).then(function (response) {

          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);

          //Verifica a quantidade de empresas na qual o usuário está viculado
          //selectEmpresa(login.usuario.empresas, notificacao, dispatch, login.usuario.token)

      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
  }
}

export function NovaSenha(notificacao, dispatch, novaSenha) {
  try {
      if (novaSenha.login.trim(" ") === "") {
          dispatch({ idStore, type: 'NOVA_SENHA', campo: 'login', value: "" });
          document.getElementById('loginEsqueciSenha').focus();
          return alertar(notificacao, 'br', "Preencha o campo", 'info', 'icon-bulb-63', 2);
      }
      axios({
          method: 'put',
          url: api.urlCadastro + `api/v1/usuario/novaSenha?login=${novaSenha.login}`,
          //headers: { Authorization: basicAuth },
      }).then(function (response) {

          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 5);
          setTimeout(() => {
              history.push('/auth/login');
          }, 1000);

      }).catch(e => {
          if (e.response.data.message.match(/inválido/)) {
              dispatch({ idStore, type: 'NOVA_SENHA', campo: 'loginInvalido', value: true });
          }
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e);
  }
}
