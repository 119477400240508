import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerCliente from "~/controller/Cadastros/controllerCliente";
import * as controllerEntrada from "~/controller/Movimentacao/controllerEntrada"
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {formatarData, maskMoeda} from "~/Util/mascaras";
import useModal from '~/Util/Hooks/useModal.ts';

import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Movimentacao/Entradas/Cadastro/EntradaCadastro.jsx";
import ModalReabrirExcluir from "../Entradas/Modals/modalReabrirExcluir.jsx";

var steps = [
  {
    stepName: "", //Nome do Step
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const idStore = "ENTRADA"

const EntradaPesquisa = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);

  //const { CLIENTEs, cadastroCliente, pesquisaCliente, inicioCadastro, ClienteCopia } = useSelector((state) => state.Cliente);
  const { ENTRADAs, cadastroEntrada, pesquisaEntrada, inicioCadastro } = useSelector((state) => state.Entrada);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);

  const [pesquisa, setpesquisa] = useState({ dataInicio: '', dataFinal: '', numeroDoc: '', idProduto: '', descricao: '' })

  const [shouldClean, setshouldClean] = useState(false)
  const [paramTelaModal, setparamTelaModal] = useState({idMovimento: '', acaoTela: '', titulo: '', mensagem: '' })
  const [modalReabrirExcluir, toggleModalReabrirExcluir] = useModal(false)
  
  useEffect(() => {
    IniciarTela();
  }, [])


  function IniciarTela(){
    dispatch({ idStore, type: "CLEAN_SEARCH" });
    dispatch({ idStore, type: "CLEAN_ENTRADA" });
    dispatch({ idStore, type: "CLEAN_ENTRADA_ITEM" });
    dispatch({ idStore, type: "CLEAN_SEARCH_PESQUISA_FORNECEDOR" });
    dispatch({ idStore, type: 'ENTRADAs', value: [] });
    dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
    dispatch({ idStore, type: "CLEAN_INVALIDO_ITEM" });

    let dataPesq = '';
    let dataAtual = new Date();
    dataPesq = formatarData('EN', dataAtual.toLocaleDateString());

    //setpesquisa({ ...pesquisa, dataInicio: dataPesq, dataFinal: dataPesq, numeroDoc: '', idProduto: '', descricao: '' })
    let type = 'PESQUISA';
    dispatch({ idStore, type, campo: 'dataInicio', value: dataPesq });
    dispatch({ idStore, type, campo: 'dataFinal', value: dataPesq });
    dispatch({ idStore, type, campo: "numeroDoc", value: '' }); 
    dispatch({ idStore, type, campo: "idProduto", value: '' }); 
    dispatch({ idStore, type, campo: "descricao", value: '' }); 
  }

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            {obj.statusProcesso === 'Em Digitação' ?
              <Button
                className="btn-link btn-icon"
                color="info"
                id="tooltipEditar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipEditar"
                  >
                  Editar
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-pencil"/>
              </Button>
            :
              <Button
                className="btn-link btn-icon"
                color="success"
                id="tooltiVisualizar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
              >
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltiVisualizar"
                    >
                    Visualizar
                  </UncontrolledTooltip>                              
                <i className="tim-icons icon-single-copy-04"/>
              </Button>
            }       

            {obj.statusProcesso === 'Em Digitação' ?
              <Button
                className="btn-link btn-icon"
                color="danger"
                id="tooltipExcluir"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleExcluirMovimento(obj);
                }}
                >
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipExcluir"
                >
                  Excluir
                </UncontrolledTooltip>                              
                <i className="tim-icons icon-simple-remove" />
              </Button>                       

              :
              <Button
                className="btn-link btn-icon"
                color="primary"
                id="tooltipReabrir"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleReabrir(obj);
                }}
                >
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipReabrir"
                >
                  Reabrir
                </UncontrolledTooltip>                              
                <i className="tim-icons icon-refresh-01" />
              </Button>

            }
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idMovimento: prop["idMovimento"],
        idEmpresa: prop["idEmpresa"],
        numeroDocumento: prop["numeroDocumento"],
        nome: prop["nome"],
        valorTotal: maskMoeda(prop["valorTotal"].toFixed(2)),
        dataInclusao: prop["dataInclusao"],
        dataMovimento: prop["dataMovimento"],
        status: prop["status"],
        statusProcesso: prop["statusProcesso"],
      };
    });
  }

  //ALTERAR MOVIMENTO
  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
        await controllerEntrada.GetEntrada(notificacao, dispatch, login.usuario.empresa, obj.idMovimento, 'E', () => {
            setHorizontalTabs("cadastrar")
            setInicioCadastro(true);
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO_ITENS' });
            document.getElementById("numeroDocumento").focus();
        });
        setLoading(false);
    }
  }

  //REABRIR MOVIMENTO
  async function handleReabrir(obj) {
    //Abrir poupUp
    await controllerEntrada.GetEntrada(notificacao, dispatch, login.usuario.empresa, obj.idMovimento, 'E', () => {
      setHorizontalTabs("cadastrar")
      setInicioCadastro(true);
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO_ITENS' });
      document.getElementById("numeroDocumento").focus();
  
      setparamTelaModal({ ...paramTelaModal, idMovimento: obj.idMovimento, acaoTela: 'R', titulo: 'Reabrir Movimentação', mensagem: 'Deseja REABRIR essa movimentação ?' })
      toggleModalReabrirExcluir();   
    });

    // setLoading(true);
    // if (!loading) {
    //     await controllerEntrada.ReabrirMovimento(notificacao, dispatch, obj.idMovimento, () => {
    //         setHorizontalTabs("cadastrar")
    //         setInicioCadastro(true);
    //         dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    //         dispatch({ idStore, type: 'CLEAN_INVALIDO_ITENS' });
    //         document.getElementById("numeroDocumento").focus();
    //     });
    //     setLoading(false);
    // }
  }
  

  //EXCLUIR MOVIMENTO
  async function handleExcluirMovimento(obj) {
    //Abrir poupUp
    setparamTelaModal({ ...paramTelaModal, idMovimento: obj.idMovimento, acaoTela: 'E', titulo: 'Exclusão de Movimentação', mensagem: 'Deseja EXCLUIR essa movimentação?' })
    toggleModalReabrirExcluir(); 


    // setLoading(true);
    // if (!loading) {
    //     await controllerEntrada.ExcluirMovimento(notificacao, dispatch, obj.idMovimento, pesquisaEntrada, login.usuario.empresa, () => {

    //     });
    //     setLoading(false);
    // }
  }



  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(ENTRADAs));
    if (ENTRADAs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [ENTRADAs]);


  async function handlePesquisar() {
    setLoading(true);
    if (!loading) {
      await controllerEntrada.Pesquisar(notificacao, dispatch, pesquisaEntrada, login.usuario.empresa, () => {

      });
    }
    setLoading(false);
  }

  function handleLimpar() {
    IniciarTela();
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("numeroDoc").focus();
      }, 100);
    }else if (tabName === "cadastrar") {
      setTimeout(() => {
        document.getElementById("numeroDocumento").focus();
      }, 100);
    }
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: "CLEAN_ENTRADA" });
    dispatch({ idStore, type: "CLEAN_ENTRADA_ITEM" });
    dispatch({ idStore, type: "CLEAN_SEARCH_PESQUISA_FORNECEDOR" });
    dispatch({ idStore, type: 'ENTRADAITENs', value: [] });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
    dispatch({ idStore, type: "CLEAN_INVALIDO_ITEM" });

    let dataPesq = '';
    let dataAtual = new Date();
    dataPesq = formatarData('EN', dataAtual.toLocaleDateString());
    let type = 'ENTRADA';
    dispatch({ idStore, type, campo: 'dataInclusao', value: dataPesq });
    dispatch({ idStore, type, campo: 'dataMovimento', value: dataPesq });
    dispatch({ idStore, type, campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type, campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 

    document.getElementById("numeroDocumento").focus();

  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleNovoCadastro(){
      changeActiveTab("cadastrar");
      novoCadatro();
      //dispatch({ idStore, type: 'CLIENTE', campo: 'statusCliente', value: '1' })
  }


  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, cadastroEntrada: cadastroEntrada };
    });
    //setInicioCadastro(false);
  }, [cadastroEntrada]);

/*   useEffect(() => {
    if (inicioCadastro) setInicioCadastro(false);
  }, [inicioCadastro]);
 */

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PESQUISA", campo, value });
  }

   function handleKeyPress(event) {
     if (event.key === "Enter") {
       //controllerCliente.Pesquisar(notificacao, dispatch, pesquisaCliente);
     }
   }

  function updateWidth() {
    setWidht(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    //Preenche combo de empresas
    //controllerEmpresas.GetEmpresas(notificacao, dispatch, login.usuario.empresa);
    //dispatch({ idStore, type: "EMPRESA_PESQ", value: [] });
    dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: login.usuario.empresa });
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
            <CardHeader className="text-center">
              <CardTitle tag="h4">
                Entrada de Mercadorias
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  {/* <h5>Pesquisa</h5> */}
                </TabPane> 

                 <TabPane tabId="cadastrar">
                  {/* <h5>Cadastro</h5> */}
                </TabPane> 
              </TabContent> 
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }} 
                    style={{ width: "50%" }}
                  >
                    Pesquisar
                  </Button>
                </ArrowTooltip>
                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    Entrada de Mercadoria
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa */}
                <Row className="padding-padrao-row">
                  <Col md="2" className="padding-padrao-col">
                        <label>Data Início</label>
                        <Input
                            style={{textTransform:"uppercase" , fontSize: '14px' }}
                            id='dataInicio'
                            type='date'
                            autoComplete='off'
                            value={pesquisaEntrada.dataInicio}
                            onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                            onFocus={e => e.target.select()}
                            onKeyPress={(e) => {
                              if (e.target.value !== ''){
                                handleKeyPress(e, e.target.value);
                                if (e.key === 'Enter') e.target.select()  
                              }
                            }}
                        />
                    </Col>
                    <Col md="2" className="padding-padrao-col">
                      <label>Data Final</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '14px' }}
                          id='dataFinal'
                          type='date'
                          autoComplete='off'
                          value={pesquisaEntrada.dataFinal}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                      />
                  </Col>
                  <Col className="padding-padrao-col" md="2">
                    <label>Num. Documento</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '16px' }}
                        id='numeroDoc'
                        type='text'
                        autoComplete='off'
                        value={pesquisaEntrada.numeroDoc}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        onFocus={e => e.target.select()}

                        maxLength={'10'}
                    />
                  </Col>
                  <Col className="padding-padrao-col" md="1">
                    <label>Código</label>
                    <Input
                        disabled
                        style={{textTransform:"uppercase" , fontSize: '16px' }}
                        id='idProduto'
                        type='text'
                        autoComplete='off'
                        value={pesquisaEntrada.idProduto}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        onFocus={e => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.target.value !== ''){
                            handleKeyPress(e, e.target.value);
                            if (e.key === 'Enter') e.target.select()  
                          }
                        }}

                        maxLength={'15'}
                    />
                  </Col>      
                  <Col className="padding-padrao-col" md="3">
                    <label>Produto</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '16px' }}
                        id='descricao'
                        type='text'
                        autoComplete='off'
                        value={pesquisaEntrada.descricao}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        onFocus={e => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.target.value !== ''){
                            handleKeyPress(e, e.target.value);
                            if (e.key === 'Enter') e.target.select()  
                          }
                        }}

                        maxLength={'40'}
                    />
                  </Col>      

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '24px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          //onClick={() => handlePesquisar(pesquisa.celular)}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>


                </Row>


                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="Limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Novo Cadastro">
                        <Button
                          color="warning"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleNovoCadastro()}
                        >
                          Novo
                        </Button>
                      </ArrowTooltip>                    
                    </Col>
                  </CardBody>
                </Row>
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  {/* INCIO TabPesquisa-GRADE */}
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "id",
                        accessor: "idMovimento",
                      },
                      {
                        Header: "N. Doc.",
                        accessor: "numeroDocumento",
                      },
                      {
                        Header: "Nome",
                        accessor: "nome",
                      },
                      {
                        Header: "Valor",
                        accessor: "valorTotal",
                      },
                      {
                        Header: "Data Inclusão",
                        accessor: "dataInclusao",
                      },
                      {
                        Header: "Data Movimento",
                        accessor: "dataMovimento",
                      },
                      {
                        Header: "Status",
                        accessor: "statusProcesso",
                      },
                     {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-70px" }}>
                   <ReactWizard
                    steps={steps}
                    //navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    //goToFirstStep={inicioCadastro}
                    //finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                      "btn-wd btn-info invisible",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )} 
                     nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                    wizardNavigationVisivel={false}
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {modalReabrirExcluir ?
          <ModalReabrirExcluir
            isOpen={true}
            toggleModalReabrirExcluir={toggleModalReabrirExcluir}
            paramPesquisa={pesquisaEntrada}
            paramTela={paramTelaModal}
            shouldClean={shouldClean}
        />
        : null}

    </>
  );
};

export default EntradaPesquisa;
