import React, { useState } from 'react'
import { useSelector } from "react-redux";
import Loading from 'react-loading-bar'
import axios from 'axios';
import 'react-loading-bar/dist/index.css'
import "../../spinner.css"

const LoadingBar = () => {
    const [show, setShow] = useState(false);
    const {mensagemLoadBar} = useSelector((state) => state.MensagemLoadBar);

    axios.interceptors.request.use((config) => {
        setShow(true);
        return config
    });
    
    axios.interceptors.response.use((response) => {
        setTimeout(() => {
            setShow(false);
        }, 500);
        return response
    }, function (error) {
        setTimeout(() => {
            setShow(false);
        }, 500);
        return Promise.reject(error);
    });

    return (
        <>
             {show &&
                 <div style={{ height: '100vh', position: 'fixed', zIndex: 1200, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', opacity: 20, backgroundColor: 'rgba(255, 255, 255, .6)' }}>
                     <div className="spinner"></div>
                     <div style={{marginTop: '90px', position: 'fixed', zIndex: 1200, width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        <label>{mensagemLoadBar.mensagem !== '' ? mensagemLoadBar.mensagem : 'Carregando...' }</label>
                     </div>
                     
                 </div>
                 
             }
        </>
     )
 

/*     return (
        <Loading
            show={show}
            color="gold"
            showSpinner={true}
        />
    ) */
}

export default LoadingBar;