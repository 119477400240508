import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import { Select } from "~/components/ErnComponets/ErnComponets";
import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const situacaoPedido = [
  { value: "T", label: "Aberto/Fechado" },
  { value: "S", label: "Aberto" },
  { value: "N", label: "Fechado" },
];

const ModalPedidos = ({isOpen, toggleModalPedidos, paramPedido, shouldClean}) => {
    const { PEDIDOs } = useSelector((state) => state.PedidoItem, shallowEqual);
    const [pesquisaPedido, setpesquisaPedido] = useState({linhaSelecionada: '', idPedido: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ idPedido: '', nomeCliente: '', dataInicio: '', dataFinal: '', pedidoAberto: '' })
    const [pedidosSelected, setpedidosSelected] = useState({ idPedido: 0 })
    const [pedidos, setPedidos] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
      
      //document.getElementById("idPedido").focus();
      let dataPesqInicio = '';
      let dataPesqFinal = '';
      let dataAtual = new Date();
      let hora = new Date().getHours();
      if (hora >= 0 && hora <= 6){
        dataAtual.setDate(dataAtual.getDate() - 1);
        dataPesqInicio = dataAtual.toISOString().substr(0, 10);    
      }else{
        dataPesqInicio = new Date().toISOString().substr(0, 10);
      }
      dataPesqFinal = new Date().toISOString().substr(0, 10);

      setpesquisa({ ...pesquisa, dataInicio: dataPesqInicio, dataFinal: dataPesqFinal, pedidoAberto: 'S' })

      handlePesquisar();

    }, [])
    
    async function handlePesquisar(){
      setLoading(true);
      if (!loading) {
          await controllerPedidoVendas.GetMesasComandasDelivery(notificacao, dispatch, login.usuario, pesquisa, () => {
              document.getElementById("nomeCliente").focus();  
          });
          setLoading(false);
      }
    }

    useEffect(() => {
      if (paramPedido !== ''){
        setpesquisa({ ...pesquisa, nomeCliente: paramPedido })
        handlePesquisar();
      }
    }, [paramPedido])

    useEffect(() => {
        setPedidos(validaList(PEDIDOs))
    }, [PEDIDOs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'PEDIDOs', value: [] })  
          setpesquisa({ ...pesquisa, nomeCliente: '' })
        }
    }, [shouldClean])

    function handleOnChange(value, campo) {
      switch (campo) {
        case "dataInicio":
          setpesquisa({ ...pesquisa, dataInicio: value })
          break;
        case "dataFinal":
          setpesquisa({ ...pesquisa, dataFinal: value })                    
          break;
        case "idPedido":
          setpesquisa({ ...pesquisa, idPedido: value })                    
          break;
        case "nomeCliente":
          setpesquisa({ ...pesquisa, nomeCliente: value })                    
          break;
        default:
          break;
      }  
    }

    function onChangeCombo(value) {
      setpesquisa({ ...pesquisa, pedidoAberto: value })                    
    }  

    function handleVoltar(){
      toggleModalPedidos();
    }

    function handleOk(){
      let pedidoSelected = PEDIDOs[pesquisaPedido.linhaSelecionada]

      controllerPedidoVendas.GetMesaComanda(notificacao, dispatch, login.usuario.empresa, '', pedidoSelected.idPedido, () => {

      });

      toggleModalPedidos();
    }

  function onClick(rowIndex) {
    let pedidoSelected = PEDIDOs[rowIndex]

    setpesquisaPedido({linhaSelecionada: rowIndex, idPedido: pedidoSelected.idPedido })
    setpesquisa({ ...pesquisa, idPedido: pedidoSelected.idPedido })
  }

  function onDoubleClick(rowIndex) {
    let pedidoSelected = PEDIDOs[rowIndex]
    setpesquisaPedido({linhaSelecionada: rowIndex, idPedido: pedidoSelected.idPedido })

    handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: PEDIDOs[row.index].idPedido === pesquisaPedido.idPedido ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar();
        }
    }

    function handleLimpar() {
        let idStore = 'PEDIDO';     
        dispatch({ idStore, type: 'PEDIDOs', value: [] })         

        setpesquisa({ ...pesquisa, idPedido: '', nomeCliente: '' })
        setpedidosSelected({ ...pedidosSelected, idPedido: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idPedido === item.idPedido)) {
                arr.push(value)                
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalPedidos"
            isOpen={isOpen}
            toggle={() => toggleModalPedidos()}
            title={'Pesquisa de Pedidos'}
            size="xl" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col md="2" className="padding-padrao-col">
                      <label>Data Início</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='dataInicio'
                          type='date'
                          autoComplete='off'
                          value={pesquisa.dataInicio}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>
                  <Col md="2" className="padding-padrao-col">
                      <label>Data Final</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='dataFinal'
                          type='date'
                          autoComplete='off'
                          value={pesquisa.dataFinal}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                      <label>Número Pedido</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='idPedido'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.idPedido}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Cliente</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='nomeCliente'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.nomeCliente}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                      <label>Situação</label>
                      <Select
                        id="situacaoPedido"
                        //select={{ value: "idGrupo", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: pesquisa.pedidoAberto }}
                        options={situacaoPedido}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo(value.value)
                        }  
                        autoComplete="off"
                      />
                  </Col>                  

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Resultados</legend>
                       <div
                        className='scrollBar'
                        //ref={tabelaPedidos}
                        //ref={tableRef}
                        style={PEDIDOs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={PEDIDOs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'id',
                                    accessor: 'idPedido',
                                    minWidth: 20,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'Mesa/Comanda/Celular',
                                    accessor: 'mesaComanda',
                                    minWidth: 40,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                  Header: 'Data Pedido',
                                  accessor: 'dataPedido',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Tipo Venda',
                                  accessor: 'tipoVenda',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Tipo Pedido',
                                  accessor: 'tipoPedido',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Situação',
                                  accessor: 'pedidoAberto',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false,
                                },
                                {
                                  Header: 'Cliente',
                                  accessor: 'nomeCliente',
                                  minWidth: 70,
                                  resizable: false,
                                  sortable: false
                                },

                              ]}
                                
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={PEDIDOs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${PEDIDOs.length} Pedidos`}
                  </div>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalPedidos