import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"

import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const ModalClientes = ({isOpen, toggleModalClientes, paramCliente, shouldClean}) => {
    const { CLIENTEs } = useSelector((state) => state.Cliente, shallowEqual);
    const [pesquisaCliente, setpesquisaCliente] = useState({linhaSelecionada: '', idCliente: '', celularCliente: '', nomeCliente: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ nomeCliente: '', celularCliente: '' })
    const [clientesSelected, setclientesSelected] = useState({ idCliente: 0 })
    const [clientes, setClientes] = useState([])
    const dispatch = useDispatch();

    // useEffect(() => {
    //   document.getElementById("celularCliente").focus();
    // }, [])
    
    async function handlePesquisar(){
      setLoading(true);
      if (!loading) {
          await controllerCliente.Pesquisar(notificacao, dispatch, pesquisa, login.usuario.empresa, () => {
              document.getElementById("nomeCliente").focus();  
          });
          setLoading(false);
      }
    }

    useEffect(() => {
      if (paramCliente !== ''){
        setpesquisa({ ...pesquisa, nomeCliente: paramCliente })
        handlePesquisar();
      }
    }, [paramCliente])

    useEffect(() => {
        setClientes(validaList(CLIENTEs))
    }, [CLIENTEs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'CLIENTE'
          dispatch({ idStore, type: 'CLIENTEs', value: [] })  
          setpesquisa({ ...pesquisa, nomeCliente: '' })
        }
    }, [shouldClean])

    function handleOnChangeCliente(value, campo) {
      switch (campo) {
        case "nomeCliente":
          setpesquisa({ ...pesquisa, nomeCliente: value })
          break;
        case "celularCliente":
          setpesquisa({ ...pesquisa, celularCliente: value })                    
          break;
        default:
          break;
      }  
    }

    function handleVoltar(){
      toggleModalClientes();
    }


    function handleOk(){
      let idStore = 'PEDIDO_PESQ_CLIENTE'
      let type = 'CLIENTE';
      let clienteSelected = CLIENTEs[pesquisaCliente.linhaSelecionada]
      
      dispatch({ idStore, type, campo: 'idCliente', value: clienteSelected.idCliente })
      dispatch({ idStore, type, campo: 'idEmpresa', value: clienteSelected.idEmpresa })
      dispatch({ idStore, type, campo: 'nomeCliente', value: clienteSelected.nome })
      dispatch({ idStore, type, campo: 'celularCliente', value: clienteSelected.celular })
      dispatch({ idStore, type, campo: 'cpfCliente', value: clienteSelected.cpf })
      dispatch({ idStore, type, campo: 'statusCliente', value: clienteSelected.status })
      //dispatch({ idStore, type, campo: 'fotoCliente', value: clienteSelected.fotoCliente })

      toggleModalClientes();
    }

    function onClick(rowIndex) {
      let clienteSelected = CLIENTEs[rowIndex]

      let idStore = 'PEDIDO_PESQ_CLIENTE'
      let type = 'PESQUISA';
      dispatch({ idStore, type, campo: 'idCliente', value: clienteSelected.idCliente })
      dispatch({ idStore, type, campo: 'idEmpresa', value: clienteSelected.idEmpresa })
      dispatch({ idStore, type, campo: 'nomeCliente', value: clienteSelected.nomeCliente })
      dispatch({ idStore, type, campo: 'celularCliente', value: clienteSelected.celularCliente })
      dispatch({ idStore, type, campo: 'statusCliente', value: clienteSelected.statusCliente })
  
      setpesquisaCliente({linhaSelecionada: rowIndex, idCliente: clienteSelected.idCliente, celularCliente: clienteSelected.celular, nomeCliente: clienteSelected.nome })
      setpesquisa({ ...pesquisa, nomeCliente: clienteSelected.nomeCliente })
  }

  function onDoubleClick(rowIndex) {
    let clienteSelected = CLIENTEs[rowIndex]
    setpesquisaCliente({linhaSelecionada: rowIndex, idCliente: clienteSelected.idCliente, celularCliente: clienteSelected.celular, nomeCliente: clienteSelected.nome })

      handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: CLIENTEs[row.index].idCliente === pesquisaCliente.idCliente ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar();
        }
    }

    function handleLimpar() {
        let idStore = 'PEDIDO_PESQ_CLIENTE';     
        dispatch({ idStore, type: 'CLIENTEs', value: [] }) 
        dispatch({ idStore, type: 'CLEAN_CLIENTE' });        

        setpesquisa({ ...pesquisa, nomeCliente: '' })
        setclientesSelected({ ...clientesSelected, idCliente: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idCliente === item.idCliente)) {
                arr.push(value)
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalClientes"
            isOpen={isOpen}
            toggle={() => toggleModalClientes()}
            title={'Pesquisa de Clientes'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col md="3" className="padding-padrao-col">
                      <label>Celular</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='celularCliente'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.celularCliente}
                          onChange={(e) => handleOnChangeCliente(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Nome</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='nomeCliente'
                          type='text'
                          autoComplete='off'
                          value={pesquisa.nomeCliente}
                          onChange={(e) => handleOnChangeCliente(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                  </Col>

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Resultados</legend>
                       <div
                        className='scrollBar'
                        //ref={tabelaClientes}
                        //ref={tableRef}
                        style={CLIENTEs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={CLIENTEs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'Celular',
                                    accessor: 'celular',
                                    minWidth: 40,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'Nome',
                                    accessor: 'nome',
                                    resizable: false,
                                    sortable: false
                                }]}
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={CLIENTEs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${CLIENTEs.length} pessoas encontradas`}
                  </div>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>

                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalClientes