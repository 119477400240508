import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {removeMaskMoney} from "~/Util/mascaras";


const idStore = 'MEUSDADOS';

/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateMeusDados(notificacao, dispatch, cadastroMeusDados, operacao, cadastroMeusDadosCopia) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  
      ulr = api.urlBaseChefe + api.urlCadastroMeusDadosAlterar;
  
      if (JSON.stringify(cadastroMeusDados) === JSON.stringify(cadastroMeusDadosCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        
  
      if (operacao === 'I'){
        cadastroMeusDados.idEmpresa = "0";
      }
  
      var invalido = false;
      if (cadastroMeusDados.cnpjCpf === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'cpfCnpj', value: true })
          invalido = true
      }
      if (cadastroMeusDados.nomeEmpresa === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'nomeEmpresa', value: true })
        invalido = true
      }
      if (cadastroMeusDados.cep === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'cep', value: true })
        invalido = true
      }
      if (cadastroMeusDados.endereco === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'endereco', value: true })
        invalido = true
      }
      if (cadastroMeusDados.bairro === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'bairro', value: true })
        invalido = true
      }
      if (cadastroMeusDados.cidade === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'cidade', value: true })
        invalido = true
      }
      if (cadastroMeusDados.uf === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true })
        invalido = true
      }
      if (cadastroMeusDados.numero === '') {
        cadastroMeusDados.numero = '0';
      }

  
      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdEmpresa: cadastroMeusDados.idEmpresa,
            NumeroContrato: cadastroMeusDados.numeroContrato,
            CnpjCpf: cadastroMeusDados.cnpjCpf,
            NomeEmpresa: cadastroMeusDados.nomeEmpresa,
            Celular: cadastroMeusDados.celular,
            Email: cadastroMeusDados.email,
            Cep: cadastroMeusDados.cep,
            Endereco: cadastroMeusDados.endereco,
            Bairro: cadastroMeusDados.bairro,        
            Cidade: cadastroMeusDados.cidade,        
            Uf: cadastroMeusDados.uf,   
            Numero: cadastroMeusDados.numero,   
            Complemento: cadastroMeusDados.complemento,   
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }
  
          //dispatch({ idStore, type: 'PRODUTO', campo: 'idProduto', value: user.idUsuarioLogin })
          //dispatch({ idStore, type: 'PRODUTO_COPIA' })
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }

  
  export async function GetMeusDados(notificacao, dispatch, idEmpresa, thenFunction){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlCadastroMeusDadosGetMeusDados + `${idEmpresa}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.data.message === 'Sucesso') {
              let type = 'MEUSDADOS';
              dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
              dispatch({ idStore, type, campo: 'numeroContrato', value: response.data.data.numeroContrato })
              dispatch({ idStore, type, campo: 'cnpjCpf', value: response.data.data.cnpjCpf })
              dispatch({ idStore, type, campo: 'nomeEmpresa', value: response.data.data.nomeEmpresa })
              dispatch({ idStore, type, campo: 'celular', value: response.data.data.celular })
              dispatch({ idStore, type, campo: 'email', value: response.data.data.email })
              dispatch({ idStore, type, campo: 'cep', value: response.data.data.cep })
              dispatch({ idStore, type, campo: 'endereco', value: response.data.data.endereco })
              dispatch({ idStore, type, campo: 'bairro', value: response.data.data.bairro })
              dispatch({ idStore, type, campo: 'cidade', value: response.data.data.cidade })
              dispatch({ idStore, type, campo: 'uf', value: response.data.data.uf })
              dispatch({ idStore, type, campo: 'numero', value: response.data.data.numero })
              dispatch({ idStore, type, campo: 'complemento', value: response.data.data.complemento })
      
              dispatch({ idStore, type: 'MEUSDADOS_COPIA' })
              thenFunction()
      
                
            }else{
              alertar(notificacao, 'br', "Dados não encontrado", 'info', 'icon-bulb-63', 2)
            }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  
  }

  export async function GetCep(notificacao, dispatch, cepInformado){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlPesquisarCEP + `${cepInformado}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          axios.request(config)
          .then((response) => {
          if (response.data.message === 'Sucesso') {
            /*-----------------------------------------------------------------------------
            * Carrega dados de CEP
            *-----------------------------------------------------------------------------*/  
            let type = 'MEUSDADOS';
            var cep = response.data.data;  
            dispatch({ idStore, type, campo: 'cep', value: cepInformado })
            dispatch({ idStore, type, campo: 'endereco', value: cep.endereco })
            dispatch({ idStore, type, campo: 'bairro', value: cep.bairro })
            dispatch({ idStore, type, campo: 'cidade', value: cep.cidade })
            dispatch({ idStore, type, campo: 'uf', value: cep.estado })
            dispatch({ idStore, type, campo: 'numero', value: '' })
            //dispatch({ idStore, type, campo: 'complemento', value: '' })
            //thenFunction()  
                
          }else{
            alertar(notificacao, 'br', "CEP Não encontrado.", 'info', 'icon-bulb-63', 2)
          }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  }
  
  