import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {maskMoeda, removeMaskMoney} from "~/Util/mascaras";
//import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";
//import { campoValido } from "~/Util/campoValido";
//import { func } from "prop-types";

const idStore = 'PRODUTO';


export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

    try {

      /*---------------------------------------------------------------------------
       * Valida Campos
      ---------------------------------------------------------------------------*/
      if (pesquisa.codigoProduto === '' && pesquisa.descricaoProduto === ''){
        if (pesquisa.idGrupo === '0'){
          alertar(notificacao, 'br', "Informe um GRUPO.", 'info', 'icon-bulb-63', 2);
          return;
        }  
      }  


      var ulrPesquisa = '';
      var codigo = '-'
      var descricao = '-'
      var pesquisaGrupo = false;

      if (pesquisa.codigoProduto !== "" || pesquisa.descricaoProduto !== "") {
        if (pesquisa.codigoProduto !== ""){
          codigo = pesquisa.codigoProduto;
        }
        if (pesquisa.descricaoProduto !== ""){
          descricao = pesquisa.descricaoProduto;      
        }
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetProdutosPesquisa + `${idEmpresa}` + `/${codigo}` + `/${descricao}`;
      }else {
        pesquisaGrupo = true;
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetGrupoProdutos + `${idEmpresa}` + `/${pesquisa.idGrupo}`;
      }
      
      //ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetGrupoProdutos + `${idEmpresa}` + `/${pesquisa.idGrupo}`;

      dispatch({ idStore, type: 'PRODUTOs', value: [] })  

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      axios.request(config)
      .then((response) => {
        var produtos = '';
      if (pesquisaGrupo){
        produtos = response.data.data[0].produtos;  
      }else{
        produtos = response.data.data;  
      }
      
      if (produtos.length === 0) {
          alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)
      }

      dispatch({ idStore, type: 'PRODUTOs', value: produtos })
      })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateProduto(notificacao, dispatch, cadastroProduto, operacao, cadastroProdutoCopia) {
  try {

      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      ulr = api.urlBaseChefe + api.urlCadastroProdutoIncluirAlterar;


      if (JSON.stringify(cadastroProduto) === JSON.stringify(cadastroProdutoCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        


      if (operacao === 'I'){
        cadastroProduto.idProduto = "0";
      }

      var invalido = false;
      if (cadastroProduto.idGrupo === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'grupoTodosSelect', value: true })
          invalido = true
      }
      if (cadastroProduto.descricaoProduto === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'descricaoProduto', value: true })
          invalido = true
      }
      if (cadastroProduto.valorProduto === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'valorProduto', value: true })
          invalido = true
      }
      if (cadastroProduto.tipoProduto === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'tipoProdutoSelect', value: true })
        invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdProduto: cadastroProduto.idProduto,
            IdEmpresa: cadastroProduto.idEmpresa,
            IdGrupo: cadastroProduto.idGrupo,
            Descricao: cadastroProduto.descricaoProduto,
            descricaoDetalhada: cadastroProduto.descricaoDetalhada,
            codigoProduto: cadastroProduto.codigoProduto,
            ValorProduto: removeMaskMoney(cadastroProduto.valorProduto),
            ImagemProduto: cadastroProduto.imagemProduto,
            ImagemProdutoHd: '',
            tempoPreparo: cadastroProduto.tempoPreparo,
            tipoProduto: cadastroProduto.tipoProduto,
            Ncm: cadastroProduto.ncmProduto,
            Cest: '',
            idTributacaoGrupo: cadastroProduto.idTributacaoGrupo,
            Status: cadastroProduto.statusProduto,
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }

          //dispatch({ idStore, type: 'PRODUTO', campo: 'idProduto', value: user.idUsuarioLogin })
          //dispatch({ idStore, type: 'PRODUTO_COPIA' })

          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*------------------------------------------------------------------------------* 
 * Retorna todos os Grupos, caso seja passado 'S'
 * Retorna Grupos da Empresa, caso seja passado 'N'
 *------------------------------------------------------------------------------*/
export async function GetGruposProduto(notificacao, dispatch, idEmpresa, todosGrupos){
  try {
    
        var ulrPesquisa = '';
        var parametroIdEmpresa = idEmpresa;
        if (todosGrupos === 'S'){
          parametroIdEmpresa = '0';
        }
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetGrupos + `${parametroIdEmpresa}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {

          if(todosGrupos === 'S'){
            dispatch({ idStore, type: 'GRUPOS_TODOs', value: [] })
            dispatch({ idStore, type: 'GRUPOS_TODOs', value: response.data.data })  
          }else{
            dispatch({ idStore, type: 'GRUPOs', value: [] })
            dispatch({ idStore, type: 'GRUPOs', value: response.data.data })  
          }

        //thenFunction()
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

export async function GetProduto(notificacao, dispatch, idEmpresa, idProduto, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetProduto + `${idEmpresa}` + `/${idProduto}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            let type = 'PRODUTO';
            dispatch({ idStore, type, campo: 'idProduto', value: response.data.data.idProduto })
            dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
            dispatch({ idStore, type, campo: 'idGrupo', value: response.data.data.idGrupo })
            dispatch({ idStore, type, campo: 'descricaoProduto', value: response.data.data.descricao })
            dispatch({ idStore, type, campo: 'descricaoProdutoDetalhe', value: response.data.data.descricaoDetalhada })
            dispatch({ idStore, type, campo: 'codigoProduto', value: response.data.data.codigoProduto })
            if (response.data.data.imagemProduto != null){
              dispatch({ idStore, type, campo: 'imagemProduto', value: response.data.data.imagemProduto })
              //dispatch({ idStore, type, campo: 'imagemProduto', value: 'data:image/png;base64,' + response.data.data.imagemProduto })
              //dispatch({ idStore, type, campo: 'imagemProduto', value: response.data.data.imagem })
            }else{
              dispatch({ idStore, type, campo: 'imagemProduto', value: "" })
            }
            dispatch({ idStore, type, campo: 'valorProduto', value: maskMoeda(response.data.data.valorProduto.toFixed(2)) })
            dispatch({ idStore, type, campo: 'impressaoProduto', value: response.data.data.impressaoProduto })
            dispatch({ idStore, type, campo: 'tempoPreparo', value: response.data.data.tempoPreparo })
            dispatch({ idStore, type, campo: 'tipoProduto', value: response.data.data.tipoProduto })
            dispatch({ idStore, type, campo: 'statusProduto', value: response.data.data.status })
            dispatch({ idStore, type, campo: 'unidadeProduto', value: response.data.data.unidade })
            dispatch({ idStore, type, campo: 'ncmProduto', value: response.data.data.ncm })
            dispatch({ idStore, type, campo: 'idTributacaoGrupo', value: response.data.data.idTributacaoGrupo })
            
    
            dispatch({ idStore, type: 'PRODUTO_COPIA' })
            thenFunction()
    
              
          }else{
            alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = 'A' = ATIVO ou 'I' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idUsuario, statusUsuario, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroUsuarioLoginAlterarStatus + `${idUsuario}` + "/" + `${statusUsuario}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Usuário Alterado com Sucesso.", 'success', 'icon-check-2', 2)
          }
          //var user = response.data.data;
          //dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          //dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
          thenFunction()
          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}

/*------------------------------------------------------------------------------* 
 * Retorna todos os NCMs
 *------------------------------------------------------------------------------*/
export async function GetNCMs(notificacao, dispatch, idEmpresa){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetNCMs;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            var ncmsProduto = response.data.data;  
            dispatch({ idStore, type: 'NCMs', value: [] })
  
            if (ncmsProduto.length > 0){
              var totalNcms = ncmsProduto.length;
              for (var i = 0; i < totalNcms; i++) {
                ncmsProduto[i].descricao = ncmsProduto[i].ncm + " - " +  ncmsProduto[i].descricao;
              }
            }
  
            dispatch({ idStore, type: 'NCMs', value: ncmsProduto })    
          }

        //thenFunction()
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}
