//State da tela de Configuracao

var INITIAL_STATE = {

  cadastroConfiguracao: {
    idConfiguracao: '',
    idEmpresa: '',
    quantidadeMesas: '',
    percentualTaxa: '',
    valorEntrega: '',
    cobrarTaxaServicoEntrega: '',
    mensagemRecibo: '',
    mesasComandas: '',
    logo: '',
    horaInicioTrabalho: '',
    horaFinalTrabalho: '',
    vincularMesaComandas: '',
    //Campos para controle de swith
    utilizaMesa: '',
    utilizaComanda: '',
    utilizaPedidoSequencial: '',
    utilizaChefDesktop: '',
    vendedorPedido: '',
  },

  invalido: {
    quantidadeMesas: false,
    mensagemRecibo: false,
  },

  inicioCadastro: false,
  cadastroConfiguracaoCopia: {},
}

export default function CONFIGURACAO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CONFIGURACAO':
          switch (action.type) {
            // case 'PESQUISA':
            //     return { ...state, pesquisaGrupoTributacao: { ...state.pesquisaGrupoTributacao, [action.campo]: action.value } }
            // case 'GRUPOS_TRIBUTACAO_TODOs':
            //     return { ...state, GRUPOTRIBUTACAOs: action.value }
            // case 'CLEAN_SEARCH':
            //     return {
            //         ...state,
            //         pesquisaGrupoTributacao: {
            //         idEmpresa: '',
            //         idGrupo: '',
            //         codigoProduto: '',
            //         descricaoProduto: '',
            //         statusProduto: '',                                    
            //         },
            //         PRODUTOs: []
            //     }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'CONFIGURACAO':
                return { ...state, cadastroConfiguracao: { ...state.cadastroConfiguracao, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'CONFIGURACAO_COPIA':
                return { ...state, cadastroConfiguracaoCopia: state.cadastroConfiguracao }
            // case 'GRUPO_TRIBUTACAO_COPIA_STATUS':
            //     return { ...state, cadastroGrupoTributacaoCopia: { ...state.cadastroGrupoTributacao, status: action.value } }
            case 'CLEAN_CONFIGURACAO':
                return {
                    ...state,
                    cadastroConfiguracao: {
                      idConfiguracao: '',
                      idEmpresa: '',
                      quantidadeMesas: '',
                      percentualTaxa: '',
                      valorEntrega: '',
                      cobrarTaxaServicoEntrega: '',
                      mensagemRecibo: '',
                      mesasComandas: '',
                      horaInicioTrabalho: '',
                      horaFinalTrabalho: '',
                      vincularMesaComandas: '',
                      logo: '',   
                      horaInicioTrabalho: '',
                      horaFinalTrabalho: '',
                      vincularMesaComandas: '',
                      //Campos para controle de swith
                      utilizaMesa: '',
                      utilizaComanda: '',
                      utilizaPedidoSequencial: '',
                      utilizaChefDesktop: '',
                      vendedorPedido: '',                  
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                      quantidadeMesas: false,
                      mensagemRecibo: false,                  
                    }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}