import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerEntrada from '~/controller/Movimentacao/controllerEntrada'
import '~/views/Vendas/Pedido/Modals/styles.css'

import { maskNumbers } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

/*-----------------------------------------------------------------------------------
 * paramPesquisa = Parametros utilizados para fazer a pesquisa após Reabrir/Excluir
 * paramTela = Id e Parametros da Tela
 *-----------------------------------------------------------------------------------*/
const ModalReabrirExcluir = ({isOpen, toggleModalReabrirExcluir, paramPesquisa, paramTela, shouldClean}) => {
    const inputRef = useRef(null);

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    //const [usuarioPedido, setusuarioPedido] = useState({idPedido: '', idEmpresa: '', usuario: '', senha: '', motivo: '', mesaComanda: '' })
    const dispatch = useDispatch();

    // useEffect(() => {
    //   if (movimentacao !== null){
    //     setusuarioPedido({ ...usuarioPedido, idPedido: movimentacao.idMovimento,
    //                                      idEmpresa: movimentacao.idEmpresa,
    //                                      usuario: movimentacao.nomeUsuarioVenda,
    //                                      senha: '',
    //                                      motivo: '',
    //                                      mesaComanda: movimentacao.mesaComanda,
    //      })
    //   }
    //   if (inputRef.current) {
    //     inputRef.current.focus();
    //   } 
    // }, [])
 
    function handleVoltar(){
      toggleModalReabrirExcluir();
    }

    async function handleReabrirExcluir(){
      setLoading(true);
      if (!loading) {

        if (paramTela.acaoTela === 'E'){
          await controllerEntrada.ExcluirMovimento(notificacao, dispatch, paramTela.idMovimento, paramPesquisa, login.usuario.empresa, () => {

          });
        }else{
          await controllerEntrada.ReabrirMovimento(notificacao, dispatch, paramTela.idMovimento, () => {

          });
        }

      }
      setLoading(false);
      toggleModalReabrirExcluir();
    }

    // function handleOnChangeUsuario(value, campo) {
    //   setusuarioPedido({ ...usuarioPedido, usuario: value }) 
    // }
  
    // function handleOnChangeSenha(value, campo) {
    //   setusuarioPedido({ ...usuarioPedido, senha: value }) 
    // }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalReabrirExcluir()}
            fontSize='30px'
            title={paramTela.titulo}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                {/* <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>ID Movimento</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '22px', height: '50px', textAlign:'center' }}
                          id='idMovimento'
                          type='text'
                          autoComplete='off'
                          value={paramTela.idMovimento}
                          disabled
                      />
                  </Col>
                </Row>   */}
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <label style={{fontSize: '20px', textAlign:'center' }}
                    >{paramTela.mensagem}</label>
                  </Col>
                </Row>

{/*                 <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Usuário</label>
                      <Input
                          ref={inputRef} 
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'center' }}
                          id='usuario'
                          type='text'
                          autoComplete='off'
                          value={usuarioPedido.usuario}
                          onChange={(e) =>
                            handleOnChangeUsuario(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>                  
                  <Col className="padding-padrao-col">
                    <label>Senha</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'center' }}
                          id='mesaPara'
                          type='password'
                          autoComplete='off'
                          value={usuarioPedido.senha}
                          onChange={(e) =>
                            handleOnChangeSenha(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                          
                      />
                  </Col>                  

                </Row> */}    
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleReabrirExcluir()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalReabrirExcluir