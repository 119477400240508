/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import global from "~/Util/global";
import api from "~/Util/api";

var ambienteAPI = api.urlBaseChefe;
var ambiente = 'L';
if (ambienteAPI.substring(0,5) === "https"){
  ambiente = 'P'
}
const versao = global.versaoApp + '.' + ambiente;

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
        style={{ paddingBottom: "15px" }}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="category">
            <a
              target="_blank"
              style={{ color: "#d6a956", textShadow: "0px 0px 6px black", fontSize: "1.4" }}
            >
              {versao}
            </a>          </div>
          <div className="copyright">
            © {new Date().getFullYear()} por{" "}
            <a
              href="http://www.chefefaster.com.br"
              //href="#"
              target="_blank"
              style={{ color: "#d6a956", textShadow: "0px 0px 6px black" }}
            >
              Chefe Faster
            </a>{" "}
            {/* e Tecnologia */}
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
