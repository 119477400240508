import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerGrupoTributacao from "~/controller/Cadastros/controllerGrupoTributacao"
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const idStore = "GRUPOTRIBUTACAO";

const GrupoTributoCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { cadastroGrupoTributacao, cadastroGrupoTributacaoCopia, CFOPs, ICMSs, IPIs, PISs, COFINSs, invalido } = useSelector((state) => state.GrupoTributacao);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "GRUPO_TRIBUTACAO", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "GRUPO_TRIBUTACAO", campo, value });
    //dispatch({ idStore, type: "INVALIDO", campo, value });
  }

  function handleStatus(campo){
    var statusGrupoTributacao = '';
    if (isEnabled){
      statusGrupoTributacao = '9';
      setIsEnabled(false);
    }else{
      statusGrupoTributacao = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'GRUPO_TRIBUTACAO', campo: campo, value: statusGrupoTributacao })
  }

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroGrupoTributacao.idGrupoTributacao === '') {
      controllerGrupoTributacao.CreateUpdateGrupoTributacao(notificacao, dispatch, cadastroGrupoTributacao, 'I', cadastroGrupoTributacaoCopia);
    } else {
      controllerGrupoTributacao.CreateUpdateGrupoTributacao(notificacao, dispatch, cadastroGrupoTributacao, 'A', cadastroGrupoTributacaoCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_GRUPO_TRIBUTACAO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('descricaoGrupoTributacao').focus()
  };

  useEffect(() => {
    //Preenche combos de CFOP, ICMS, IPI, PIS e COFINS
    controllerGrupoTributacao.GetTributos(notificacao, dispatch, login.usuario.empresa)
  }, []);

  function onChangeCombo(campo, value) {
    dispatch({ idStore, type: "GRUPO_TRIBUTACAO", campo: campo, value: value });
  }
  
  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Grupo Tributação</label>
                      <Input
                        id="idGrupoTributacao"
                        type="text"
                        disabled
                        value={cadastroGrupoTributacao.idGrupoTributacao}
                        //onChange={e => onChange(e.target.value, 'idUsuario')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="7" md="3">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricaoGrupoTributacao ? "has-danger" : ''}>
                      <Input
                        id="descricaoGrupoTributacao"
                        type="text"
                        value={cadastroGrupoTributacao.descricaoGrupoTributacao}
                        //onChange={e => onChange(e.target.value, 'nome')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroGrupoTributacao.statusGrupoTributacao === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroGrupoTributacao.statusGrupoTributacao === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroGrupoTributacao.statusGrupoTributacao === "1"}
                          onChange={(e) =>
                            handleStatus("statusGrupoTributacao")
                          }
                          value={isEnabled}
                        />
                      </Col>            

                  </Col>

                </Row>

                <Row style={{ marginTop: '-15px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>CFOP</label>
                    <FormGroup className={invalido.idCfop ? "has-danger" : ''}>
                      <Select
                        id="cfopSelect"
                        select={{ value: "idCfop", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: cadastroGrupoTributacao.idCfop }}
                        options={CFOPs}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo("idCfop", value.value)
                        }  
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginTop: '-10px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>ICMS</label>
                    <FormGroup className={invalido.idIcms ? "has-danger" : ''}>
                      <Select
                        id="icmsSelect"
                        select={{ value: "idIcms", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: cadastroGrupoTributacao.idIcms }}
                        options={ICMSs}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo("idIcms", value.value)
                        }  
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                </Row>

                <Row style={{ marginTop: '-10px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>IPI</label>
                    <FormGroup className={invalido.idIpi ? "has-danger" : ''}>
                      <Select
                        id="ipiSelect"
                        select={{ value: "idIpi", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: cadastroGrupoTributacao.idIpi }}
                        options={IPIs}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo("idIpi", value.value)
                        }  
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginTop: '-10px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>PIS</label>
                    <FormGroup className={invalido.idPis ? "has-danger" : ''}>
                      <Select
                        id="pisSelect"
                        select={{ value: "idPis", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: cadastroGrupoTributacao.idPis }}
                        options={PISs}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo("idPis", value.value)
                        }  
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <Row style={{ marginTop: '-10px' }}>
                  <Col className="padding-padrao-col" lg="12" md="3">
                    <label>COFINS</label>
                    <FormGroup className={invalido.idCofins ? "has-danger" : ''}>
                      <Select
                        id="cofinsSelect"
                        select={{ value: "idCofins", label: "descricao" }}
                        //first={"Selecione"}
                        value={{ value: cadastroGrupoTributacao.idCofins }}
                        options={COFINSs}
                        //isSearchable={true}
                        onChange={(value) =>
                          onChangeCombo("idCofins", value.value)
                        }  
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                </Row>



              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('codigoProduto').focus()
    }, 100)
  }

  render() {
    return (
      <GrupoTributoCadastro />
    )
  }
}

export default Wizard;
