import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table-6";
import * as controllerPesqUsuario from "~/controller/controllerPesqUsuario";
import * as controllerCliente from "~/controller/Cadastros/controllerCliente";
import * as controllerFornecedor from "~/controller/Cadastros/controllerFornecedor"
import classNames from "classnames";
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import { maskNumbers } from "~/Util/mascaras";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Cadastros/Pessoas/Fornecedor/Cadastro/FornecedorCadastro.jsx"


var steps = [
  {
    stepName: "", //Nome do Step
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const situacaoFornecedor = [
  { value: "1", label: "Ativo" },
  { value: "9", label: "Inativo" },
  { value: "T", label: "Todos" },
];

const idStore = "FORNECEDOR"

const FornecedorPesquisa = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);

  const { FORNECEDOREs, cadastroFornecedor, pesquisaFornecedor} = useSelector((state) => state.Fornecedor);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);


  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipEditar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleEditarCadastro(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipEditar"
              >
                Editar
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-pencil" />
            </Button>
            {obj.status === 'ATIVO' ?
              <Button
                className="btn-link btn-icon"
                // color={obj.statusUsuario === 'ATIVO' ? "danger" : "success" }
                color="danger"
                id="tooltipInativar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipInativar"
                  >
                  Inativar
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-simple-remove"/>
              </Button>
            :
              <Button
                className="btn-link btn-icon"
                color="success"
                id="tooltipAtivar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltipAtivar"
                    >
                    Ativar
                  </UncontrolledTooltip>                              
                {/* <i className={obj.statusUsuario === 'ATIVO' ? "tim-icons  icon-simple-remove" : "tim-icons icon-check-2"} /> */}
                <i className="tim-icons icon-check-2"/>
              </Button>
            }       
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idFornecedor: prop["idFornecedor"],
        idEmpresa: prop["idEmpresa"],
        cpfCnpj: prop["cpfCnpj"],
        nome: prop["nome"],
        celular: prop["celular"],
        email: prop["email"],
        status: prop["status"],
      };
    });
  }


  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
        await controllerFornecedor.GetFornecedor(notificacao, dispatch, obj.idFornecedor, () => {
            setHorizontalTabs("cadastrar")
            setInicioCadastro(true);
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        });
        setLoading(false);
    }
  }

  async function handleAtivarInativarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      var statusNovo = '1';
      if (obj.statusCliente === 'ATIVO'){
        statusNovo = '9'
      }
      await controllerCliente.AtivarInativar(notificacao, dispatch, obj.idCliente, statusNovo, () => {
        handlePesquisar();

    });
    setLoading(false);
    }
  }

  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(FORNECEDOREs));
    if (FORNECEDOREs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [FORNECEDOREs]);


  function handlePesquisar() {
    controllerFornecedor.Pesquisar(notificacao, dispatch, pesquisaFornecedor, login.usuario.empresa);
  }

  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
    
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("cpfCnpj").focus();
      }, 100);
    }
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: "CLEAN_FORNECEDOR" });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleNovoCadastro(){
      changeActiveTab("cadastrar");
      novoCadatro();
      dispatch({ idStore, type: 'FORNECEDOR', campo: 'status', value: '1' })
  }


  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, cadastroFornecedor: cadastroFornecedor };
    });
    //setInicioCadastro(false);
  }, [cadastroFornecedor]);

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PESQUISA", campo, value });
  }

  function onChangeCombo(value) {
    dispatch({ idStore, type: "PESQUISA", campo: "statusFornecedor", value });
    //setpesquisa({ ...pesquisa, situacao: value })
  }


  function handleKeyPress(event) {
    if (event.key === "Enter") {
      controllerFornecedor.Pesquisar(notificacao, dispatch, pesquisaFornecedor);
    }
  }

  function updateWidth() {
    setWidht(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    //Preenche combo de empresas
    //controllerEmpresas.GetEmpresas(notificacao, dispatch, login.usuario.empresa);
    //dispatch({ idStore, type: "EMPRESA_PESQ", value: [] });
    dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: login.usuario.empresa });
    dispatch({ idStore, type: "PESQUISA", campo: "statusFornecedor", value: 'T' });
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
            <CardHeader className="text-center">
              <CardTitle tag="h4">
                Fornecedores
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  <h5>Pesquisa de Fornecedores</h5>
                </TabPane> 

                 <TabPane tabId="cadastrar">
                  <h5>Cadastro de Fornecedores</h5>
                </TabPane> 
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }} 
                    style={{ width: "50%" }}
                  >
                    Pesquisar
                  </Button>
                </ArrowTooltip>
                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    Cadastrar
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa */}
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" lg="2" md="3">
                    <label>CPF/CNPJ</label>
                    <Input
                      id="cpfCnpj"
                      type="text"
                      autoComplete="off"
                      value={pesquisaFornecedor.cpfCnpj}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      autoFocus={true}
                      maxLength="20"
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg="7" md="3">
                    <label>Nome/Razão Social</label>
                    <Input
                      id="nomeFornecedor"
                      type="text"
                      autoComplete="off"
                      value={pesquisaFornecedor.nomeFornecedor}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      maxLength="50"
                    />
                  </Col>      
                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Situação</label>
                    <Select
                      id="situcaoFornecedorSelect"
                      //select={{ value: "idGrupo", label: "descricao" }}
                      //first={"Selecione"}
                      value={{ value: pesquisaFornecedor.statusFornecedor }}
                      options={situacaoFornecedor}
                      onChange={(value) =>
                        onChangeCombo(value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="Limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Novo Cadastro">
                        <Button
                          color="warning"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleNovoCadastro()}
                        >
                          Novo
                        </Button>
                      </ArrowTooltip>                    
                    </Col>
                  </CardBody>
                </Row>
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  {/* INCIO TabPesquisa-GRADE */}
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "id Fornecedor",
                        accessor: "idFornecedor",
                      },
                      {
                        Header: "Nome/Razão Social",
                        accessor: "nome",
                      },
                      {
                        Header: "Celular",
                        accessor: "celular",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                      },
                     {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-70px" }}>
                   <ReactWizard
                    steps={steps}
                    //navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    //goToFirstStep={inicioCadastro}
                    //finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                      "btn-wd btn-info invisible",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )} 
                     nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                    wizardNavigationVisivel={false}
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {/* Modal Excluir */}

{/*       <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
 */}
      {/* Modal Excluir*/}
    </>
  );
};

export default FornecedorPesquisa;
