
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerProduto from "~/controller/Cadastros/controllerProduto"
import * as controllerGrupoTributacao from "~/controller/Cadastros/controllerGrupoTributacao"
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa"
import { Select } from "~/components/ErnComponets/ErnComponets";
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {formatarData, maskMoeda} from "~/Util/mascaras";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Financeiro/AbrirCaixa/Cadastro/CaixaAbrir.jsx";

var steps = [
  {
    stepName: "", //Nome do Step
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const idStore = "CAIXA"

const situacaoCaixa = [
  { value: "A", label: "Aberto" },
  { value: "F", label: "Fechado" },
  { value: "T", label: "Todos" },
];


const CaixaPesquisa = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);


  
  const { CAIXAs, cadastroCaixa } = useSelector((state) => state.Caixa);

  const [pesquisa, setpesquisa] = useState({ dataInicio: '', dataFinal: '', situacao: '' })
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");

  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);
  

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  useEffect(() => {
    let dataPesqInicio = '';
    let dataPesqFinal = '';
    let dataAtual = new Date();
    let hora = new Date().getHours();
    if (hora >= 0 && hora <= 6){
      dataAtual.setDate(dataAtual.getDate() - 1);
      dataPesqInicio = formatarData('EN', dataAtual.toLocaleDateString());
      //dataPesqInicio = dataAtual.toISOString().substr(0, 10);    
    }else{
      dataPesqInicio = formatarData('EN', dataAtual.toLocaleDateString());
      //dataPesqInicio = new Date().toISOString().substr(0, 10);
    }
    dataPesqFinal = formatarData('EN', dataAtual.toLocaleDateString());
    //dataPesqFinal = new Date().toISOString().substr(0, 10);

    setpesquisa({ ...pesquisa, dataInicio: dataPesqInicio, dataFinal: dataPesqFinal, situacao: 'A' })
  }, [])


  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipEditar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleEditarCadastro(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipEditar"
              >
                Editar
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-pencil" />
            </Button>
            {obj.status === 'INATIVO' ?
              <Button
                className="btn-link btn-icon"
                color="danger"
                id="tooltipInativar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipInativar"
                  >
                  Ativar
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-simple-remove"/>
              </Button>
            :
              <Button
                className="btn-link btn-icon"
                color="success"
                id="tooltipAtivar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltipAtivar"
                    >
                    Inativar
                  </UncontrolledTooltip>                              
                <i className="tim-icons icon-check-2"/>
              </Button>
            }       
          </div>
        ),
        situacaoCaixa: (
          <div className="actions-left">
            {typeof obj.dataFechamento !== 'undefined' ?
              <label>FECHADO</label>
            :
              <label>ABERTO</label>
            }
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idEmpresa: prop["idEmpresa"],
        idCaixa: prop["idCaixa"],
        idUsuario: prop["idUsuario"],
        usuarioLogin: prop["usuarioLogin"],
        usuarioNome: prop["usuarioNome"],
        dataAbertura: prop["dataAbertura"],
        dataFechamento: prop["dataFechamento"],
        valorAbertura: maskMoeda(prop["valorAbertura"].toFixed(2)),
        status: prop["status"],
      };
    });
  }


  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      dispatch({ idStore, type: 'CLEAN_CAIXA' });

      dispatch({ idStore, type: "CAIXA", campo: "idCaixa", value: obj.idCaixa });
      dispatch({ idStore, type: "CAIXA", campo: "idEmpresa", value: obj.idEmpresa });
      dispatch({ idStore, type: "CAIXA", campo: "idUsuario", value: obj.idUsuario });
      dispatch({ idStore, type: "CAIXA", campo: "usuarioLogin", value: obj.usuarioLogin });
      dispatch({ idStore, type: "CAIXA", campo: "dataAbertura", value: obj.dataAbertura });
      dispatch({ idStore, type: "CAIXA", campo: "dataFechamento", value: obj.dataFechamento });
      dispatch({ idStore, type: "CAIXA", campo: "valorAbertura", value: obj.valorAbertura });
      if (obj.status === 'ATIVO'){
        obj.status = '1'
      }else{
        obj.status = '9'
      }
      dispatch({ idStore, type: "CAIXA", campo: "status", value: obj.status });

      setHorizontalTabs("cadastrar")
      setInicioCadastro(true);
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        
    }
    setLoading(false);
  }

  async function handleAtivarInativarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      var statusNovo = '1';
      if (obj.statusUsuario === 'ATIVO'){
        statusNovo = '9'
      }



/*       await controllerCaixa.AtivarInativar(notificacao, dispatch, obj.idGrupoTributacao, statusNovo, () => {
        handlePesquisar();

    });
 */    
    setLoading(false);

    }
  }

  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(CAIXAs));
    if (CAIXAs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [CAIXAs]);


  function handlePesquisar() {
    controllerCaixa.Pesquisar(notificacao, dispatch, pesquisa, login.usuario.empresa);
  }

  function onChangeCombo(value) {
    setpesquisa({ ...pesquisa, situacao: value })
  }

  function handleOnChange(value, campo) {
    if (campo === 'dataInicio'){
      setpesquisa({ ...pesquisa, dataInicio: value })
    }else if (campo === 'dataFinal'){
      setpesquisa({ ...pesquisa, dataFinal: value })
    }
  }


  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
    
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("valorAbertura").focus();
      }, 100);
    }
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: "CLEAN_CAIXA" });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
    dispatch({ idStore, type: "CAIXA", campo: "status", value: '1' });

    let dataAbertura = '';
    let dataAtual = new Date();
    let hora = new Date().getHours();
    if (hora >= 0 && hora <= 6){
      dataAtual.setDate(dataAtual.getDate() - 1);
      dataAbertura = dataAtual.toLocaleDateString();
      //dataAbertura = dataAtual.toISOString().substr(0, 10);    
    }else{
      dataAbertura = dataAtual.toLocaleDateString();
      //dataAbertura = new Date().toISOString().substr(0, 10);
    }

    //dataAbertura = formatarData('BR', dataAbertura);

    dispatch({ idStore, type: "CAIXA", campo: "idCaixa", value: '' });
    dispatch({ idStore, type: "CAIXA", campo: "dataAbertura", value: dataAbertura });
    dispatch({ idStore, type: "CAIXA", campo: "valorAbertura", value: '0,00' });
    dispatch({ idStore, type: "CAIXA", campo: "idEmpresa", value: login.usuario.empresa });
    dispatch({ idStore, type: "CAIXA", campo: "idUsuario", value: login.usuario.idUsuarioLogin });
    dispatch({ idStore, type: "CAIXA", campo: "usuarioLogin", value: login.usuario.nome });

  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleNovoCadastro(){
      changeActiveTab("cadastrar");
      novoCadatro();
  }

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, cadastroCaixa: cadastroCaixa };
    });
    //setInicioCadastro(false);
  }, [cadastroCaixa]);


  function handleKeyPress(event) {
    if (event.key === "Enter") {
      //controllerPesqUsuario.Pesquisar(notificacao, dispatch, pesquisaLogin);
    }
  }


  function updateWidth() {
    setWidht(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    //dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: login.usuario.empresa });
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
             <CardHeader className="text-center">
              <CardTitle tag="h4">
               Fluxo de Caixa
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  <h5>Pesquisa de Caixas</h5>
                </TabPane> 

                 <TabPane tabId="cadastrar">
                  <h5>Abrir Caixa</h5>
                </TabPane> 
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }} 
                    style={{ width: "50%" }}
                  >
                    Pesquisar
                  </Button>
                </ArrowTooltip>
                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    Abrir
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa-GRADE */}
                <Row className="padding-padrao-row">
                <Col md="2" className="padding-padrao-col">
                      <label>Data Início</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '14px' }}
                          id='dataInicio'
                          type='date'
                          autoComplete='off'
                          value={pesquisa.dataInicio}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>
                  <Col md="2" className="padding-padrao-col">
                      <label>Data Final</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '14px' }}
                          id='dataFinal'
                          type='date'
                          autoComplete='off'
                          value={pesquisa.dataFinal}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>
                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Situação</label>
                    <Select
                      id="situcaoCaixaSelect"
                      //select={{ value: "idGrupo", label: "descricao" }}
                      //first={"Selecione"}
                      value={{ value: pesquisa.situacao }}
                      options={situacaoCaixa}
                      //isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo(value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>


                  
                </Row>

                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="Limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Novo Cadastro">
                        <Button
                          color="warning"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleNovoCadastro()}
                        >
                          Novo
                        </Button>
                      </ArrowTooltip>                    
                    </Col>
                  </CardBody>
                </Row>
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "id Caixa",
                        accessor: "idCaixa",
                      },
                      {
                        Header: "Data Abertura",
                        accessor: "dataAbertura",
                      },
                      {
                        Header: "Valor",
                        accessor: "valorAbertura",
                      },
                      {
                        Header: "Data Fechamento",
                        accessor: "dataFechamento",
                      },
                      {
                        Header: "Usuário",
                        accessor: "usuarioNome",
                      },
                      {
                        Header: "Login",
                        accessor: "usuarioLogin",
                      },
                      {
                        Header: "Situação",
                        accessor: "situacaoCaixa",
                      },
                     {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-70px" }}>
                   <ReactWizard
                    steps={steps}
                    //navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    //goToFirstStep={inicioCadastro}
                    //finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                      "btn-wd btn-info invisible",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )} 
                     nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                    wizardNavigationVisivel={false} //Para não mostrar a barra de navegação do wizard
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {/* Modal Excluir */}

{/*       <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
 */}
      {/* Modal Excluir*/}
    </>
  );
};

export default CaixaPesquisa;
