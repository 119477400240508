/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button, UncontrolledTooltip } from "reactstrap";
import { useSelector} from "react-redux";


var ps;


const Sidebar = (props) => {
  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const acesso = useSelector((state) => state.ACESSO);
  React.useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, []);
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

/*-----------------------------------------------------------------------------------------------
 * Acesso aos Menus/SubMenus do Sistema
 * Verifica se tem acesso ao Menu Primeiro e Depois Verifica o Acesso ao SubMenu
 * SubMenu Terceiro nível ainda não implementado
* Menu [Nivel 0]   
 *    |_SubMenu [Nivel 1]
 *             |_SubMenu2 [Nivel 2]
 *                       |_SubMenu3 [Nivel3]
 *-----------------------------------------------------------------------------------------------*/   
React.useEffect(() => {
  props.routes.forEach(menu => {
      if (acesso.PerfilAcessos.length > 0){
        //if (menu.id !== '' && menu.id !== null && menu.id !== undefined){
          if (menu.nivelMenu === "SubMenu0"){
            let menuExiste = acesso.PerfilAcessos.findIndex(x => x.idActivity === menu.id);
            if (menuExiste < 0){
              //Remove Menu
              //menu.views.splice(menu,1)  
            }      
        }else{
          if (menu.nivelMenu === "SubMenu1"){
            for (let i = 0; i < menu.views.length; i++) {
              if (menu.views[i].id !== '' && menu.views[i].id !== null && menu.views[i].id !== undefined){
                let subMenuExiste = acesso.PerfilAcessos.findIndex(x => x.idActivity === menu.views[i].id);
                if (subMenuExiste < 0){
                  //Caso Não Tenha Acesso Remove subMenu
                  menu.views[i].path = '#'
                  menu.views[i].name = menu.views[i].name + ' ** SEM ACESSO **'
                  //subTela.views[indexSubTela2].splice(subTela.views, 1)  
                }                
              }              
            }
          }else if (menu.nivelMenu === "SubMenu2"){
          //if (menu.views !== '' && menu.views !== null && menu.views !== undefined){
            menu.views.forEach(subTela => {
              for (let i = 0; i < subTela.views.length; i++) {
                if (subTela.views[i].id !== '' && subTela.views[i].id !== null && subTela.views[i].id !== undefined){
                  let subMenuExiste = acesso.PerfilAcessos.findIndex(x => x.idActivity === subTela.views[i].id);
                  if (subMenuExiste < 0){
                    //Caso Não Tenha Acesso Remove subMenu
                    subTela.views[i].path = '#'
                    subTela.views[i].name = subTela.views[i].name + ' ** SEM ACESSO **'
                    //subTela.views[indexSubTela2].splice(subTela.views, 1)  
                  }                
                }              
              }
            })
          }
        }
      }  
    });

  }, []);


  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <li
            // Para nao marcar o caminho selecionado no menu pois fica em cima da imagem do menu
            className={(getCollapseInitialState(prop.views) && st[prop["state"]]  ? "active" : ""), prop.className !== "" ? prop.className : ""}
            //className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              className={"collapse"}
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p className="textMenu">
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">
                      {prop.mini}
                    </span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="" 
          onClick={props.closeSidebar}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">
                    {prop.mini}
                  </span>
                  <span className="sidebar-normal">
                    {prop.name}
                  </span>
                </>
              )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  //verifica se routeName é o ativo (na entrada do navegador)
  //const activeRoute = (routeName) => {
  //  return location.pathname === routeName ? "active" : "";
  //};
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // componentDidMount() {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   //se você estiver usando uma máquina Windows, as barras de rolagem terão uma aparência de Mac
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebar);
  //   }
  //   window.addEventListener("resize", this.minimizeVisible.bind(this));
  // }
  // componentWillUnmount() {
  //   // we need to destroy the false scrollbar when we navigate
  //   // to a page that doesn't have this component rendered
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", minimizeVisible.bind(this));
  // }
  // minimizeVisible() {
  //   window.innerWidth < 1200 ?
  //     setState({ minimizeVisible: false }) :
  //     setState({ minimizeVisible: true })
  // }
  //render() {
    const { activeColor, logo } = props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={props.closeSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={props.closeSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <NavLink
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={props.closeSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </NavLink>
        );
        logoText = (
          <NavLink
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={props.closeSidebar}
          >
            {logo.text}
          </NavLink>
        );

      }
    }
    return (
      <div className="sidebar" data={activeColor}
        style={!state.minimizeVisible ? {} : { marginTop: '20px' }}
      >
        {state.minimizeVisible ?
          <div className="navbar-minimize d-inline navBar-Btn-minimizar">
            <Button
              className="minimize-sidebar btn-just-icon"
              color="link"
              id="tooltip209599"
              disabled={
                this.props.location.pathname === "/pedidoitem" ? true : false
              }
              onClick={props.handleMiniClick}
            >
              <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
              <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209599"
              placement="right"
            >
              Fixar
                </UncontrolledTooltip>
          </div> : null}
        {/* <div className="sidebar-wrapper" ref="sidebar"> */}
        <div className="sidebar-wrapper" ref={sidebarRef}>
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    );
  };


Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func
};

export default Sidebar;



/*eslint-disable*/
/* import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button, UncontrolledTooltip } from "reactstrap";
import { useSelector} from "react-redux";


var ps;


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getCollapseStates(props.routes), minimizeVisible: true };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={(this.getCollapseInitialState(prop.views) && st[prop["state"]]  ? "active" : ""), prop.className !== "" ? prop.className : ""}
            key={key}
          >
            <a
            className={"collapse"}
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p className="textMenu">
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">
                      {prop.mini}
                    </span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="" 
          onClick={this.props.closeSidebar}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">
                    {prop.mini}
                  </span>
                  <span className="sidebar-normal">
                    {prop.name}
                  </span>
                </>
              )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  //verifica se routeName é o ativo (na entrada do navegador)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    //se você estiver usando uma máquina Windows, as barras de rolagem terão uma aparência de Mac
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar);
    }
    window.addEventListener("resize", this.minimizeVisible.bind(this));
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.minimizeVisible.bind(this));
  }
  minimizeVisible() {
    window.innerWidth < 1200 ?
      this.setState({ minimizeVisible: false }) :
      this.setState({ minimizeVisible: true })
  }
  render() {
    const { activeColor, logo } = this.props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.closeSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.closeSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <NavLink
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={this.props.closeSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </NavLink>
        );
        logoText = (
          <NavLink
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.closeSidebar}
          >
            {logo.text}
          </NavLink>
        );

      }
    }
    return (
      <div className="sidebar" data={activeColor}
        style={!this.state.minimizeVisible ? {} : { marginTop: '20px' }}
      >
        {this.state.minimizeVisible ?
          <div className="navbar-minimize d-inline navBar-Btn-minimizar">
            <Button
              className="minimize-sidebar btn-just-icon"
              color="link"
              id="tooltip209599"
              onClick={this.props.handleMiniClick}
            >
              <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
              <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209599"
              placement="right"
            >
              Fixar
                </UncontrolledTooltip>
          </div> : null}
        <div className="sidebar-wrapper" ref="sidebar">
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func
};

export default Sidebar;
 */