import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerOperacao from "~/controller/Cadastros/controllerOperacao.js"
import * as controllerEntrada from "~/controller/Movimentacao/controllerEntrada.js"
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas.js"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {formatarData, maskMoeda, removeMaskMoney} from "~/Util/mascaras";
import useModal from '~/Util/Hooks/useModal.ts';
import ModalFornecedores from '../Modals/modalFornecedores.jsx'
import ModalProdutos from '../Modals/modalProdutos.jsx'
import { Select } from "~/components/ErnComponets/ErnComponets";
import { alertar } from "~/Util/alertar";

import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  FormGroup,
  CustomInput,
  UncontrolledTooltip
} from "reactstrap";

const idStore = "ENTRADA";

const EntradaCadastro = () => {
  var tema = localStorage.getItem('TEMA')
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { OPERACOEs } = useSelector((state) => state.Operacao);
  const {ENTRADAITENs, cadastroEntrada, cadastroEntradaCopia, cadastroEntradaItem, invalido, invalidoItem} = useSelector((state) => state.Entrada);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [horizontalTabs, setHorizontalTabs] = useState("cadastrar");

  /*------------------------------------------------------------------------------ 
   * MODAIS
   *------------------------------------------------------------------------------*/
  const [pesquisa, setpesquisa] = useState({ produto: '' })
  const [shouldClean, setshouldClean] = useState(false)
  const [modalFornecedores, toggleModalFornecedores] = useModal(false)
  const [modalProdutos, toggleModalProdutos] = useModal(false)


  /*------------------------------------------------------------------------------ 
   * FOCUS dos Campos
   *------------------------------------------------------------------------------*/
  const focoProduto = useRef(false);
  const focoQuantidade = useRef(false);
  const focoValorItem = useRef(false);
  const focoNumeroDocumento = useRef(false);

  function handleModalProdutos() {
    focoQuantidade.current = true;
    toggleModalProdutos();
  }

  function handleModalFornecedores() {
    focoProduto.current = true;
    toggleModalFornecedores();
  }


  /*------------------------------------------------------------------------------ 
   * FOCUS dos Campos
   *------------------------------------------------------------------------------*/
    if (focoProduto.current){
      setTimeout(() => {
        document.getElementById("idProduto").focus();
      }, 100);
      focoProduto.current = false;
    }  
  
    if (focoQuantidade.current){
      document.getElementById("quantidade").focus();
      focoQuantidade.current = false;
    }

    if (focoNumeroDocumento.current){
      setTimeout(() => {
        document.getElementById("numeroDocumento").focus();
      }, 100);
      focoNumeroDocumento.current = false;
    }  
    
    if (focoValorItem.current){
      document.getElementById("valorItem").focus();
      focoValorItem.current = false;
    }  

    useEffect(() => {
      setTable(renderTable());
    }, [dataTable, loading]);
  
    function renderTable() {
      return dataTable.map((obj, key) => {
        return {
          ...dataTable[key],
          codigoProduto: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '10px', fontWeight: 'normal'}}>
              {obj.idProduto}
            </div>
          ),
          descricao: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'left', fontSize: '11px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '11px', fontWeight: 'normal'}}>
              {obj.descricao}
            </div>
          ),
          quantidade: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'center', fontSize: '11px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '11px', fontWeight: 'normal'}}>
              {obj.quantidade}
            </div>
          ),
          valorItem: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'right', fontSize: '11px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'right', fontSize: '11px', fontWeight: 'normal'}}>
              {obj.valorItem}
            </div>
          ),
          valorTotal: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'right', fontSize: '11px', fontWeight: 'bold'}: {color: '#727272', textAlign: 'right', fontSize: '11px', fontWeight: 'bold'}}>
              {obj.valorTotal}
            </div>
          ),
          status: (
            <div style={tema === 'ESCURO'? {color: '#ffffff', textAlign: 'center', fontSize: '11px', fontWeight: 'normal'}: {color: '#727272', textAlign: 'left', fontSize: '11px', fontWeight: 'normal'}}>
              {obj.status}
            </div>
          ),
  
          actions: (
            <div className="actions-center">
              <Button
                className="btn-link btn-icon"
                color="info"
                id="tooltipEditar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
              >
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipEditar"
                >
                  Editar Item
                </UncontrolledTooltip>                              
                <i className="tim-icons icon-pencil" />
              </Button>

              <Button
                className="btn-link btn-icon"
                color="danger"
                id="tooltipRemover"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleRemoverItem(obj);
                }}
              >
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipRemover"
                >
                  Remover
                </UncontrolledTooltip>                              
                <i className="tim-icons icon-simple-remove" />
              </Button>      
            </div>
          ),
        };
      });
    }
  
  
    //Monta Dados para Gride
    function tableData(data) {
      return data.map((prop, key) => {
        return {
          id: key,
          idMovimentoItem: prop["idMovimentoItem"],
          idMovimento: prop["idMovimento"],
          idProduto: prop["idProduto"],
          descricao: prop["descricao"],
          quantidade: prop["quantidade"],
          valorItem: maskMoeda(prop["valorItem"].toFixed(2)),
          valorTotal: maskMoeda(prop["valorTotal"].toFixed(2)),
          status: prop["status"],
        };
      });
    }
  
    //Se existir registro carrega e deixa a gride visivel
    useEffect(() => {
      setDataTable(tableData(ENTRADAITENs));
      if (ENTRADAITENs.length > 0) {
        setTableVisible(true);
      } else {
        setTableVisible(false);
      }
    }, [ENTRADAITENs]);


    async function handleEditarCadastro(obj) {
      if (cadastroEntrada.dataFinalizado !== '' && cadastroEntrada.dataFinalizado !== null && cadastroEntrada.dataFinalizado !== undefined){
        alertar(notificacao, 'br', "Movimentação já FINALIZADA.", 'warning', 'icon-alert-circle-exc', 2);    
      }else{
        setLoading(true);
        if (!loading) {
  
            let idStore = 'ENTRADA';
            let type = 'ENTRADA_ITEM';
      
            dispatch({ idStore, type, campo: 'idMovimentoItem', value: obj.idMovimentoItem })
            dispatch({ idStore, type, campo: 'idMovimento', value: obj.idMovimento })
            dispatch({ idStore, type, campo: 'idProduto', value: obj.idProduto })
            dispatch({ idStore, type, campo: 'descricao', value: obj.descricao })
            dispatch({ idStore, type, campo: 'quantidade', value: obj.quantidade })
            dispatch({ idStore, type, campo: 'valorItem', value: obj.valorItem })
            dispatch({ idStore, type, campo: 'valorTotal', value: obj.valorTotal }) 
            dispatch({ idStore, type, campo: 'alterando', value: 'S' }) 
        }
        setLoading(false);
      }      
    }
  
    async function handleRemoverItem(obj) {
      if (cadastroEntrada.dataFinalizado !== '' && cadastroEntrada.dataFinalizado !== null && cadastroEntrada.dataFinalizado !== undefined){
        alertar(notificacao, 'br', "Movimentação já FINALIZADA.", 'warning', 'icon-alert-circle-exc', 2);    
      }else{
        setLoading(true);
        if (!loading) {
           
            await controllerEntrada.AlterarRemoverItem(notificacao, dispatch, obj, 'S', () => {
              dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
            });
        }
        setLoading(false);  
      }
    }
    

  function onChangeOperacao(value) {
    dispatch({ idStore, type: "ENTRADA", campo: "idOperacao", value: value });
  }

  useEffect(() => {
    let dataPesq = '';
    let dataAtual = new Date();
    dataPesq = formatarData('EN', dataAtual.toLocaleDateString());
    let type = 'ENTRADA';

    if (cadastroEntrada.dataInclusao === ''){  
      dispatch({ idStore, type, campo: 'dataInclusao', value: dataPesq });
    }

    if (cadastroEntrada.dataMovimento === ''){
      dispatch({ idStore, type, campo: 'dataMovimento', value: dataPesq });
    }

    dispatch({ idStore, type: "ENTRADA", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "ENTRADA", campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 

    controllerOperacao.Pesquisar(notificacao, dispatch, 'E', login.usuario.empresa);

    focoProduto.current = false;
    focoQuantidade.current = false;
    focoValorItem.current = false;
    focoNumeroDocumento.current = true;

    document.getElementById("numeroDocumento").focus();

  }, []);

  // //Carregar uma vez
  //  useEffect(() => {
  //   handleStatus(true);
  //  }, [])
 
  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "ENTRADA", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "ENTRADA", campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 
    dispatch({ idStore, type: "ENTRADA", campo, value });
  }

  function handleOnChangeItem(value, campo) {
    var valorTotalItem = 0;
    var valorItem = 0;
    switch (campo) {
      case "idProduto":
        dispatch({ idStore, type: "ENTRADA_ITEM", campo, value });
        setpesquisa({ ...pesquisa, produto: value });
        break;
      case "quantidade":
        valorItem = cadastroEntradaItem.valorItem;
        valorItem = removeMaskMoney(valorItem)
        valorTotalItem = value * valorItem;  

        dispatch({ idStore, type: "ENTRADA_ITEM", campo, value });
        dispatch({ idStore, type: "ENTRADA_ITEM", campo: 'valorTotal', value: maskMoeda(valorTotalItem.toFixed(2)) });
        break;
      case "valorItem":
        valorItem = value;
        valorItem = removeMaskMoney(valorItem)
        valorTotalItem = cadastroEntradaItem.quantidade * valorItem;  

        dispatch({ idStore, type: "ENTRADA_ITEM", campo: 'valorTotal', value: maskMoeda(valorTotalItem.toFixed(2)) });
        dispatch({ idStore, type: "ENTRADA_ITEM", campo, value });        
        break;
      default:
        break;
    } 
  }

  /*-----------------------------------------------------------------
  * PESQUISAS
  *-----------------------------------------------------------------*/
  async function handleBuscarProduto(){
    setLoading(true);
    if (!loading) {
      setpesquisa({ ...pesquisa, produto: cadastroEntradaItem.idProduto })

      //Limpa Objetos de Pesquisa
      let idStore = 'PEDIDO_PESQ_PRODUTO'
      dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
      dispatch({ idStore, type: 'PRODUTOs', value: [] })  

      if (!isNaN(parseFloat(pesquisa.produto)) && isFinite(pesquisa.produto)) {
        await controllerEntrada.GetProduto(notificacao, dispatch, login.usuario.empresa, pesquisa.produto, () => {
            document.getElementById("quantidade").focus();  
        });
      }else{
        document.getElementById("btnPesqProduto").click();    
      }
    }
    setLoading(false);
  }


  function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "numeroDocumento":
          event.preventDefault(); //Não fazer o reload da pagina toda
          document.getElementById("idProduto").focus();
          focoProduto.current = true;;
          //document.getElementById('dataInclusao').focus()
          //document.getElementById("idProduto").focus();
          break;
        case "idProduto":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (cadastroEntradaItem.idProduto !== ''){
            handleBuscarProduto();    
          }else{
            document.getElementById("btnPesqProduto").click();  
          }
          break;
          //event.preventDefault(); //Não fazer o reload da pagina toda
          //document.getElementById('quantidade').focus()
          //break;
        case "quantidade":
          //event.preventDefault(); //Não fazer o reload da pagina toda
          document.getElementById('valorItem').focus()
          focoValorItem.current = true;
          break;
        case "valorItem":
          event.preventDefault(); //Não fazer o reload da pagina toda
          handleAdicionarItem();
          document.getElementById('idProduto').focus()
          break;    
        default:
          break;
      }
    }
  }

  async function handleAdicionarItem(e) {
    setLoading(true);
    if (!loading) {
      //Verifica Campos
      var camposOk = true;
      if (cadastroEntrada.idFornecedor === ''){
        alertar(notificacao, 'br', "Informe o FORNECEDOR.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }else if (cadastroEntrada.idOperacao === ''){
        alertar(notificacao, 'br', "Informe o TIPO DE OPERAÇÃO.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }else if (cadastroEntradaItem.idProduto === ''){
        alertar(notificacao, 'br', "Informe o PRODUTO.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }else if (cadastroEntradaItem.quantidade === ''){
        alertar(notificacao, 'br', "Informe a QUANTIDADE.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }else if (cadastroEntradaItem.valorItem === ''){
        alertar(notificacao, 'br', "Informe o VALOR do Produto.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }else if (cadastroEntrada.dataFinalizado !== '' && cadastroEntrada.dataFinalizado !== null && cadastroEntrada.dataFinalizado !== undefined){
        alertar(notificacao, 'br', "Movimentação já FINALIZADA.", 'warning', 'icon-alert-circle-exc', 2);    
        camposOk = false;
      }

      if (camposOk){
        dispatch({ idStore, type: "ENTRADA", campo: "idEmpresa", value: login.usuario.empresa }); 
        dispatch({ idStore, type: "ENTRADA", campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 

        //Alteração de Item
        if (cadastroEntradaItem.alterando === 'S'){
          await controllerEntrada.AlterarRemoverItem(notificacao, dispatch, cadastroEntradaItem, 'N', () => {
            dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
          });
        }else{
          //Inclusao de Item
          if (cadastroEntrada.idMovimento === '') {
            controllerEntrada.AdicionarItem(notificacao, dispatch, cadastroEntrada, cadastroEntradaItem, 'E', 'I', cadastroEntradaCopia);
          } else {
            controllerEntrada.AdicionarItem(notificacao, dispatch, cadastroEntrada, cadastroEntradaItem, 'E', 'A', cadastroEntradaCopia);
          }
        }
      }
    }
    setLoading(false);
  };

  var camposOk = true;
  function ValidarCampos(){

    //Verifica Campos
    camposOk = true;
    if (cadastroEntrada.idMovimento === '') {
      alertar(notificacao, 'br', "Não é possível Finalizar sem Movimento Lançado.", 'warning', 'icon-alert-circle-exc', 2);    
      camposOk = false;
    }else if (cadastroEntrada.idFornecedor === ''){
      alertar(notificacao, 'br', "Informe o FORNECEDOR.", 'warning', 'icon-alert-circle-exc', 2);    
      camposOk = false;
    }else if (cadastroEntrada.idOperacao === ''){
      alertar(notificacao, 'br', "Informe o TIPO DE OPERAÇÃO.", 'warning', 'icon-alert-circle-exc', 2);    
      camposOk = false;
    }else if (cadastroEntrada.dataFinalizado !== '' && cadastroEntrada.dataFinalizado !== null && cadastroEntrada.dataFinalizado !== undefined){
      alertar(notificacao, 'br', "Movimentação já FINALIZADA.", 'warning', 'icon-alert-circle-exc', 2);    
      camposOk = false;
    }    
  }

  async function handleSalvar(e){
    setLoading(true);
    if (!loading) {
      ValidarCampos();
      if (camposOk){
        dispatch({ idStore, type: "ENTRADA", campo: "idEmpresa", value: login.usuario.empresa }); 
        dispatch({ idStore, type: "ENTRADA", campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 
        controllerEntrada.Salvar(notificacao, dispatch, cadastroEntrada, 'E', 'N', cadastroEntradaCopia);
      }
    }
    setLoading(false);
  }

  async function handleFinalizar(e) {
    setLoading(true);
    if (!loading) {
      ValidarCampos();
      if (camposOk){
        dispatch({ idStore, type: "ENTRADA", campo: "idEmpresa", value: login.usuario.empresa }); 
        dispatch({ idStore, type: "ENTRADA", campo: "idUsuario", value: login.usuario.idUsuarioLogin }); 
        await controllerEntrada.Salvar(notificacao, dispatch, cadastroEntrada, 'E', 'S', cadastroEntradaCopia, () => { 
          changeActiveTab("pesquisar");  
        });
      }      
    }
    setLoading(false);
  };

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("numeroDoc").focus();
      }, 100);
    }else if (tabName === "cadastrar") {
      setTimeout(() => {
        document.getElementById("numeroDocumento").focus();
      }, 100);
    }
  }


  async function importarXml(e) {
    e.preventDefault();
  };


  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_ENTRADA' });
    dispatch({ idStore, type: 'CLEAN_ENTRADA_ITEM' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    dispatch({ idStore, type: 'ENTRADAITENs', value: [] });

    document.getElementById('numeroDocumento').focus()
  };


  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-80px" }}>
        <Col>
            <Row className="justify-content-center" >
              <Col md="12">
                <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
                  <legend>Informações da Entrada</legend>
                  <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

                    <Col className="padding-padrao-col" lg="1" md="2">
                      <label>Id Entrada</label>
                        <Input
                          id="idMovimento"
                          type="text"
                          disabled
                          value={cadastroEntrada.idMovimento}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                        />
                    </Col>

                    <Col className="padding-padrao-col" lg="2" md="3">
                      <label>Num. Documento</label>
                        <Input
                          ref={focoNumeroDocumento}
                          id="numeroDocumento"
                          type="text"
                          value={cadastroEntrada.numeroDocumento}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          onKeyDown={(e) => handleKeyPress(e, e.target.id)}
                          // onKeyPress={(e) => {
                          //   if (e.target.value !== ''){
                          //     handleKeyPress(e, e.target.id);
                          //     //if (e.key === 'Enter') e.target.select()  
                          //   }
                          // }}
        
                          autoComplete="off"
                          maxLength="10"
                        />
                    </Col>

                    <Col md="2" className="padding-padrao-col">
                    <label>Data Documento</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataInclusao'
                        type='date'
                        autoComplete='off'
                        value={cadastroEntrada.dataInclusao}
                        onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        onFocus={e => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.target.value !== ''){
                            handleKeyPress(e, e.target.id);
                            if (e.key === 'Enter') e.target.select()  
                          }
                        }}
                      />
                    </Col>

                    <Col md="2" className="padding-padrao-col">
                      <label>Data Entrada</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '14px' }}
                          id='dataMovimento'
                          type='date'
                          autoComplete='off'
                          value={cadastroEntrada.dataMovimento}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.id);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                        />
                    </Col>

                    <Col className="padding-padrao-col" md="5">
                      <label>Tipo de Operação</label>
                      <Select
                        id="operacaoSelect"
                        select={{ value: "idOperacao", label: "descricao" }}
                        first={"Selecione"}
                        value={{ value: cadastroEntrada.idOperacao }}
                        options={OPERACOEs}
                        isSearchable={true}
                        onChange={(value) =>
                          onChangeOperacao(value.value, "idOperacao")
                        }  
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: "25px", marginRight: "25px"}}>
                    <Col className="padding-padrao-col" lg="1" md="3">
                        <label>Fornecedor</label>
                        <FormGroup className={invalido.idFornecedor ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="idFornecedor"
                            type="text"
                            value={cadastroEntrada.idFornecedor}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="15"
                          />
                        </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg="4" md="3">
                        <label>Nome/Razão Social</label>
                        <FormGroup className={invalido.nomeFornecedor ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="nomeFornecedor"
                            type="text"
                            value={cadastroEntrada.nomeFornecedor}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="50"
                          />
                        </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg="2" md="3">
                        <label>CPF/CNPJ</label>
                        <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="cpfCnpj"
                            type="text"
                            value={cadastroEntrada.cpfCnpj}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="20"
                          />
                        </FormGroup>
                    </Col>
                    <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '26px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handleModalFornecedores()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                    </Col>

                    <Col className="padding-padrao-col" md="3">
                    </Col>
                    <Col className="padding-padrao-col" md="2" style={{marginLeft: '-48px', marginTop: '26px'}}>
                      <Input
                        style={{textAlign: 'center', fontWeight: 'normal', fontStyle: 'normal' , color: "#0c7eaf", fontSize: '16px'}}
                        disabled
                        id="valorTotal"
                        type="text"
                        value={cadastroEntrada.statusProcesso}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="15"
                      />
                    </Col>

                  </Row>
                </fieldset>  
              </Col>
            </Row>
        </Col>
      </Row>

      <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
        <legend>Produto</legend>
          <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

            <Col className="padding-padrao-col" lg="2" md="2">
              <label>Código Produto</label>
              <FormGroup className={invalidoItem.idProduto ? "has-danger" : ''}>              
                <Input
                  ref={focoProduto}
                  style={{textTransform:"uppercase" , fontSize: '14px' }}
                  id="idProduto"
                  type="text"
                  value={cadastroEntradaItem.idProduto}
                  onChange={(e) =>
                    handleOnChangeItem(e.target.value, e.target.id)
                  }
                  onFocus={e => e.target.select()}
                  onKeyPress={(e) => {
                    if (e.target.value !== ''){
                      handleKeyPress(e, e.target.id);
                      if (e.key === 'Enter') e.target.select()  
                    }
                  }}
                  autoComplete="off"
                />
              </FormGroup>  
            </Col>
            <Col lg="auto" className="padding-padrao-col">
              <Button
                  id="btnPesqProduto"
                  style={{ marginLeft: '0px', marginTop: '26px', width: '40px', marginBottom: '0px' }}
                  type='button'
                  color="info"
                  onClick={() => handleModalProdutos()}
              >
                  <Icon icon="search" className="fa-lg" />
              </Button>
            </Col>


            <Col className="padding-padrao-col" lg="6" md="3">
              <label>Descrição</label>
                <Input
                  style={{textTransform:"uppercase" , fontSize: '14px' }}
                  disabled
                  id="descricao"
                  type="text"
                  value={cadastroEntradaItem.descricao}
                  onChange={(e) =>
                    handleOnChangeItem(e.target.value, e.target.id)
                  }
                  autoComplete="off"
                />
              
            </Col>

            <Col className="padding-padrao-col" lg="1" md="3">
              <label>Quantidade</label>
              <FormGroup className={invalidoItem.quantidade ? "has-danger" : ''}>
                <Input
                  style={{textTransform:"uppercase" , fontSize: '14px' }}
                  id="quantidade"
                  type="text"
                  value={cadastroEntradaItem.quantidade}
                  onFocus={e => e.target.select()}
                  onChange={(e) =>
                    handleOnChangeItem(e.target.value, e.target.id)
                  }    
                  onKeyPress={(e) => {
                    if (e.target.value !== ''){
                      handleKeyPress(e, e.target.id);
                      if (e.key === 'Enter') e.target.select()  
                    }
                  }}
                  autoComplete="off"
                  maxLength="10"
                />
              </FormGroup>
            </Col>
            <Col className="padding-padrao-col" lg="1" md="3">
              <label>Valor Unitário</label>
              <FormGroup className={invalidoItem.valorItem ? "has-danger" : ''}>
                <Input
                  style={{marginLeft: '5px', textAlign: 'right', textTransform:"uppercase" , fontSize: '14px' }}
                  id="valorItem"
                  type="text"
                  value={cadastroEntradaItem.valorItem}
                  onChange={(e) =>
                    handleOnChangeItem(maskMoeda(e.target.value), e.target.id)
                  }    
                  onKeyPress={(e) => {
                    if (e.target.value !== ''){
                      handleKeyPress(e, e.target.id);
                      if (e.key === 'Enter') e.target.select()  
                    }
                  }}
                  autoComplete="off"
                  maxLength="15"
                />
              </FormGroup>
            </Col>

            <Col className="padding-padrao-col" lg="1" md="3" >
              <label>Total</label>
                <Input
                  disabled
                  style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '16px', textAlign: 'right' }}                  
                  id="valorTotal"
                  type="text"
                  value={cadastroEntradaItem.valorTotal}
                  onChange={(e) =>
                    handleOnChangeItem(maskMoeda(e.target.value), e.target.id)
                  }    
                  autoComplete="off"
                  maxLength="15"
                />
            </Col>
            <Col lg="auto" className="padding-padrao-col">
              <ArrowTooltip title='Adicionar Item'>    
                <Button
                    className='btn btn-green'
                    style={{ marginLeft: '3px', marginTop: '26px', width: '40px', marginBottom: '0px', fontWeight: 'bold', fontSize: '22px' }}
                    type='button'
                    //color="info"
                    onClick={() => handleAdicionarItem()}
                >
                  {cadastroEntradaItem.alterando === 'N' ? '+' : '✔'}
                  <Icon icon="icon-simple-add" className="fa-lg" />
                </Button>
                </ArrowTooltip>  
            </Col>
          </Row>

        </fieldset>

        <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
          <legend>Produtos Lançados</legend>
          <Row style={{ marginLeft: "25px", marginRight: "25px"}}>
            <Col>
              <div
                  className='scrollBar'
                  style={
                  tableVisible
                    ? {
                        transition: "0.5s ease-in-out",
                        visibility: "visible",
                        opacity: "1",
                        position: 'relative', maxHeight: 230,
                      }
                    : { visibility: "hidden", opacity: "0" }
                  }
                >
              {/* INCIO TabPesquisa-GRADE */}
              <ReactTable
                data={table}
                filterable={false}
                columns={[
                  {
                    Header: () => (<div style={{ textAlign: 'left', fontSize: '12px' }}>Código Produto</div>),
                    accessor: "codigoProduto",
                    width: 150,
                    height: 30,
                    sortable: false,
                  },
                  {
                    Header: () => (<div style={{ textAlign: 'left', fontSize: '12px' }}>Descrição</div>),
                    accessor: "descricao",
                    width: 400,
                    height: 30,
                    sortable: false,
                  },
                  {
                    Header: () => (<div style={{ textAlign: 'center', fontSize: '12px' }}>Quantidade</div>),
                    accessor: "quantidade",
                    width: 100,
                    height: 20,
                    sortable: false,
                  },
                  {
                    Header: () => (<div style={{ textAlign: 'right', fontSize: '12px' }}>Valor Item</div>),
                    accessor: "valorItem",
                    width: 120,
                    height: 20,
                    sortable: false,                    
                  },
                  {
                    Header: () => (<div style={{ textAlign: 'right', fontSize: '12px' }}>Valor Total</div>),
                    accessor: "valorTotal",
                    width: 150,
                    height: 20,
                    sortable: false,                    
                  },
                  {
                    Header: () => (<div style={{ textAlign: 'center', fontSize: '12px' }}>Situação</div>),
                    accessor: "status",
                    width: 100,
                    height: 20,
                    sortable: false,                    
                  },
                  {
                    Header: "",
                    height: 20,
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                minRows={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                noDataText=""
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
              </div>
            </Col>                  
          </Row>
        </fieldset>

        <Row className="justify-content-height" style={{ marginRight: '0px', marginTop: '5px' }}>
          <Col className="padding-padrao-col" md="10">
          </Col>
          <Col className="padding-padrao-col" md="2">
            <Input
              style={{textAlign: 'right', fontWeight: 'bold', fontStyle: 'normal' , color: "#f7a610", fontSize: '18px'}}
              disabled
              id="valorTotal"
              type="text"
              value={"R$ " + cadastroEntrada.valorTotal}
              onChange={(e) =>
                handleOnChange(e.target.value, e.target.id)
              }    
              autoComplete="off"
              maxLength="15"
            />
          </Col>
        </Row>        

                      

      <Row className="justify-content-center" style={{ marginTop: "0px" }}>
        <Col>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                  <ArrowTooltip title='Limpar'>
                    <Button 
                      style={{ width: '120px'}}
                      color="default" 
                      onClick={(e) => Limpar(e)}
                      >
                        <Icon icon="icon-trash-simple" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Limpar
                    </Button>
                  </ArrowTooltip>
                  <ArrowTooltip title='Importar XML de Nota Fiscal'>
                    <Button 
                      style={{ width: '150px'}}
                      color="Primary" 
                      onClick={(e) => importarXml(e)}>
                        <Icon icon="download" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Importar XML
                    </Button>
                  </ArrowTooltip>
                  <ArrowTooltip title='Salva e Continuar Depois'>
                    <Button 
                      style={{ width: '120px'}}
                      color="info" 
                      onClick={(e) => handleSalvar(e)}>
                        <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Salvar
                    </Button>
                  </ArrowTooltip>
                  <ArrowTooltip title='Finalizar Entrada'>
                    <Button 
                      style={{ width: '120px'}}
                      color="info" 
                      onClick={(e) => handleFinalizar(e)}>
                        <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Finalizar
                    </Button>
                  </ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>


      {modalFornecedores ?
        <ModalFornecedores
          isOpen={true}
          toggleModalFornecedores={toggleModalFornecedores}
          //paramCliente={pesquisaCliente.cliente}
          shouldClean={shouldClean}
      />
      : null}

      {modalProdutos ?
          <ModalProdutos
            isOpen={true}
            toggleModalProdutos={toggleModalProdutos}
            paramProduto={pesquisa.produto}
            shouldClean={shouldClean}
        />
        : null}



    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('celularCliente').focus()
    }, 100)
  }

  render() {
    return (
      <EntradaCadastro />
    )
  }
}

export default Wizard;
