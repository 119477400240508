//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaProduto: {
      idEmpresa: '',
      idGrupo: '0',
      codigoProduto: '',
      descricaoProduto: '',
      statusProduto: '',
  },

  cadastroProduto: {
      idEmpresa: '',
      idProduto: '',
      idGrupo: '',
      descricaoProduto: '',
      descricaoProdutoDetalhe: '',
      codigoProduto: '',
      imagemProduto: '',
      valorProduto: '',
      impressaoProduto: '',
      tempoPreparo: 0,
      tipoProduto: '',
      unidadeProduto: '',
      ncmProduto: '',
      cestProduto: '',
      idTributacaoGrupo: '',
      statusProduto: '',
  },

  invalido: {
    idGrupo: false,
    descricaoProduto: false,
    valorProduto: false,
    tipoProduto: false,
  },

  PRODUTOs: [],
  inicioCadastro: false,
  CadastroProdutoCopia: {},
  GRUPOs: [],
  GRUPOSTODOs: [],
  NCMs: [],
}

export default function PRODUTO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'PRODUTO':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaProduto: { ...state.pesquisaProduto, [action.campo]: action.value } }
              case 'PRODUTOs':
                  return { ...state, PRODUTOs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaProduto: {
                        idEmpresa: '',
                        idGrupo: '0',
                        codigoProduto: '',
                        descricaoProduto: '',
                        statusProduto: '',                                    
                      },
                      PRODUTOs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'PRODUTO':
                  return { ...state, cadastroProduto: { ...state.cadastroProduto, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'PRODUTO_COPIA':
                  return { ...state, CadastroProdutoCopia: state.cadastroProduto }
              case 'PRODUTO_COPIA_STATUS':
                  return { ...state, cadastroProdutoCopia: { ...state.cadastroProduto, status: action.value } }
              case 'CLEAN_PRODUTO':
                  return {
                      ...state,
                      cadastroProduto: {
                        idProduto: '',
                        idGrupo: '',
                        descricaoProduto: '',
                        descricaoProdutoDetalhe: '',
                        codigoProduto: '',
                        imagemProduto: '',
                        valorProduto: '',
                        impressaoProduto: '',
                        tempoPreparo: 0,
                        tipoProduto: '',
                        unidadeProduto: '',
                        ncmProduto: '',
                        cestProduto: '',
                        idTributacaoGrupo: '',
                        statusProduto: '',
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        idGrupo: false,
                        descricaoProduto: false,
                        valorProduto: false,
                        tipoProduto: false,                    
                      }
                  }
              case 'GRUPOs':
                return { ...state, GRUPOs: action.value }
              case 'GRUPOS_TODOs':
                return { ...state, GRUPOSTODOs: action.value }
              case 'NCMs':
                return { ...state, NCMs: action.value }
    
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




