//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaGrupoTributacao: {
      idEmpresa: '',
      idGrupoTributacao: '',
      descricaoGrupoTributacao: '',
      statusGrupoTributacao: '',
  },

  cadastroGrupoTributacao: {
    idEmpresa: '',
    idGrupoTributacao: '',
    descricaoGrupoTributacao: '',
    idCfop: '',
    idIcms: '',
    idIpi: '',
    idPis: '',
    idCofins: '',
    statusGrupoTributacao: '',
  },

  invalido: {
    descricaoGrupoTributacao: false,
    idCfop: false,
    idIcms: false,
    idIpi: false,
    idPis: false,
    idCofins: false,
  },

  GRUPOTRIBUTACAOs: [],
  inicioCadastro: false,
  cadastroGrupoTributacaoCopia: {},
  CFOPs: [],
  ICMSs: [],
  IPIs: [],
  PISs: [],
  COFINSs: [],
}

export default function GRUPOTRIBUTACAO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'GRUPOTRIBUTACAO':
          switch (action.type) {
            case 'PESQUISA':
                return { ...state, pesquisaGrupoTributacao: { ...state.pesquisaGrupoTributacao, [action.campo]: action.value } }
            case 'GRUPOS_TRIBUTACAO_TODOs':
                return { ...state, GRUPOTRIBUTACAOs: action.value }
            case 'CLEAN_SEARCH':
                return {
                    ...state,
                    pesquisaGrupoTributacao: {
                    idEmpresa: '',
                    idGrupo: '',
                    codigoProduto: '',
                    descricaoProduto: '',
                    statusProduto: '',                                    
                    },
                    PRODUTOs: []
                }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'GRUPO_TRIBUTACAO':
                return { ...state, cadastroGrupoTributacao: { ...state.cadastroGrupoTributacao, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'GRUPO_TRIBUTACAO_COPIA':
                return { ...state, cadastroGrupoTributacaoCopia: state.cadastroGrupoTributacao }
            case 'GRUPO_TRIBUTACAO_COPIA_STATUS':
                return { ...state, cadastroGrupoTributacaoCopia: { ...state.cadastroGrupoTributacao, status: action.value } }
            case 'CLEAN_GRUPO_TRIBUTACAO':
                return {
                    ...state,
                    cadastroGrupoTributacao: {
                    idEmpresa: '',
                    idGrupoTributacao: '',
                    descricaoGrupoTributacao: '',
                    idCfop: '',
                    idIcms: '',
                    idIpi: '',
                    idPis: '',
                    idCofins: '',
                    statusGrupoTributacao: '',                    
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                        descricaoGrupoTributacao: false,
                        idCfop: false,
                        idIcms: false,
                        idIpi: false,
                        idPis: false,
                        idCofins: false,
                    }
                }
            case 'CFOP_TODOs':
                return { ...state, CFOPs: action.value }
            case 'ICMS_TODOs':
                return { ...state, ICMSs: action.value }
            case 'IPI_TODOs':
                return { ...state, IPIs: action.value }
            case 'PIS_TODOs':
                return { ...state, PISs: action.value }
            case 'COFINS_TODOs':
                return { ...state, COFINSs: action.value }
                  
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




