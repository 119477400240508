import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"
import * as controllerCep from "~/controller/controllerCep"
import { Select } from "~/components/ErnComponets/ErnComponets";
import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
    FormGroup,
} from "reactstrap";

const ModalEndrecos = ({isOpen, toggleModalEndereco, pedidoCampos, shouldClean}) => {
    //const { PEDIDOs } = useSelector((state) => state.PedidoItem, shallowEqual);

    const { ENDERECOs } = useSelector((state) => state.Cliente, shallowEqual);
    const { pedidoEnderecoEntrega, PEDIDOENDERECOs, pesquisaPedidoEndereco, invalidoEnderecoEntrega } = useSelector((state) => state.PedidoItem, shallowEqual);
    const [pesquisaEndereco, setpesquisaEndereco] = useState({linhaSelecionada: '', idEndereco: ''})

    const [pesquisaCep, setpesquisaCep] = useState({cep: '', endereco: '', bairro: '', cidade: '', uf: '', numero: '', complemento: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ celular: '', nomeCliente: '' })
    const [enderecosSelected, setenderecosSelected] = useState({ idEndereco: 0 })
    const [enderecos, setEnderecos] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
      
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  

      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'idCliente', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: pedidoCampos.mesaComanda });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: '' });

      dispatch({ idStore, type: 'CLEAN_INVALIDO_ENDERECO_ENTREGA' });
      //setpesquisa({ ...pesquisa, celular: pedidoCampos.mesaComanda, nomeCliente: '' })

      handlePesquisar(pedidoCampos.mesaComanda);

      

    }, [])
    
    async function handlePesquisar(celular){
      setLoading(true);
      if (!loading) {
        await controllerCliente.GetClienteEnderecoCelular(notificacao, dispatch, celular, () => {
            // if (PEDIDOENDERECOs.length > 0){
            //   setpesquisa({ ...pesquisa, celular: celular, nomeCliente: PEDIDOENDERECOs[0].nomeCliente })
            //   document.getElementById("nomeCliente").value = PEDIDOENDERECOs[0].nomeCliente;
            //   document.getElementById("celular").value = celular;  
            // }
        });
        //await controllerPedidoVendas.GetMesasComandasDelivery(notificacao, dispatch, login.usuario, pesquisa, () => {
        //    document.getElementById("nomeCliente").focus();  
        //});
        
      }
      setLoading(false);
      document.getElementById("cep").focus();
    }


    //  useEffect(() => {
    //    if (pedidoCampos !== ''){
    //      setpesquisa({ ...pesquisa, celular: pedidoCampos.mesaComanda })
    //      handlePesquisar(pesquisa.celular);
    //    }
    //   }, [pedidoCampos])

    useEffect(() => {
        setEnderecos(validaList(PEDIDOENDERECOs))
    }, [PEDIDOENDERECOs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  
        }
    }, [shouldClean])

    function handleOnChangePesquisa(value, campo) {
      let idStore = 'PEDIDO'

      switch (campo) {
        case "celular":
          dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: value });          
          break;
        case "nomeCliente":
          dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: value });
          break;
        default:
          break;
      }  
    }

    function handleOnChange(value, campo) {
      let idStore = 'PEDIDO'
        dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: campo, value: value });          
    }


    function handleVoltar(){
      toggleModalEndereco();
    }

    function handleOk(){
      let idStore = 'PEDIDO'

      //Armazena Informações do Cliente
      if (pedidoEnderecoEntrega.idCliente == ''){
        //Verifica Campos
        if (pesquisaPedidoEndereco.celular === '') {
          dispatch({ idStore, type: 'INVALIDO_ENDERECO_ENTREGA', campo: 'celular', value: true })
          return true;
        }

        if (pesquisaPedidoEndereco.nomeCliente === '') {
          dispatch({ idStore, type: 'INVALIDO_ENDERECO_ENTREGA', campo: 'nomeCliente', value: true })
          return true;
        }

        dispatch({ idStore, type:'PEDIDO', campo: 'idCliente', value: '0' });
        dispatch({ idStore, type:'PEDIDO', campo: 'nomeCliente', value: pesquisaPedidoEndereco.nomeCliente });
      }else{
        dispatch({ idStore, type:'PEDIDO', campo: 'idCliente', value: pedidoEnderecoEntrega.idCliente });  
        dispatch({ idStore, type:'PEDIDO', campo: 'nomeCliente', value: pedidoEnderecoEntrega.nomeEntrega });
      }
      dispatch({ idStore, type:'PEDIDO', campo: 'tipoVenda', value: 'E' });

      idStore = 'PEDIDO_PESQ_CLIENTE'
      let type = 'CLIENTE';
      if (pedidoEnderecoEntrega.idCliente == ''){
        dispatch({ idStore, type, campo: 'idCliente', value: '0' })
        dispatch({ idStore, type, campo: 'nomeCliente', value: pesquisaPedidoEndereco.nomeCliente })  
      }else{
        dispatch({ idStore, type, campo: 'idCliente', value: pedidoEnderecoEntrega.idCliente })
        dispatch({ idStore, type, campo: 'nomeCliente', value: pedidoEnderecoEntrega.nomeEntrega })  
      }  
      dispatch({ idStore, type, campo: 'celularCliente', value: pesquisaPedidoEndereco.celular })

      toggleModalEndereco();
    }

  function onClick(rowIndex) {
    let enderecoSelected = PEDIDOENDERECOs[rowIndex]

    setpesquisaEndereco({linhaSelecionada: rowIndex, idEndereco: enderecoSelected.idEndereco })
    setpesquisa({ ...pesquisa, idEndereco: enderecoSelected.idEndereco })

    //Carrega Endereço Selecionado nos Campos
    setpesquisaCep({ ...pesquisaCep, 
      cep: enderecoSelected.cep, 
      endereco: enderecoSelected.endereco,
      numero: enderecoSelected.numero,
      bairro: enderecoSelected.bairro,
      cidade: enderecoSelected.cidade,
      uf: enderecoSelected.uf,
      complemento: enderecoSelected.complemento 
    })
    
  }

  function onDoubleClick(rowIndex) {
    let enderecoSelected = PEDIDOENDERECOs[rowIndex]
    setpesquisaEndereco({linhaSelecionada: rowIndex, idEndereco: enderecoSelected.idEndereco })

    handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: PEDIDOENDERECOs[row.index].idEndereco === pesquisaEndereco.idEndereco ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

  function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "cep":
          //let idStore = 'PEDIDO';  
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idEndereco', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idCliente', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'nomeEntrega', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cep', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'endereco', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'bairro', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cidade', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'uf', value: '' });   
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'numero', value: '' });
          // dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'complemento', value: '' });   
          controllerCep.GetCep(notificacao, dispatch, pedidoEnderecoEntrega.cep, 'PEDIDO', 'PEDIDO_ENDERECO_ENTREGA');
          document.getElementById('numero').focus();
          break;
        case "celular":
          handlePesquisar(pesquisa.celular);
          break;
        case "numero":
          document.getElementById('bairro').focus();
          break;  
        case "bairro":
          document.getElementById('cidade').focus();
          break;    
        case "cidade":
          document.getElementById('uf').focus();
          break;      
        case "uf":
          document.getElementById('complemento').focus();
          break;        
        default:
          break;
      }
    }
  }


    // function handleKeyPress(event, value) {
    //     if (event.key === 'Enter') {
    //         handlePesquisar(pesquisa.celular);
    //     }
    // }

    function handleLimpar() {
      let idStore = 'PEDIDO'
      //dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  

      dispatch({ idStore, type: "CLEAN_PEDIDO_ENDERECO" });

      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'idCliente', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: '' });

        //let idStore = 'PEDIDO';     
        //dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })         

        //setpesquisa({ ...pesquisa, celular: '', nomeCliente: '' })
      setenderecosSelected({ ...enderecosSelected, idEndereco: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idEndereco === item.idEndereco)) {
                arr.push(value)                
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalEnderecos"
            isOpen={isOpen}
            toggle={() => toggleModalEndereco()}
            title={'Endereço de Entrega do Cliente'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">

                  <Col md="3" className="padding-padrao-col">
                      <label>Número Celular</label>
                      <FormGroup className={invalidoEnderecoEntrega.celular ? "has-danger" : ''}>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='celular'
                          type='text'
                          autoComplete='off'
                          value={pesquisaPedidoEndereco.celular}
                          onChange={(e) => handleOnChangePesquisa(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                      </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Cliente</label>
                      <FormGroup className={invalidoEnderecoEntrega.nomeCliente ? "has-danger" : ''}>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='nomeCliente'
                          type='text'
                          autoComplete='off'
                          value={pesquisaPedidoEndereco.nomeCliente}
                           onChange={(e) => handleOnChangePesquisa(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}

                          maxLength={'30'}
                      />
                      </FormGroup>
                  </Col>

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar(pesquisa.celular)}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
                  <legend>Endereço de Entrega Selecionado</legend>
                    <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

                      <Col className="padding-padrao-col" lg="3" md="3">
                        <label>CEP</label>
                        {"  "}<a
                          target="_blank"
                          style={{ fontSize:"10px", color: "#d6a956" }}
                        >
                          *ENTER para pesquisar
                        </a>
                          <Input
                            id="cep"
                            type="text"
                            value={pedidoEnderecoEntrega.cep}
                            onKeyPress={(e) => handleKeyPress(e, "cep")}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            autoComplete="off"
                            maxLength="10"
                          />
                          <br></br>
                      </Col>

                      <Col className="padding-padrao-col" lg="7" md="3">
                        <label>Endereço</label>
                          <Input
                            id="endereco"
                            type="text"
                            value={pedidoEnderecoEntrega.endereco}
                            //onChange={e => onChange(e.target.value, 'nome')}
                            onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="150"
                          />
                          <br></br>
                      </Col>
                      <Col className="padding-padrao-col" lg="2" md="3">
                      <label>Número</label>
                        <Input
                          id="numero"
                          type="text"
                          value={pedidoEnderecoEntrega.numero}
                          //onChange={e => onChange(e.target.value, 'nome')}
                          onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }    
                          onFocus={e => e.target.select()}
                          autoComplete="off"
                          maxLength="10"
                        />
                        <br></br>
                    </Col>

                    </Row>
                    <Row style={{ marginTop: '-25px', marginLeft: "25px", marginRight: "25px" }}>
                      <Col className="padding-padrao-col" lg="4" md="3">
                          <label>Bairro</label>
                          <Input
                            id="bairro"
                            type="text"
                            value={pedidoEnderecoEntrega.bairro}
                            onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            autoComplete="off"
                            maxLength="100"
                          />
                      </Col>

                      <Col className="padding-padrao-col" lg="4" md="3">
                        <label>Cidade</label>
                          <Input
                            id="cidade"
                            type="text"
                            value={pedidoEnderecoEntrega.cidade}
                            onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            autoComplete="off"
                            maxLength="100"
                          />
                      </Col>

                      <Col className="padding-padrao-col" lg="1" md="3">
                        <label>Estado</label>
                          <Input
                            id="uf"
                            type="text"
                            value={pedidoEnderecoEntrega.uf}
                            //onChange={e => onChange(e.target.value, 'nome')}
                            onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="2"
                          />
                      </Col>
                      <Col className="padding-padrao-col" lg="3" md="3">
                            <label>Complemento</label>
                          <Input
                            id="complemento"
                            type="text"
                            value={pedidoEnderecoEntrega.complemento}
                            //onChange={e => onChange(e.target.value, 'nome')}
                            onKeyPress={(e) => handleKeyPress(e, e.target.id)}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="50"
                          />
                          <br></br>
                      </Col>
                      
                    </Row>
                </fieldset>


                {/* <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Endereços Cadastrados</legend>
                       <div
                        className='scrollBar'
                        style={PEDIDOENDERECOs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 150 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={PEDIDOENDERECOs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'id',
                                    accessor: 'idEndereco',
                                    minWidth: 20,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'CEP',
                                    accessor: 'cep',
                                    minWidth: 20,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                  Header: 'Endereço',
                                  accessor: 'endereco',
                                  minWidth: 40,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Bairro',
                                  accessor: 'bairro',
                                  minWidth: 40,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Cidade',
                                  accessor: 'cidade',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false
                                },
                              ]}
                                
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={PEDIDOENDERECOs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${PEDIDOENDERECOs.length} Enereços`}
                  </div>
                </Col> */}
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center" style={{ marginTop: '-35px' }}>
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalEndrecos