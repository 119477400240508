
import axios from 'axios';
import api from "../Util/api";
import {maskValor, maskMoeda} from "~/Util/mascaras";

const idStore = 'DASHBOARD';


export function Pesquisar(dispatch, user) {

    try {


      /*---------------------------------------------------------------------------
       * 
      ---------------------------------------------------------------------------*/
        //const login = useSelector((state) => state.login);
        var usuarioStorage = localStorage.getItem('USUARIO');
        let usuario = JSON.parse(usuarioStorage) //Converte JSON em Objeto
        var idEmpresa = '';

        if (usuario.empresa === "T") {
            idEmpresa = "1";
        }else{
            idEmpresa = usuario.empresa;
        }

/*         var labelGrafic = [];
        let dataGrafic = [];

        var labelGraficSemana = [];
        let dataGraficSemana = [];

        var labelGraficDiario = [];
        let dataGraficDiario = [];

        var labelGraficUserPerfil = [];
        let dataGraficUserPerfil = [];

        var dataInicioIntegracao = [];
        var dataFinalIntegracao = [];
        var statusIntegra = [];

        var dataErroIntegracao = [];
        var descricaoErroIntegracao = [];
        var statusErroIntegracao = [];
        var toolTip = [];


        var labelGraficCurso = [];
        let dataGraficCurso = [];

        var labelGraficDepartamento = [];
        let dataGraficDepartamento = [];
 */
        var ulrPesquisa = '';
        //ulrPesquisa = api.urlIntegradorApi + api.urlGraficoDashboard + `${idEmpresa}` + `/${2024}`;
        ulrPesquisa = api.urlBaseChefe + api.urlGraficoDashboard + `${idEmpresa}` + `/${2024}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };        
        axios.request(config)
        .then((response) => {
            var graficos = response.data.data;
            carregarDadosDashboard(dispatch, graficos);

/*             var departamentos = 0;
            var perfisCadastrados = 0;
            var usuariosAtivos = 0;
            var errosDia = 0;
            var empresaNome = '';
            var idEmp = 0;

            if (graficos.graficoMes.length > 0){
                idEmp = graficos.graficoMes[0].idEmpresa;                 
            }

            departamentos = graficos.departamentos;
            perfisCadastrados = graficos.perfis;
            usuariosAtivos = graficos.usuariosAtivo;
            errosDia = graficos.erros;
            empresaNome = graficos.empresaNome;

            //let graficoObj = JSON.parse(graficos) //Converte JSON em Objeto
            //Grafico Mensal
            for (var i = 0; i < 12; i++) {
                labelGrafic.push(retornarMes(graficos.graficoMes[i].mes))
                dataGrafic.push(graficos.graficoMes[i].quantidade)
            }

            //Grafico Semanal
            var totalSemanas = graficos.graficoSemana.length;
            for (var i = 0; i < totalSemanas; i++) {
                labelGraficSemana.push(graficos.graficoSemana[i].primeiroDiaSemana)
                dataGraficSemana.push(graficos.graficoSemana[i].quantidade)
            }

            //Grafico Diario
            var totalDias = graficos.graficoDiario.length;
            for (var i = 0; i < totalDias; i++) {
                labelGraficDiario.push(graficos.graficoDiario[i].data)
                dataGraficDiario.push(graficos.graficoDiario[i].quantidade)
            }
            
            //Grafico Perfil de Usuários
            var totalUserPerfil = graficos.graficoMesUsuarioPerfil.length;
            var qtdUserPerfil = 0;
            for (var i = 0; i < totalUserPerfil; i++) {
                labelGraficUserPerfil.push(retornarMes(graficos.graficoMesUsuarioPerfil[i].mes))
                dataGraficUserPerfil.push(graficos.graficoMesUsuarioPerfil[i].quantidade)
                qtdUserPerfil += graficos.graficoMesUsuarioPerfil[i].quantidade;
            }

            //Grafico Mensal Cursos
            var qtdCursos = 0;
            for (var i = 0; i < 12; i++) {
                labelGraficCurso.push(retornarMes(graficos.graficoMesCursos[i].mes))
                dataGraficCurso.push(graficos.graficoMesCursos[i].quantidade)
                qtdCursos += graficos.graficoMesCursos[i].quantidade;
            }
            
            //Grafico Mensal Departamentos
            var qtdDepartamentos = 0;
            for (var i = 0; i < 12; i++) {
                labelGraficDepartamento.push(retornarMes(graficos.graficoMesDepartamentos[i].mes))
                dataGraficDepartamento.push(graficos.graficoMesDepartamentos[i].quantidade)
                qtdDepartamentos += graficos.graficoMesDepartamentos[i].quantidade;
            }

            //Table Ultimas Integrações
            var totalIntegracoes = graficos.ultimasIntegracoes.length;
            for (var i = 0; i < totalIntegracoes; i++) {
                dataInicioIntegracao.push(graficos.ultimasIntegracoes[i].dataHoraIntegracao)
                
                if (graficos.ultimasIntegracoes[i].statusIntegracao === "OK"){
                    let texto = graficos.ultimasIntegracoes[i].descricao;
                    let dataFinal = texto.substring(16, 35)
                    dataFinalIntegracao.push(dataFinal)
                }else{
                    let texto = graficos.ultimasIntegracoes[i].descricao;
                    let dataFinal = texto.substring(0, 25)
                    dataFinalIntegracao.push(dataFinal)
                }
                statusIntegra.push(graficos.ultimasIntegracoes[i].statusIntegracao)
            }

            
            //Table Erros do Dia
            var totalErros = graficos.ultimosErros.length;
            for (var i = 0; i < totalErros; i++) {
                dataErroIntegracao.push(graficos.ultimosErros[i].dataHoraIntegracao)
                
                let texto = graficos.ultimosErros[i].descricao;
                let descricaoErro = '';
                if (texto.length > 40){
                    descricaoErro = texto.substring(0, 40)
                }else{
                    descricaoErro = texto;
                }
                descricaoErroIntegracao.push(descricaoErro)
                statusErroIntegracao.push(graficos.ultimosErros[i].statusIntegracao)
                toolTip.push(texto);
            }


            const mensal = {
                labels: labelGrafic,
                dados: dataGrafic,
            }
    

            const semanal = {
                labels: labelGraficSemana,
                dados: dataGraficSemana,
            }

            const diario = {
                labels: labelGraficDiario,
                dados: dataGraficDiario,
            }

            const usuarioPerfil = {
                labels: labelGraficUserPerfil,
                dados: dataGraficUserPerfil,
            }

            const cursoMes = {
                labels: labelGraficCurso,
                dados: dataGraficCurso,
            }

            const departamentoMes = {
                labels: labelGraficDepartamento,
                dados: dataGraficDepartamento,
            }

            const ultimasIntegracoes  = {
                idEmpresa: idEmp,
                dataInicio: dataInicioIntegracao,
                dataFinal: dataFinalIntegracao,
                statusIntegracao: statusIntegra,
            }

            const ultimosErros = {
                dataErro: dataErroIntegracao,
                descricaoErro: descricaoErroIntegracao,
                statusErro: statusErroIntegracao,
                toolTipErro: toolTip,
            }


            dispatch({ idStore, type: 'CHART1', value: JSON.stringify(mensal) })

            dispatch({ idStore, type: 'CHART_SEMANAL', value: JSON.stringify(semanal) })
            
            dispatch({ idStore, type: 'CHART_DIARIO', value: JSON.stringify(diario) })

            dispatch({ idStore, type: 'CHART_USUARIO_PERFIL', value: JSON.stringify(usuarioPerfil) })

            dispatch({ idStore, type: 'QTD_USUARIO_PERFIL', value: qtdUserPerfil })

            dispatch({ idStore, type: 'CHART_CURSOS', value: JSON.stringify(cursoMes) })

            dispatch({ idStore, type: 'QTD_CURSOS', value: qtdCursos })

            dispatch({ idStore, type: 'CHART_DEPARTAMENTOS', value: JSON.stringify(departamentoMes) })

            dispatch({ idStore, type: 'QTD_DEPARTAMENTOS_INTEGRADOS', value: qtdDepartamentos })
            
            dispatch({ idStore, type: 'DEPARTAMENTOS', value: departamentos })

            dispatch({ idStore, type: 'PERFIS', value: perfisCadastrados })

            dispatch({ idStore, type: 'USUARIOS_ATIVOS', value: usuariosAtivos })

            dispatch({ idStore, type: 'ERROS', value: errosDia })

            dispatch({ idStore, type: 'EMPRESA_NOME', value: empresaNome })

            dispatch({ idStore, type: 'ULTIMAS_INTEGRACOES', value: JSON.stringify(ultimasIntegracoes) })

            dispatch({ idStore, type: 'ULTIMOS_ERROS', value: JSON.stringify(ultimosErros) })
 */            
           // return mensal
        })
/*         .then((response) => {
        //moment.locale('pt-br')
        let labelGrafic: string[] = []
        let dataGrafic: number[] = []
        response.data.data.map((dados: any) => {
            labelGrafic.push(dados.periodo)
            dataGrafic.push(dados.valor)
        }) */

        
        //})
    } catch (e) {
        console.log(e) 
        //alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}

function carregarDadosDashboard(dispatch, graficos){
    try {

        var labelGrafic = [];
        let dataGrafic = [];

        var labelGraficSemana = [];
        let dataGraficSemana = [];

        var labelGraficDiario = [];
        let dataGraficDiario = [];

        var labelGraficUserPerfil = [];
        let dataGraficUserPerfil = [];

        var dataInicioIntegracao = [];
        var dataFinalIntegracao = [];
        var statusIntegra = [];

        var dataErroIntegracao = [];
        var descricaoErroIntegracao = [];
        var statusErroIntegracao = [];
        var toolTip = [];


        var labelGraficCurso = [];
        let dataGraficCurso = [];

        var labelGraficDepartamento = [];
        let dataGraficDepartamento = [];


        var departamentos = 0;
        var perfisCadastrados = 0;
        var usuariosAtivos = 0;
        var errosDia = 0;
        var empresaNome = '';
        var idEmp = 0;

        if (graficos.graficoMes.length > 0){
            idEmp = graficos.graficoMes[0].idEmpresa;                 
        }

        departamentos = graficos.departamentos;
        perfisCadastrados = graficos.perfis;
        usuariosAtivos = graficos.usuariosAtivo;
        errosDia = graficos.erros;
        empresaNome = graficos.empresaNome;

        //let graficoObj = JSON.parse(graficos) //Converte JSON em Objeto
        //Grafico Mensal
        for (var i = 0; i < 12; i++) {
            labelGrafic.push(retornarMes(graficos.graficoMes[i].mes))
            dataGrafic.push(graficos.graficoMes[i].quantidade)
        }

        //Grafico Semanal
        var totalSemanas = graficos.graficoSemana.length;
        for (var i = 0; i < totalSemanas; i++) {
            labelGraficSemana.push(graficos.graficoSemana[i].primeiroDiaSemana)
            dataGraficSemana.push(graficos.graficoSemana[i].quantidade)
        }

        //Grafico Diario
        var totalDias = graficos.graficoDiario.length;
        for (var i = 0; i < totalDias; i++) {
            labelGraficDiario.push(graficos.graficoDiario[i].data)
            dataGraficDiario.push(graficos.graficoDiario[i].quantidade)
        }
        
        //Grafico Perfil de Usuários
        var totalUserPerfil = graficos.graficoMesUsuarioPerfil.length;
        var qtdUserPerfil = 0;
        for (var i = 0; i < totalUserPerfil; i++) {
            labelGraficUserPerfil.push(retornarMes(graficos.graficoMesUsuarioPerfil[i].mes))
            dataGraficUserPerfil.push(graficos.graficoMesUsuarioPerfil[i].quantidade)
            qtdUserPerfil += graficos.graficoMesUsuarioPerfil[i].quantidade;
        }

        //Grafico Mensal Cursos
        var qtdCursos = 0;
        for (var i = 0; i < 12; i++) {
            labelGraficCurso.push(retornarMes(graficos.graficoMesCursos[i].mes))
            dataGraficCurso.push(graficos.graficoMesCursos[i].quantidade)
            qtdCursos += graficos.graficoMesCursos[i].quantidade;
        }
        
        //Grafico Mensal Departamentos
        var qtdDepartamentos = 0;
        for (var i = 0; i < 12; i++) {
            labelGraficDepartamento.push(retornarMes(graficos.graficoMesDepartamentos[i].mes))
            dataGraficDepartamento.push(graficos.graficoMesDepartamentos[i].quantidade)
            qtdDepartamentos += graficos.graficoMesDepartamentos[i].quantidade;
        }

        //Table Ultimas Integrações
        var totalIntegracoes = graficos.ultimasIntegracoes.length;
        for (var i = 0; i < totalIntegracoes; i++) {
            dataInicioIntegracao.push(graficos.ultimasIntegracoes[i].dataHoraIntegracao)
            
            if (graficos.ultimasIntegracoes[i].statusIntegracao === "OK"){
                let texto = graficos.ultimasIntegracoes[i].descricao;
                let dataFinal = texto.substring(16, 35)
                dataFinalIntegracao.push(dataFinal)
            }else{
                let texto = graficos.ultimasIntegracoes[i].descricao;
                let dataFinal = texto.substring(0, 25)
                dataFinalIntegracao.push(dataFinal)
            }
            statusIntegra.push(graficos.ultimasIntegracoes[i].statusIntegracao)
        }

        
        //Table Erros do Dia
        var totalErros = graficos.ultimosErros.length;
        for (var i = 0; i < totalErros; i++) {
            dataErroIntegracao.push(graficos.ultimosErros[i].dataHoraIntegracao)
            
            let texto = graficos.ultimosErros[i].descricao;
            let descricaoErro = '';
            if (texto.length > 40){
                descricaoErro = texto.substring(0, 40)
            }else{
                descricaoErro = texto;
            }
            descricaoErroIntegracao.push(descricaoErro)
            statusErroIntegracao.push(graficos.ultimosErros[i].statusIntegracao)
            toolTip.push(texto);
        }


        const mensal = {
            labels: labelGrafic,
            dados: dataGrafic,
        }


        const semanal = {
            labels: labelGraficSemana,
            dados: dataGraficSemana,
        }

        const diario = {
            labels: labelGraficDiario,
            dados: dataGraficDiario,
        }

        const usuarioPerfil = {
            labels: labelGraficUserPerfil,
            dados: dataGraficUserPerfil,
        }

        const cursoMes = {
            labels: labelGraficCurso,
            dados: dataGraficCurso,
        }

        const departamentoMes = {
            labels: labelGraficDepartamento,
            dados: dataGraficDepartamento,
        }

        const ultimasIntegracoes  = {
            idEmpresa: idEmp,
            dataInicio: dataInicioIntegracao,
            dataFinal: dataFinalIntegracao,
            statusIntegracao: statusIntegra,
        }

        const ultimosErros = {
            dataErro: dataErroIntegracao,
            descricaoErro: descricaoErroIntegracao,
            statusErro: statusErroIntegracao,
            toolTipErro: toolTip,
        }


        dispatch({ idStore, type: 'CHART1', value: JSON.stringify(mensal) })

        dispatch({ idStore, type: 'CHART_SEMANAL', value: JSON.stringify(semanal) })
        
        dispatch({ idStore, type: 'CHART_DIARIO', value: JSON.stringify(diario) })

        dispatch({ idStore, type: 'CHART_USUARIO_PERFIL', value: JSON.stringify(usuarioPerfil) })

        dispatch({ idStore, type: 'QTD_USUARIO_PERFIL', value: qtdUserPerfil })

        dispatch({ idStore, type: 'CHART_CURSOS', value: JSON.stringify(cursoMes) })

        dispatch({ idStore, type: 'QTD_CURSOS', value: qtdCursos })

        dispatch({ idStore, type: 'CHART_DEPARTAMENTOS', value: JSON.stringify(departamentoMes) })

        dispatch({ idStore, type: 'QTD_DEPARTAMENTOS_INTEGRADOS', value: qtdDepartamentos })
        
        dispatch({ idStore, type: 'DEPARTAMENTOS', value: departamentos })

        dispatch({ idStore, type: 'PERFIS', value: perfisCadastrados })

        dispatch({ idStore, type: 'USUARIOS_ATIVOS', value: usuariosAtivos })

        dispatch({ idStore, type: 'ERROS', value: errosDia })

        dispatch({ idStore, type: 'EMPRESA_NOME', value: empresaNome })

        dispatch({ idStore, type: 'ULTIMAS_INTEGRACOES', value: JSON.stringify(ultimasIntegracoes) })

        dispatch({ idStore, type: 'ULTIMOS_ERROS', value: JSON.stringify(ultimosErros) })

        
    } catch (error) {
        console.log(error)
    }
}

function retornarMes(numeroMes){
    switch (numeroMes) {
        case 1:
            return "JAN"
        case 2:
            return "FEV"
        case 3:
            return "MAR"
        case 4:
            return "ABR"
        case 5:
            return "MAI"
        case 6:
            return "JUN"
        case 7:
            return "JUL"
        case 8:
            return "AGO"
        case 9:
            return "SET"
        case 10:
            return "OUT"
        case 11:
            return "NOV"
        case 12:
            return "DEZ"
        default:
            break;
    }
}

export function carregarDadosDashboardPrincipal(dispatch, graficos, graficosProdutos, empresaDescricao){
    try {

        var labelGrafic = [];
        let dataGrafic = [];

        var labelGraficSemana = [];
        let dataGraficSemana = [];

        var labelGraficDiario = [];
        let dataGraficDiario = [];

        var labelGraficVendidosAno = [];
        let dataGraficVendidosAno = [];

        var labelGraficVendidosMes = [];
        let dataGraficVendidosMes = [];

        var labelGraficVendidosTipoBalcao = [];
        var labelGraficVendidosTipoEntrega = [];
        let dataGraficVendidosTipoBalcao = [];
        let dataGraficVendidosTipoEntrega = [];


        var dataInicioIntegracao = [];
        var dataFinalIntegracao = [];
        var statusIntegra = [];

        var dataErroIntegracao = [];
        var descricaoErroIntegracao = [];
        var statusErroIntegracao = [];
        var toolTip = [];


        var labelGraficCurso = [];
        let dataGraficCurso = [];

        var labelGraficDepartamento = [];
        let dataGraficDepartamento = [];


        // var departamentos = 0;
        // var perfisCadastrados = 0;
        // var usuariosAtivos = 0;
        // var errosDia = 0;
        var empresaNome = '';
        var idEmp = 0;

        //departamentos = graficos.departamentos;
        //perfisCadastrados = graficos.perfis;
        //usuariosAtivos = graficos.usuariosAtivo;
        //errosDia = graficos.erros;
        empresaNome = empresaDescricao;

        //let graficoObj = JSON.parse(graficos) //Converte JSON em Objeto
        //Grafico Mensal
        for (var i = 0; i < 12; i++) {
            labelGrafic.push(retornarMes(graficos.graficoPedidoMes[i].mesVenda))
            dataGrafic.push(graficos.graficoPedidoMes[i].valorTotal)
            //dataGrafic.push(graficos.graficoPedidoMes[i].quantidade)
        }

        //Grafico Semanal
        var totalSemanas = graficos.graficoPedidoSemana.length;
        for (var i = 0; i < totalSemanas; i++) {
            labelGraficSemana.push(graficos.graficoPedidoSemana[i].primeiroDiaSemana)
            dataGraficSemana.push(graficos.graficoPedidoSemana[i].valorTotal)
            //dataGraficSemana.push(graficos.graficoPedidoSemana[i].quantidade)
        }

        //Grafico Diario
        var totalDias = graficos.graficoPedidoDia.length;
        for (var i = 0; i < totalDias; i++) {
            labelGraficDiario.push(graficos.graficoPedidoDia[i].diaVenda)
            dataGraficDiario.push(graficos.graficoPedidoDia[i].valorTotal)
            //dataGraficDiario.push(graficos.graficoPedidoDia[i].quantidade)
        }

        
         //Grafico Produtos Mais Vendidos no Ano (Mostra 5)
        var totalVendidosAno = graficosProdutos.graficoProdutoAno.length;
        var qtdVendidosAno = 0;
        var qtdAno = 0;
        for (var i = 0; i < totalVendidosAno; i++) {
            labelGraficVendidosAno.push(graficosProdutos.graficoProdutoAno[i].descricao)
            dataGraficVendidosAno.push(graficosProdutos.graficoProdutoAno[i].quantidadeTotal)
            //dataGraficVendidosAno.push(graficosProdutos.graficoProdutoAno[i].valorTotal)
            qtdVendidosAno += graficosProdutos.graficoProdutoAno[i].quantidadeTotal;
            qtdAno += 1;
            if (qtdAno === 5){
                break
            }
        }

        /*-------------------------------------------------------------------------
         * Grafico Mais Vendidos no mês (Verifica qual mês é para ser mostratdo)
         * (Mostra 5)
         *-------------------------------------------------------------------------*/
        const date = new Date();
        const mesInformado = date.getMonth() + 1; 
        var totalVendidosMes = graficosProdutos.graficoProdutoMes.length;
        var qtdVendidosMes = 0;
        var qtdMes = 0;
        for (var i = 0; i < totalVendidosMes; i++) {
            if (mesInformado === graficosProdutos.graficoProdutoMes[i].mesVenda){
                labelGraficVendidosMes.push(graficosProdutos.graficoProdutoMes[i].descricao)
                dataGraficVendidosMes.push(graficosProdutos.graficoProdutoMes[i].quantidadeTotal)
                //dataGraficVendidosMes.push(graficosProdutos.graficoProdutoMes[i].valorTotal)
                qtdVendidosMes += graficosProdutos.graficoProdutoMes[i].quantidadeTotal;    
                qtdMes += 1;
                if (qtdMes === 5){
                    break
                }    
            }
        }


        /*-------------------------------------------------------------------------
         * Grafico Vendas Balcao e Entrega 
         *-------------------------------------------------------------------------*/
        var totalVendidos = graficos.graficoPedidoTipoVenda.length;
        var qtdVendidosBalcao = 0;
        var qtdVendidosEntrega = 0;
        var valorTotalBalcao = 0;
        var valorTotalEntrega = 0;
        for (var i = 0; i < totalVendidos; i++) {
            if (graficos.graficoPedidoTipoVenda[i].tipoVenda === 'B'){
                labelGraficVendidosTipoBalcao.push(retornarMes(graficos.graficoPedidoTipoVenda[i].mes))
                dataGraficVendidosTipoBalcao.push(graficos.graficoPedidoTipoVenda[i].valorTotal)    
                valorTotalBalcao += graficos.graficoPedidoTipoVenda[i].valorTotal;
                //qtdVendidosBalcao += graficos.graficoPedidoTipoVenda[i].quantidadeTotal;    
            }else {
                labelGraficVendidosTipoEntrega.push(retornarMes(graficos.graficoPedidoTipoVenda[i].mes))
                dataGraficVendidosTipoEntrega.push(graficos.graficoPedidoTipoVenda[i].valorTotal)                    
                valorTotalEntrega += graficos.graficoPedidoTipoVenda[i].valorTotal;
                //qtdVendidosEntrega += graficos.graficoPedidoTipoVenda[i].quantidadeTotal;    
            }
        }


        const mensal = {
            labels: labelGrafic,
            dados: dataGrafic,
        }


        const semanal = {
            labels: labelGraficSemana,
            dados: dataGraficSemana,
        }

        const diario = {
            labels: labelGraficDiario,
            dados: dataGraficDiario,
        }

         const produtosVendidosAno = {
            labels: labelGraficVendidosAno,
            dados: dataGraficVendidosAno,
        }

        const produtosVendidosMes = {
            labels: labelGraficVendidosMes,
            dados: dataGraficVendidosMes,
        }

        const produtosVendidosBalcao = {
            labels: labelGraficVendidosTipoBalcao,
            dados: dataGraficVendidosTipoBalcao,
        }

        const produtosVendidosEntrega = {
            labels: labelGraficVendidosTipoEntrega,
            dados: dataGraficVendidosTipoEntrega,
        }

        dispatch({ idStore, type: 'CHART1', value: JSON.stringify(mensal) })

        dispatch({ idStore, type: 'CHART_SEMANAL', value: JSON.stringify(semanal) })
        
        dispatch({ idStore, type: 'CHART_DIARIO', value: JSON.stringify(diario) })

         dispatch({ idStore, type: 'CHART_VENDIDOS_ANO', value: JSON.stringify(produtosVendidosAno) })

        dispatch({ idStore, type: 'QTD_VENDIDOS_ANO', value: qtdVendidosAno })

        dispatch({ idStore, type: 'CHART_VENDIDOS_MES', value: JSON.stringify(produtosVendidosMes) })

        dispatch({ idStore, type: 'QTD_VENDIDOS_MES', value: qtdVendidosMes })

        dispatch({ idStore, type: 'CHART_VENDIDOS_BALCAO', value: JSON.stringify(produtosVendidosBalcao) })

        dispatch({ idStore, type: 'CHART_VENDIDOS_ENTREGA', value: JSON.stringify(produtosVendidosEntrega) })

        dispatch({ idStore, type: 'VALOR_TOTAL_BALCAO', value: valorTotalBalcao })

        dispatch({ idStore, type: 'VALOR_TOTAL_ENTREGA', value: valorTotalEntrega })

        dispatch({ idStore, type: 'EMPRESA_NOME', value: empresaNome })
        
    } catch (error) {
        console.log(error)
    }
}