//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaOperacao: {
      idOperacao: '',
      tipoOperacao: '',
      descricao: '',
      statusOperacao: '',
  },

  cadastroOperacao: {
    idOperacao: '',
    tipoOperacao: '',
    descricao: '',
    statusOperacao: '',
  },  

  invalido: {
    descricao: false,
  },

  OPERACOEs: [],
  inicioCadastro: false,
  OperacaoCopia: {},
}

export default function OPERACAO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'OPERACAO':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaOperacao: { ...state.pesquisaOperacao, [action.campo]: action.value } }
              case 'OPERACOEs':
                  return { ...state, OPERACOEs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaOperacao: {
                        idOperacao: '',
                        tipoOperacao: '',
                        descricao: '',
                        statusOperacao: '',                  
                      },
                      OPERACOEs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'OPERACAO':
                  return { ...state, cadastroOperacao: { ...state.cadastroOperacao, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'OPERACAO_COPIA':
                  return { ...state, OperacaoCopia: state.cadastroOperacao }
              case 'OPERACAO_COPIA_STATUS':
                  return { ...state, OperacaoCopia: { ...state.cadastroOperacao, status: action.value } }
              case 'CLEAN_OPERACAO':
                  return {
                      ...state,
                      cadastroOperacao: {
                        idOperacao: '',
                        tipoOperacao: '',
                        descricao: '',
                        statusOperacao: '',                                      
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        descricao: false,                                        
                      }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




