import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'OPERACAO';


export function Pesquisar(notificacao, dispatch, tipoOperacao, idEmpresa) {

    try {

      var ulrPesquisa = api.urlBaseChefe + api.urlOperacoesGet + `${idEmpresa}` + `/${tipoOperacao}`;

      dispatch({ idStore, type: 'OPERACOEs', value: [] })  

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': bearerAuth
        },    
      };
      
      axios.request(config)
      .then((response) => {
        
        if (response.data.data.length === 0) {
            alertar(notificacao, 'br', "Nenhuma Operação encontrada", 'info', 'icon-bulb-63', 2)
        }
        //var clientes = response.data.data;  
        let idStore = 'OPERACAO'
        dispatch({ idStore, type: 'OPERACOEs', value: response.data.data });

      })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
