import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";


export async function GetCep(notificacao, dispatch, cepInformado, idstroe, type){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlBaseChefe + api.urlPesquisarCEP + `${cepInformado}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        axios.request(config)
        .then((response) => {
        if (response.data.message === 'Sucesso') {
          /*-----------------------------------------------------------------------------
          * Carrega dados de CEP
          *-----------------------------------------------------------------------------*/  
         let idStore = idstroe;
          var cep = response.data.data;  
          dispatch({ idStore, type: type, campo: 'cep', value: cepInformado })
          dispatch({ idStore, type: type, campo: 'endereco', value: cep.endereco })
          dispatch({ idStore, type: type, campo: 'bairro', value: cep.bairro })
          dispatch({ idStore, type: type, campo: 'cidade', value: cep.cidade })
          dispatch({ idStore, type: type, campo: 'uf', value: cep.estado })
              
        }else{
          alertar(notificacao, 'br', "CEP Não encontrado.", 'info', 'icon-bulb-63', 2)
        }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
