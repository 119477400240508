import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";
import { maskMoeda } from "~/Util/mascaras";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const idStore = "CAIXA";

const CaixaAbrir = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { cadastroCaixa, cadastroCaixaCopia, invalido } = useSelector((state) => state.Caixa);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CAIXA", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "CAIXA", campo, value });
  }

  function handleStatus(campo){
    var statusCaixa = '';
    if (isEnabled){
      statusCaixa = '9';
      setIsEnabled(false);
    }else{
      statusCaixa = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CAIXA', campo: campo, value: statusCaixa })
  }

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroCaixa.idCaixa === '') {
      controllerCaixa.CreateUpdateCaixa(notificacao, dispatch, cadastroCaixa, 'I', login.usuario, cadastroCaixaCopia);
    } else {
      controllerCaixa.CreateUpdateCaixa(notificacao, dispatch, cadastroCaixa, 'A', login.usuario, cadastroCaixaCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CAIXA' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('valorAbertura').focus()
  };

  useEffect(() => {
    //Preenche campos
    //controllerGrupoTributacao.GetTributos(notificacao, dispatch, login.usuario.empresa)
  }, []);
  
  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Caixa</label>
                      <Input
                        id="idCaixa"
                        type="text"
                        disabled
                        value={cadastroCaixa.idCaixa}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>
                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Usuário</label>
                      <Input
                        id="usuarioLogin"
                        type="text"
                        disabled
                        value={cadastroCaixa.usuarioLogin}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>
                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Data Abertura</label>
                      <Input
                        id="dataAbertura"
                        type="text"
                        disabled
                        value={cadastroCaixa.dataAbertura}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padr/ao-col" lg="2" md="3">
                    <label>Valor Abertura</label>
                    <Input
                      
                      id="valorAbertura"
                      type="text"
                      value={cadastroCaixa.valorAbertura}
                      onFocus={e => e.target.select()}
                      onChange={(e) =>
                        handleOnChange(maskMoeda(e.target.value), e.target.id)
                      }    
                      autoComplete="off"
                      maxLength="15"
                    />
                    <br></br>
                  </Col>

                  <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroCaixa.status === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroCaixa.status === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroCaixa.status === "1"}
                          onChange={(e) =>
                            handleStatus("status")
                          }
                          value={isEnabled}
                        />
                      </Col>            

                  </Col>

                </Row>
              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('codigoProduto').focus()
    }, 100)
  }

  render() {
    return (
      <CaixaAbrir />
    )
  }
}

export default Wizard;
