import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";

const idStore = 'USUARIOS';


//Função que recebe o usuario, os campos da pesquisa e o dispatch, para pesquisa de usuarios por empresa
export function Pesquisar(notificacao, codEmpresa, pesquisa, dispatch) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/usuario/usuarios?codEmpresa=${codEmpresa}&nome=${pesquisa.nome}&email=${pesquisa.email}&cpf=${pesquisa.cpf}&status=${pesquisa.ativos}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            response.data.records.forEach(record => {
                record.dtCadastro = new Date(record.dtCadastro).toLocaleString();
            });
            dispatch({ idStore, type: 'USUARIOS', value: response.data.records })
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum usuário encontrado", 'info', 'icon-bulb-63', 2);
            }

        }).catch(e => {
            alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2, e)
        });
    }
    catch (e) {
        console.log(e);
    }
}

 
export function GetGrupos(notificacao, dispatch, codEmpresa) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grupos/getGrupos?codEmpresa=${codEmpresa}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({ idStore: 'GRUPOS_ACESSO', type: 'GRUPOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível obter os grupos de acesso", 'warning', 'icon-alert-circle-exc', 2, e)
        });
    }
    catch (e) {
        console.log(e);
    }
}

export async function NovoGrupo(notificacao, codEmpresa, newGrupo, dispatch) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var codGrupoAcesso
        if (newGrupo.nome === "") {
            return alertar(notificacao, 'br', "Preencha o campo para o nome do grupo", 'info', 'icon-bulb-63', 2);
        }
        await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/grupos/novoGrupo`,
            headers: { Authorization: basicAuth },
            data: {
                codEmpresa: codEmpresa,
                nome: newGrupo.nome,
                telas: newGrupo.telas
            }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            codGrupoAcesso = response.data.records
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
        GetGrupos(notificacao, dispatch, codEmpresa);
        return codGrupoAcesso
    }
    catch (e) {
        console.log(e);
    }
}

export async function UpdateGrupo(notificacao, dispatch, codEmpresa, grupos, grupoSelecionado) {
    try {
        var telas = [];
        var count = 0;
        grupos.forEach(grupo => {
            if (grupo.codGrupoAcesso === grupoSelecionado) {
                grupo.telas.forEach(tela => {
                    if (tela.changed) {
                        telas.push({ codTela: tela.codTela, status: tela.status });
                        count++;
                    }
                })
            }
        });
        if (count === 0) {
            return alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
        }
        var grupo = { codEmpresa, codGrupoAcesso: grupoSelecionado, telas }
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupos/updateGrupo`,
            headers: { Authorization: basicAuth },
            data: grupo
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });

        GetGrupos(notificacao, dispatch, codEmpresa);
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function ExcluirGrupo(notificacao, codEmpresa, codGrupoAcesso, dispatch) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupos/excluirGrupo`,
            headers: { Authorization: basicAuth },
            data: { codEmpresa: codEmpresa, codGrupoAcesso }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
        GetGrupos(notificacao, dispatch, codEmpresa);
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetUsuariosGrupo(notificacao, codEmpresa, codGrupoAcesso) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/usuario/getUsuariosGrupo?codEmpresa=${codEmpresa}&codGrupoAcesso=${codGrupoAcesso}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            return response.data.records
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function SalvarUsuario(notificacao, dispatch, cadastro, codEmpresa) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var usuarioExistente = false

        var invalido = false;

        if (cadastro.nome === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
            invalido = true
        }
        if (cadastro.cpf === '' || !ValidaCPF(cadastro.cpf)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cpf', value: true })
            invalido = true
        }
        if (cadastro.email === '' || !ValidaEmail(cadastro.email)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/createUsuario?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                CodEmpresa: cadastro.codEmpresa,
                CodUser: cadastro.codUser,
                CodFunc: cadastro.codFunc,
                CodGrupoAcesso: cadastro.codGrupoAcesso,
                Nome: cadastro.codUser !== "" ? "" : cadastro.nome,
                Apelido: cadastro.codUser !== "" ? "" : cadastro.apelido,
                Email: cadastro.codUser !== "" ? "" : cadastro.email,
                Cpf: cadastro.codUser !== "" ? "" : cadastro.cpf,
                Foto: cadastro.codUser !== "" ? "" : cadastro.foto
            }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codUser', value: response.data.records.codUser })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codFunc', value: response.data.records.codFunc })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'emailFunc', value: cadastro.email })
            dispatch({ idStore, type: 'CADASTRO_COPIA' })
            return usuarioExistente

        }).catch(e => {
            if (e.response.status === 409) {
                usuarioExistente = true
                return true
            } else {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            }
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function SalvarUsuarioGrupo(notificacao, dispatch, cadastro, codGrupo) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/vincularUsuario`,
            headers: { Authorization: basicAuth },
            data: {
                CodGrupoAcesso: codGrupo,
                CodEmpresa: cadastro.codEmpresa,
                CodUser: cadastro.codUser,
            }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            dispatch({ idStore, type: 'CADASTRO_COPIA' })
            return true;

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            return false;
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
        return false;
    }
}

export async function SalvarFuncionario(notificacao, dispatch, cadastro, cadastroCopia) {
    try {
        if (JSON.stringify(cadastro) === JSON.stringify(cadastroCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;
        var dataNascimento = cadastro.dtNascimento;
        var dataEmissao = cadastro.dtEmissao
        if (cadastro.emailFunc !== '' && !ValidaEmail(cadastro.emailFunc)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'emailFunc', value: true })
            invalido = true
        }

        if (dataNascimento !== '') {
            dataNascimento = new Date(dataNascimento)
            dataNascimento.setDate(dataNascimento.getDate() + 1)

            dataNascimento = dataNascimento.toLocaleDateString()
        }

        if (dataNascimento !== '' && !ValidaData(dataNascimento)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'dtNascimento', value: true })
            invalido = true
        }

        if (dataEmissao !== '') {
            dataEmissao = new Date(dataEmissao)
            dataEmissao.setDate(dataEmissao.getDate() + 1)

            dataEmissao = dataEmissao.toLocaleDateString()
        }

        if (dataEmissao !== '' && !ValidaData(dataEmissao)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'dtEmissao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/updateFuncionario`,
            headers: { Authorization: basicAuth },
            data: {
                CodEmpresa: cadastro.codEmpresa,
                CodFunc: cadastro.codFunc,
                CodUser: cadastro.codUser,
                Email: cadastro.emailFunc,
                Telefone: cadastro.telefone,
                RG: cadastro.rg,
                OrgaoEmissor: cadastro.orgaoEmissor,
                DtEmissao: dataEmissao,
                Sexo: cadastro.genero,
                DtNascimento: dataNascimento,
            }
        }).then(function (response) {
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codFunc', value: response.data.records })
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            dispatch({ idStore, type: 'CADASTRO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            return true
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
        return true
    }
}

export async function GetUsuario(notificacao, codEmpresa, codUser, dispatch, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/usuario/getUsuarioCompleto?codEmpresa=${codEmpresa}&codUser=${codUser}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codEmpresa', value: response.data.records.codEmpresa })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codUser', value: response.data.records.codUser })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codFunc', value: response.data.records.codFunc })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'codGrupo', value: response.data.records.codGrupo })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'nomeGrupo', value: response.data.records.nomeGrupo })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'nome', value: response.data.records.nome })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'apelido', value: response.data.records.apelido })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'email', value: response.data.records.email })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'emailFunc', value: response.data.records.emailFunc })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'cpf', value: response.data.records.cpf })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'foto', value: response.data.records.foto })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'telefone', value: response.data.records.telefone })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'rg', value: response.data.records.rg })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'orgaoEmissor', value: response.data.records.orgaoEmissor })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'dtEmissao', value: response.data.records.dtEmissao !== "" && response.data.records.dtEmissao !== "0000-00-00" ? response.data.records.dtEmissao : "" })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'genero', value: response.data.records.sexo === "" ? "O" : response.data.records.sexo })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'dtNascimento', value: response.data.records.dtNascimento !== "" && response.data.records.dtNascimento !== "0000-00-00" ? response.data.records.dtNascimento : "" })
            dispatch({ idStore: 'USUARIOS', type: 'CADASTRO', campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'CADASTRO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function InativarUsuario(notificacao, dispatch, user, codEmpresa, pesquisa) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        const cadastroPendente = axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/inativar`,
            headers: { Authorization: basicAuth },
            data: {
                CodEmpresa: codEmpresa,
                CodUser: user.codUser,
                Status: user.status
            }
        }).then(function (response) {
            if (response.status === 200) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
                Pesquisar(notificacao, codEmpresa, pesquisa, dispatch);
                return false
            } else if (response.status === 201) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 30);
                Pesquisar(notificacao, codEmpresa, pesquisa, dispatch);
                return true
            }
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            return false
        });
        return cadastroPendente
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function VinculaUsuarioEmpresa(notificacao, dispatch, cpf, codEmpresa) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/createFuncionario`,
            headers: { Authorization: basicAuth },
            data: {
                Cpf: cpf,
                CodEmpresa: codEmpresa,
            }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            var codUser = response.data.records.codUser
            GetUsuario(notificacao, codEmpresa, codUser, dispatch);
            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2);
            return false

        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

//Retorna os usuário ATIVOS da empresa
export function UsuariosEmpresaGet(notificacao, idEmpresa, dispatch) {
try {

    var ulrPesquisa = '';
    ulrPesquisa = api.urlBaseChefe + api.urlRetornarUsuariosEmpresa + `${idEmpresa}`;

    dispatch({ idStore, type: 'USUARIOS', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    var usuarios = response.data.data;
    if (usuarios.length === 0) {
        alertar(notificacao, 'br', "Nenhum Usuário Encontrado", 'info', 'icon-bulb-63', 2)      
    }


    dispatch({ idStore, type: 'USUARIOS', value: usuarios })

    })

} catch (e) {
    alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
}
}

