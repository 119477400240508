import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import * as controllerLogin from "~/controller/controllerLogin";
//const avatar = require("~/assets/img/face-0.jpg");
const avatar = "https://chefe.ernetwork.com.br/img/face-0.jpg";
export default function Options() {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  function handleLogout() {
    controllerLogin.logout(dispatch);
  }
  function hanldeLock() {
    controllerLogin.lock(dispatch);
}

  return (
    <>
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          nav
          onClick={(e) => e.preventDefault()}
        >
          <div className="photo">
            <img
              alt="..."
              src={login.usuario.foto !== "" ? login.usuario.foto : avatar}
            />
          </div>
          <b className="caret d-none d-lg-block d-xl-block" />
          <p className="d-lg-none">Opções</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-navbar" right tag="ul">
          {/*           <NavLink tag="li">
            <DropdownItem className="nav-item">Perfil</DropdownItem>
          </NavLink>
          <NavLink tag="li">
            <DropdownItem className="nav-item">Configurações</DropdownItem>
          </NavLink> 
          <DropdownItem divider tag="li" /> */}

          <NavLink tag="li" onClick={() => hanldeLock()}>
            {/* Menu Bloquear */}
            <DropdownItem className="nav-item">Bloquear</DropdownItem>
          </NavLink>
          <NavLink tag="li" onClick={() => handleLogout()}>
            {/* Menu Sair */}
            <DropdownItem className="nav-item">Sair</DropdownItem>
          </NavLink>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
